import { Button, Modal } from "react-bootstrap";

export const CustomModal = ({
  show,
  handleClose,
  title,
  message,
  variant = 'success',
  onConfirm,
  isSubmitting = false
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header
        closeButton
      >
        <h3 className="fs-5 fw-bold">{title}</h3>
      </Modal.Header>

      <Modal.Body>
        {message}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={handleClose}
          disabled={isSubmitting}
        >
          Cancelar
        </Button>
        <Button
          variant={variant}
          onClick={onConfirm}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Procesando..." : "Confirmar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};