import axios from "axios";

const source = axios.CancelToken.source();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
  cancelToken: source.token,
});

export const cancelRequests = () => {
  source.cancel("Petición cancelada por el usuario."); // llamamos a la función de cancelación
};

const axiosGet = async (url, params) => {
  // Make a request for a user with a given ID
  let finalResponse = null;
  try {
    finalResponse = await axiosInstance
      .get(url)
      .then(function (response) {
        // handle success
        return response;
      })
      .catch(function (error) {
        // handle error
        return error.message;
      });
  } catch (error) {
    finalResponse = error.message;
  }
  return finalResponse;
};

const axiosAuthGet = async (url, token, params) => {
  // Make a request for a user with a given ID
  try {
    return await axiosInstance
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        // handle success
        return response;
      })
      .catch(function (error) {
        // handle error
        return error;
      });
  } catch (error) {
    return error;
  }
};

const axiosPost = async (url, params) => {
  try {
    const response = await axiosInstance.post(url, params);
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(`Failed to make POST request to ${url}`);
  }
};

const axiosAuthPost = async (url, token, params, config = null) => {
  try {
    let customHeaders = { Authorization: `Bearer ${token}` };
    if (config) {
      customHeaders = { ...config, Authorization: `Bearer ${token}` };
    }
    return await axiosInstance
      .post(url, params, { headers: customHeaders })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  } catch (error) {
    console.error(error);
    return error;
  }
};

const axiosPut = async (url, params) => {
  try {
    return await axiosInstance
      .put(url, params)
      .then(function (response) {
        // handle success
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (error) {
    console.error(error);
  }
};

const axiosAuthPut = async (url, token, params) => {
  try {
    return await axiosInstance
      .put(url, params, { headers: { Authorization: `Bearer ${token}` } })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  } catch (error) {
    console.error(error);
  }
};

const axiosPatch = async (url, params) => {
  try {
    return await axiosInstance
      .patch(url, params)
      .then(function (response) {
        // handle success
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (error) {
    console.error(error);
  }
};

const axiosAuthPatch = async (url, token, params) => {
  try {
    return await axiosInstance
      .patch(url, params, { headers: { Authorization: `Bearer ${token}` } })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  } catch (error) {
    console.error(error);
  }
};

const axiosDelete = async (url) => {
  try {
    return await axiosInstance
      .delete(url)
      .then(function (response) {
        // handle success
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (error) {
    console.error(error);
  }
};

const axiosAuthDelete = async (url, token) => {
  try {
    return await axiosInstance
      .delete(url, { headers: { Authorization: `Bearer ${token}` } })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  axiosGet,
  axiosAuthGet,
  axiosPost,
  axiosAuthPost,
  axiosPut,
  axiosAuthPut,
  axiosPatch,
  axiosAuthPatch,
  axiosDelete,
  axiosAuthDelete,
};
