import { getCurrentUser } from "../utils/authUtils";
import { getCookie } from "../utils/cookiesUtils";
import api from "../utils/axiosUsage";
import { getTimeTokenRest } from "../utils/tokenValidation";
import { updateStorage } from "../utils/authStorageUtils.tsx";

async function RefreshAccessToken() {
  const access_token = getCookie("session_token") || getCurrentUser().token;
  const refresh_token = getCurrentUser().refresh_token;
  const accessToken = getTimeTokenRest(access_token);
  const refreshToken = getTimeTokenRest(refresh_token);

  if (!access_token && !refresh_token) return "No existe el token";

  if (accessToken > 15 || accessToken < 5) {
    const updated_values = await updateAccessToken(access_token, refresh_token);
    if (updated_values.success) {
      const { user } = updated_values;
      const new_token = user.token;
      updateStorage(new_token);
    } else {
      console.log("caso externo");
      //supongo que se deberian limpiar las credenciales en este caso
    }
  }
  if (refreshToken > 15 || refreshToken < 5) {
    //Refrescar el token de refresh
    //Por ahora no va a hacer nada
  }
}

const updateAccessToken = async (token: string, refreshToken: string) => {
  const refresh_token = { refresh: refreshToken };
  const currentUser = getCurrentUser();
  let refreshUser: { [key: string]: any } = {};
  let success = false;
  try {
    const refreshTokens = await api.axiosAuthPost(
      "/api/v1/refresh_token/",
      token,
      refresh_token
    );
    if (refreshTokens.status === 200 || refreshTokens === 201) {
      const new_token = refreshTokens.data.access;
      refreshUser = {
        ...currentUser,
        token: new_token,
        access_token: new_token,
      };
      success = true;
    }
  } catch (err) {
    console.error(err);
  }

  return { success, user: refreshUser };
};

export default RefreshAccessToken;
