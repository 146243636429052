import React, { useState } from "react";
import { Container, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import SetUserAccountForm from "./SetUserAccountForm";
import SetCorporationForm from "./SetCorporationForm";

import "../../../assets/styles/pages/design-app.scss";
import "../../../assets/styles/pages/index.scss";
import "react-toastify/dist/ReactToastify.css";

import step1 from "../../../assets/images/onboarding/step-1.svg";
import { getCookie } from "../../../utils/cookiesUtils";
import { getCurrentUser } from "../../../utils/authUtils";
import api from "../../../utils/axiosUsage";
import { cancelRequests } from "../../../utils/axiosUsage";
import { useEffect } from "react";

const UserAssignmentManager = () => {
  const [step, setStep] = useState(1);
  const [storedUser, setStoredUser] = useState({});
  const [institution, setInstitution] = useState("");
  const [fetchedUser, setFetchedUser] = useState({});
  const user = useSelector((state) => state);
  const user_name = user.user_data.data.name;
  const [selectedId, setSelectedId] = useState("");
  const token = getCookie("session_token") || getCurrentUser().token;

  const changeStep = (val) => {
    setStep(val);
  };

  const getProfileData = async (token, id) => {
    const profile_data = await api.axiosAuthGet(
      `/api/v1/profile-crud/${id}/`,
      token
    );
    if (profile_data.status === 200 || profile_data.status === 201) {
      setFetchedUser(profile_data.data);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    if (Object.keys(storedUser).length !== 0) {
      const fetchData = async () => {
        const profile_data = await api.axiosAuthGet(
          `/api/v1/profile-crud/${storedUser.id}/`,
          token
        );
        if (profile_data.status === 200 || profile_data.status === 201) {
          setFetchedUser(profile_data.data);
        } else {
          console.log("error");
        }
      };
      fetchData();

      return () => {
        // cancelRequests();
      };
    }
  }, [storedUser, token]);

  const steps = [
    {
      id: 1,
      description: "Crea un usuario o conecta a un usuario",
      img: step1,
      frm: (
        <SetUserAccountForm
          personName={user_name}
          step={step}
          changeStep={changeStep}
          setSelectedId={setSelectedId}
          setStoredUser={setStoredUser}
        />
      ),
    },
    {
      id: 2,
      description: "Asignalo a una entidad ya existente o crea una nueva",
      img: step1,
      frm: (
        <SetCorporationForm
          personName={user_name}
          step={step}
          changeStep={changeStep}
          editedUser={fetchedUser}
          setInstitution={setInstitution}
          storedUser={storedUser}
        />
      ),
    },
    // {
    //   id: 3,
    //   description: "Edita a fondo la entidad",
    //   img: step2,
    //   frm: <FrmSetEditCorporation institution={institution} step={step} />,
    // },
  ];

  const renderMenuOptions = () => {
    const menu = Object.values(steps).map((opt) => {
      return (
        <li key={`menu${opt.id}`} className={step === opt.id ? "active" : ""}>
          {opt.description}
        </li>
      );
    });
    return menu;
  };

  return (
    <Container fluid className="stepPage-container">
      <Col lg={12} className="stepPage-content">
        <img
          src="/images/logos/logo.svg"
          alt="logo"
          style={{ marginLeft: "30px", marginBottom: "30px" }}
        />
        <div></div>
        <div className="onboardingForms">{steps[step - 1].frm}</div>
      </Col>
      <div className="colStepsDescription">
        <h3>
          Estás asignando una entidad
          <br />a un usuario
        </h3>
        <p>
          Como administrador de logra puedes asignar un usuario a una entidad.
        </p>
        <ul>{renderMenuOptions()}</ul>
      </div>
    </Container>
  );
};

export default UserAssignmentManager;
