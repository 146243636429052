import styled from "styled-components";

// export const Main = styled.main`
//   // width: 100%;
//   position: relative;
//   transition: all 0.15s;
//   height: 100vh;
//   overflow-y: scroll;
//   margin-left: 65px;
//   padding: 0 5px;
//   margin-left: ${(expanded) => (expanded ? 5 : 64)}px;
// `;

export const Main = styled.main`
  // width: 100%;
  height: 100vh;
  position: relative;
  background: #f5f5f5;
  transition: all 0.15s;
  padding: 0 5px;
  margin-left: ${(expanded) => (expanded ? 5 : 64)}px;
`;
