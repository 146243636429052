import React, { useState, useEffect, useRef } from "react";
import { Loading } from "@logra/component-library";
import { useSelector } from "react-redux";
import { FormInstitutionEdit } from "./FormInstitutionEdit";
import { FormProgramEdit } from "./FormProgramEdit";
import FrmMembers from "./FormMember";
import { getCurrentCompany, getCurrentUser } from "../../utils/authUtils";
import "../../assets/styles/pages/configuration.scss";
import api from "../../utils/axiosUsage";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../utils/cookiesUtils";

const ConfigurationEditProfile = () => {
  const location = useLocation();
  const [locationForm, setLocationForm] = useState(location?.state?.step || 1);
  const [loggedUser, setLoggedUser] = useState({});
  const [formState, setFormState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [team, setTeam] = useState([]);

  const company = useSelector((state) => state.corp_data.data);
  const currentCompany = company || getCurrentCompany();
  const token = getCurrentUser().token || getCookie("session_token");
  const user_data = useSelector((state) => state.crud_data.data);
  const backup_user = getCurrentUser();
  const currentUser = user_data || backup_user;

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const companyId = company?.id || getCurrentCompany().id;
        const { data } = await api.axiosAuthGet(
          `/api/v1/corporation/${companyId}/`,
          token
        );
        setFormState(data);
        const { data: teamData } = await api.axiosAuthGet(
          `/api/v1/view-team/${companyId}/`,
          token
        );
        setTeam(teamData.members);
      } catch (error) {
        console.log(error);
      } finally {
        setLoggedUser(currentUser);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [company, token]);

  const forms = [
    {
      id: 1,
      description: "Acerca del programa",
      frm: (
        <FormProgramEdit
          formState={company || formState}
          user={currentUser}
          company={currentCompany}
        />
      ),
    },
    {
      id: 2,
      description: "Acerca de la Institución",
      frm: (
        <FormInstitutionEdit
          formState={company || formState}
          user={currentUser}
          company={currentCompany}
        />
      ),
    },
    // {
    //   id: 3,
    //   description: "Miembros de tu equipo",
    //   frm: (
    //     <FrmMembers
    //       formState={company || formState}
    //       user={loggedUser}
    //       team={team}
    //     />
    //   ),
    // },
  ];

  const renderMenuOptions = () => {
    return forms.map((opt) => (
      <li
        key={`menu${opt.id}`}
        className={locationForm === opt.id ? "active" : ""}
        onClick={() => setLocationForm(opt.id)}
      >
        {opt.description}
      </li>
    ));
  };

  return (
    <div className="configuration-container">
      <div className="configuration-frm">
        {isLoading ? <Loading /> : forms[locationForm - 1].frm}
      </div>
      <section className="configuration-section">
        <h3>Estás configurando tu cuenta</h3>
        <p>
          Conocer mejor a tu empresa nos permitirá crear la mejor ruta de
          crecimiento para ti y tu equipo.
        </p>
        <ul>{renderMenuOptions()}</ul>
      </section>
    </div>
  );
};

export default ConfigurationEditProfile;
