import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";
import { getCookie } from "../../utils/cookiesUtils";

export const fetchReferalData = createAsyncThunk(
  "user/fetchReferalData",
  async () => {
    const token = getCookie("session_token");
    const response = await api.axiosAuthGet(
      "/api/v1/corporation-kpis-v2/",
      token
    );
    console.log("ejectuto referal data");
    return {
      referal_data: response.data,
      timeStamp: Date.now(),
    };
  }
);
