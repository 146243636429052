import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import StartSlider from "../../Components/StartSlider/StartSlider";
import "../../assets/styles/pages/index.scss";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";
import { NavigateToButton } from "../../utils/routerUtils";
import { HandleLogin } from "../../api/api-utils.tsx";
import {
  setAuthCookies,
  setLocalStorageValues,
  setGlobalState,
} from "../../utils/authStorageUtils.tsx";

const LoginPage = () => {
  // Configuración inicial
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Función para validar si el usuario tiene permiso de acceso
  function validateUser(isCorpOwner, isStaffDigitizeMe, user, company) {
    if (!isCorpOwner) {
      setError(true);
      setErrorMessage("Tienes que ser dueño de una entidad");
      return false;
    }
    return true;
  }

  // Función que se ejecuta al presionar el botón "Ingresar"
  const handleLogin = async () => {
    // Validar si los campos de correo y contraseña no están vacíos
    if (!email || !password) {
      setErrorMessage("Oops! Debes de ingresar tus credenciales");
      setError(true);
      return;
    }

    // Construir un objeto con las credenciales del usuario
    const credentials = { email: email, password: password };

    try {
      // Enviar las credenciales del usuario al servidor para validarlas
      const login = await HandleLogin({ credentials: credentials });

      // Si la validación es exitosa, redireccionar al usuario a la página de inicio
      if (login.success) {
        // Obtener los datos del usuario y de la empresa a la que pertenece
        const { connectedUserData, companyData } = login;

        // Validar si el usuario tiene permiso de acceso
        const isValidUser = validateUser(
          connectedUserData.is_corp_owner,
          connectedUserData.is_staff_digitizeme,
          connectedUserData,
          companyData
        );

        // Si el usuario tiene permiso de acceso, configurar las cookies de autenticación
        // y redireccionar al usuario a la página de inicio
        if (isValidUser) {
          setAuthCookies(
            connectedUserData,
            connectedUserData.is_staff_digitizeme
          );
          setLocalStorageValues(connectedUserData, companyData);
          setGlobalState(dispatch, connectedUserData.is_staff_digitizeme);

          history.push("/home");
        }
      } else {
        // Si la validación es fallida, mostrar un mensaje de error
        setError(true);
        setErrorMessage(login.errors[0]);
      }
    } catch (error) {
      // Si ocurre un error durante la validación, mostrar un mensaje de error
      setError(true);
      setErrorMessage("Ha ocurrido un error al intentar iniciar sesión");
    }
  };

  return (
    <Container fluid className="landing-content">
      <Row className="rowPage" style={{ display: "flex", flexWrap: "wrap" }}>
        <Col xs={12} md={12} lg={6} className="columnIntro">
          <div className="home-topbar logra-logo">
            <img src="/images/logos/logo.svg" alt="logra-io-logo" />
          </div>
          <Form className="loginContainer">
            <MainTitle>Inicia sesión</MainTitle>
            <Form.Group controlId="formBasicEmail">
              <label>Correo</label>
              {/* <img className="input-icon" src={MailIcon} alt="Email" /> */}
              <Form.Control
                className="inputNewBrand main-btn-align"
                type="email"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              controlId="formBasicPassword"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <label>Contraseña</label>
              {/* <img className="input-icon" src={PasswordIcon} alt="Password" /> */}
              <Form.Control
                className="inputNewBrand main-btn-align"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <div
              style={{
                display: error ? "block" : "none",
                padding: "10px",
                marginBottom: 15,
                fontWeight: "bold",
                color: "#CC3333",
                backgroundColor: "#FFEEEE",
              }}
            >
              {errorMessage}
            </div>
            <Button onClick={handleLogin} className="btn-style btn-text">
              Ingresar
            </Button>
            <br />
            <br />
            <NavigateToButton
              url={"/forgot-password"}
              description="Has olvidado tu contraseña?"
            />
          </Form>
        </Col>
      </Row>
      <div className="startCover">
        <StartSlider />
      </div>
    </Container>
  );
};

export default LoginPage;
