import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosUsage from "../../utils/axiosUsage";
import { getCurrentUser } from "../../utils/authUtils";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async () => {
    const response = await getCurrentUser();
    return {
      data: response,
      timeStamp: Date.now(),
    };
  }
);
