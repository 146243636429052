import React, { useState } from "react";
import { Container, Col } from "react-bootstrap";

import SetCorporationCreateForm from "./SetCorporationCreateForm";
import SetCorporationEditForm from "./SetCorporationEditForm";

import "../../../assets/styles/pages/design-app.scss";
import "../../../assets/styles/pages/index.scss";

import { getCookie } from "../../../utils/cookiesUtils";
import { getCurrentUser } from "../../../utils/authUtils";
import api from "../../../utils/axiosUsage";
import { notifyBad, notifyCool } from "../../../utils/toastNotifications";
import { useHistory } from "react-router-dom";

const CreateCorporationManager = () => {
  /**
   *
   * This code purpose is to add an option to only just create entities for the admin panel
   */

  const [step, setStep] = useState(1);
  const [corporation, setCorporation] = useState({});
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = getCookie("session_token") || getCurrentUser().token;
  const changeStep = (val) => {
    setStep(val);
  };

  // Handle error when corp creation fails
  const handleCorpCreationError = () => {
    notifyBad("¡Hubo un error!");
    setError(true);
    setErrorMessage("Hubo un error en la creacion de la corporacion");
  };

  // Handle error when corp edition fails
  const handleCorpEditError = () => {
    notifyBad("¡Hubo un error!");
    setError(true);
    setErrorMessage("Hubo un error en la edicion de la corporacion");
  };

  // Handle general error
  const handleGeneralError = () => {
    notifyBad("Ocurrió un error");
    setError(true);
    setErrorMessage("Ocurrio un error general");
  };

  const handleCreateCorporation = async (values) => {
    if (!values) {
      handleGeneralError();
      return false;
    }
    const config_headers = { "content-type": "multipart/form-data" };
    const response = await api.axiosAuthPost(
      "/api/v1/corporation/",
      token,
      values,
      config_headers
    );
    if (response.status === 200 || response.status === 201) {
      setCorporation(response.data);
      notifyCool("Institucion creada correctamente");
      changeStep(step + 1);
    } else {
      handleCorpCreationError();
    }
  };

  const handleEditCorporation = async (values) => {
    if (!values) {
      handleGeneralError();
      return false;
    }
    const response = await api.axiosAuthPatch(
      `/api/v1/corporation/${corporation.id}/`,
      token,
      values
    );
    if (response.status === 200 || response.status === 201) {
      notifyCool("Institucion editada correctamente");
      history.push("/home");
    } else {
      handleCorpEditError();
    }
  };

  const steps = [
    {
      id: 1,
      description: "Crea una entidad nueva",
      frm: <SetCorporationCreateForm handler={handleCreateCorporation} />,
    },
    {
      id: 2,
      description: "Añade mas detalles a la entidad creada",
      frm: (
        <SetCorporationEditForm
          institutionName={corporation.name}
          handler={handleEditCorporation}
        />
      ),
    },
  ];

  const renderMenuOptions = () => {
    const menu = Object.values(steps).map((opt) => {
      return (
        <li key={`menu${opt.id}`} className={step === opt.id ? "active" : ""}>
          {opt.description}
        </li>
      );
    });
    return menu;
  };

  const errorMessageStyles = {
    display: error ? "block" : "none",
    padding: "10px",
    marginBottom: 15,
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };

  return (
    <Container fluid className="stepPage-container">
      <Col lg={12} className="stepPage-content">
        <img
          src="/images/logos/logo.svg"
          alt="logo"
          style={{ marginLeft: "30px", marginBottom: "30px" }}
        />
        <div
          className="adminForms"
          style={{ overflow: "auto", padding: "1.5rem" }}
        >
          {/* Here goes the form */}
          {steps[step - 1].frm}
        </div>
        <div style={errorMessageStyles}>{errorMessage}</div>
      </Col>
      <div className="colStepsDescription">
        <h3>Crea una entidad</h3>
        <p>Como administrador de logra puedes crear una entidad</p>
        <ul>{renderMenuOptions()}</ul>
      </div>
    </Container>
  );
};

export default CreateCorporationManager;
