import React from "react";
import "../../assets/styles/pages/ErrorBarrel/errorboundary.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    console.error("Error:", error);
    console.error("Component stack:", errorInfo.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can return a custom error message or UI here
      return (
        <div className="errorBoundary-container">
          <img
            src="/images/logos/logo.svg"
            alt="logra-logo"
            className="errorBoundary-img"
          />
          <h1 className="errorBoundary-title">
            Ocurrio un error inesperado 😱
          </h1>
          <button
            className="getBackbtn"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Volver al inicio
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
