import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";
import { getCookie } from "../../utils/cookiesUtils";

export const fetchGraphData = createAsyncThunk(
  "user/fetchGraphData",
  async () => {
    const userData = await getCurrentUser();
    const token = getCookie("session_token");
    const response = await api.axiosAuthGet("/api/v1/corporation-kpis/", token);
    return {
      graph_data: response.data,
      timeStamp: Date.now(),
    };
  }
);
