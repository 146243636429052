import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Loading } from "@logra/component-library";
import { getCookie } from "../../utils/cookiesUtils";
import useWindowWidth from "../../hooks/useWindowsWidth";
import AwsDashboard from "../../Components/aws/AwsDashboardComponent";
import {
  CorporationLogoContainer,
  NewHomeSubTitle,
  NewHomeTitle,
} from "../../assets/styles/pages/general/GeneralStyles";
import LograLogo from "../../assets/images/logo/logo.svg";
import { getCurrentCompany, getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";

const DashboardComponent = ({ dashboardType, dashboardUrl, width, height }) => {
  if (dashboardType === "QUICKSIGHT") {
    return <AwsDashboard url={dashboardUrl} width={width} height={height} />;
  } else {
    return <></>;
  }
};
/**
 * La función Home renderiza el componente de la página principal de la aplicación.
 * Esta página muestra un panel de control de los datos de la corporación del usuario y
 * un dashboard de AWS QuickSight o Logra Analytics.
 *
 * @return {JSX.Element} El componente React que contiene la página principal de la aplicación.
 */

const Home = () => {
  // Define los estados necesarios para el componente
  const [dataLoaded, setDataLoaded] = useState(false);
  const [referralData, setReferralData] = useState(null);
  const [fetchedData, setFetchedData] = useState(false);
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [showModalNotCompanies, setShowModalNotCompanies] = useState(false);

  // Obtiene los datos del dashboard desde el estado global usando la función useSelector de Redux
  const dashboard = useSelector((state) => state.dashboard);
  const dashboard_url = dashboard?.dashboard_url;

  // Obtiene el ancho de la ventana y calcula la altura para el aspect ratio 16:9
  const windowWidth = useWindowWidth();
  const height = windowWidth * 0.44; // 16:9 aspect ratio
  const adjustedWidth = windowWidth * 0.85;

  // Obtiene los datos de la corporación y del usuario desde el estado global o desde el local storage
  const companyData =
    useSelector((state) => state.corp_data.data) || getCurrentCompany();
  const userData =
    useSelector((state) => state.user_data.data) || getCurrentUser();

  useEffect(() => {
    if (!dashboard) {
      setDataLoaded(false);
      return;
    }

    if (dashboard.dashboard_type === "QUICKSIGHT") {
      setDashboardUrl(dashboard_url);
    }

    setDataLoaded(true);
  }, [dashboard, dashboard_url]);

  useEffect(() => {
    const getReferralData = async () => {
      try {
        const user = getCurrentUser();
        const token = getCookie("session_token") || user.token;
        const referralData = await api.axiosAuthGet(
          "/api/v1/corporation-kpis-v2/",
          token
        );
        setReferralData(referralData.data);
        setShowModalNotCompanies(
          referralData.owners_in_corp
            ? referralData.owners_in_corp.length === 0
            : false
        );
      } catch (e) {
        console.log("err", e);
      } finally {
        setFetchedData(true);
      }
    };

    if (!fetchedData) {
      getReferralData();
    }
  }, [fetchedData]);

  const loadingStyles = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  // Renderiza el loading spinner mientras se cargan los datos del dashboard
  if (!dataLoaded) {
    return (
      <div className="home-containerLoading" style={loadingStyles}>
        <Loading />
      </div>
    );
  }

  return (
    <Container fluid className="home-container">
      <Row
        className="rowLimit  rowDashboard"
        style={{ width: "100%", padding: "0.5rem " }}
      >
        <Col lg={12}>
          <CorporationLogoContainer>
            <img
              src={companyData.logotype ? companyData.logotype : LograLogo}
              alt="corporation logo"
            />
          </CorporationLogoContainer>
          <NewHomeTitle style={{ marginBottom: 20 }}>
            ¡Hola {userData.name} ! 👋🏼
          </NewHomeTitle>
          <NewHomeSubTitle>
            Gracias a ti, cada día construimos un mundo más justo lleno de
            logradores 🚀
          </NewHomeSubTitle>
        </Col>
      </Row>
      <div style={{ marginLeft: "1.3rem" }}>
        {dataLoaded ? (
          <React.Fragment>
            <DashboardComponent
              dashboardType={dashboard.dashboard_type}
              dashboardUrl={dashboard_url}
              width={adjustedWidth}
              height={height}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="home-containerLoading" style={loadingStyles}>
              <Loading />
            </div>
          </React.Fragment>
        )}
      </div>
    </Container>
  );
};

export default Home;
