import { createGlobalStyle } from "styled-components";
import { themes } from "./ColorStyles";

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${themes.light.backgroundColor};
    font-family: "Inter", sans-serif;
    padding-left:1rem;
  }
`;
