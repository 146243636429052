import React from "react";
import "../../assets/styles/pages/configuration/community-profile.scss";
import rectangle from "../../assets/images/icons/rectangle_test.png";
import useTruncate from "../../hooks/useTruncate";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentCompany } from "../../utils/authUtils";

const CommunityProfile = () => {
  const corporation = useSelector((state) => state.corp_data.data);

  /**
   * This object works in case there is not any value from the global state or local Storage
   */

  const mock_Company = {
    pyme_plan_name: "Plan de tu pymes",
    name: "Nombre de la organizacion",
    location: "Locacion",
  };
  const fallback_company = getCurrentCompany()
    ? getCurrentCompany()
    : mock_Company;

  function getProfilePicture() {
    if (corporation) {
      return corporation.logotype;
    } else if (fallback_company) {
      return fallback_company.logotype;
    } else {
      return rectangle;
    }
  }

  const truncatedPymeName = useTruncate(
    corporation?.pyme_plan_name
      ? corporation.pyme_plan_name
      : fallback_company.pyme_plan_name,
    13
  );

  const truncatedCompanyName = useTruncate(
    corporation?.name ? corporation.name : fallback_company.name,
    13
  );
  const truncatedDescription = useTruncate(
    "Ayudamos las PyMes de LATAM a resolver sus principales retos.",
    65
  );
  const truncatedLocation = useTruncate(
    corporation?.location ? corporation.location : fallback_company.location,
    15
  );

  return (
    <div className="community-profile-container">
      <div className="community-profile-header">
        <img src={getProfilePicture()} alt="community-avatar" />
        <div className="community-profile-header-title">
          <p className="community-profile-header-titleMain">
            {truncatedPymeName}
          </p>
          <p className="community-profile-header-titleName">
            {truncatedCompanyName}
          </p>
          <p className="community-profile-header-titleCategory">
            {useTruncate("Tecnologia", 13)}
          </p>
        </div>
      </div>
      <div className="community-profile-body">
        <p className="community-profile-body-title">"{truncatedDescription}"</p>
      </div>
      <div className="community-profile-footer">
        <div className="community-profile-footer-row">
          <div className="community-profile-footer-text">
            <span className="community-profile-footer-textTitle">
              Ubicacion:
            </span>
            <p className="community-profile-footer-textLocation">
              {truncatedLocation} 🌎
            </p>
          </div>
          <Link
            to="/configuration/edit_profile"
            className="community-profile-footer-button"
          >
            <span className="community-profile-footer-buttonText">
              Editar perfil
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CommunityProfile;
