import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import {
  createSessionCookies,
  getCurrentUser,
  setCurrentUser,
} from "./authUtils";
import api from "./axiosUsage";

export const validTokenTime = async (tokenAccess, refreshToken) => {
  let userData = getCurrentUser();

  if (!tokenAccess || !refreshToken) return false;
  const tokenAccessExpiration = getTimeTokenRest(tokenAccess);
  const tokenRefreshExpiration = getTimeTokenRest(refreshToken);

  if (tokenRefreshExpiration < 15) {
    return false;
  }

  if (tokenAccessExpiration < 15) {
    const respRefreshTokenAccess = await api.axiosAuthPost(
      "/api/v1/refresh_token/",
      tokenAccess,
      {
        refresh: refreshToken,
      }
    );
    if (respRefreshTokenAccess.status === 200) {
      userData.token = respRefreshTokenAccess.data.access;
      setCurrentUser(userData);
      // createSessionCookies(respRefreshTokenAccess.data.access);
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const getTimeTokenRest = (token) => {
  const decodeToken = jwtDecode(token);
  const dateToken = dayjs.unix(decodeToken.exp);
  const dateCurrent = dayjs();
  const minutesLeft = dateToken.diff(dateCurrent, "minutes");
  return minutesLeft;
};
