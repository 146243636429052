import React from "react";
import "../../assets/styles/pages/configuration.scss";

import ConfigurationPage from "../../Components/configuration/ConfigurationPage";
import { Col, Container, Row } from "react-bootstrap";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";

const Configuration = () => {
  /**
   *
   * This is Legacy code along its components folder "configuration"
   */

  const Header = () => {
    return (
      <div className="configurationHeader-container">
        <h1 className="configurationHeader-title">Tu perfil</h1>
        <p className="configurationHeader-subtitle"></p>
      </div>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <MainTitle style={{ marginBottom: 50 }}>Tu perfil</MainTitle>
          {/* <Header /> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <ConfigurationPage />
        </Col>
      </Row>
    </Container>
  );
};

export default Configuration;
