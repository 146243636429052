import React from "react";
import "./list-member.scss";
import ProfilePicture from "../../assets/images/icons/profile_picture.svg";

// import UserDefaultImg from "../../../assets/images/profile/userDefault.png";

export const ListMembers = ({ member }) => {
  return (
    <div className="profilePage-membersList">
      <div className="profilePage-membersListInfo">
        <img
          src={!member.profile_photo ? ProfilePicture : member.profile_photo}
          alt="Usuario"
        />
        <div className="profilePage-membersListName">
          <p>{member.name ? member.name : member.email}</p>
          <span>{member?.job_position}</span>
        </div>
      </div>
      <hr />
    </div>
  );
};
