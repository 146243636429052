import React, { useState } from "react";
import { notifyBad, notifyCool } from "../../utils/toastNotifications";
import { ToastContainer } from "react-toastify";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
import { CompactPicker } from "react-color";
import { Container, Row, Col, Form } from "react-bootstrap";

import api from "../../utils/axiosUsage";
import logoBgAdd from "../../assets/images/onboarding/logo-bg-add.png";
import MoreInfo from "../../assets/images/icons/moreInfo.png";
import "react-toastify/dist/ReactToastify.css";
import { ModalHelpLogos } from "../modals/ModalHelpLogos";
import { useDispatch } from "react-redux";
import { fetchCorpData } from "../../features/corp_data/corpData";
import { getCookie } from "../../utils/cookiesUtils";
import { getCurrentUser } from "../../utils/authUtils";

export const FormProgramEdit = ({ formState, user, company }) => {
  const [imgPreview, setImgPreview] = useState(formState.logo || null);
  const [imgPreviewLogotipo, setImgPreviewLogotipo] = useState(
    formState.logotype || null
  );
  const [pictureData, setPictureData] = useState(formState.logo || null);
  const [pictureDataLogotipo, setPictureDataLogotipo] = useState(
    formState.logotype || null
  );
  const [colorCompany, setColorCompany] = useState(formState.color || "#fff");
  const dispatch = useDispatch();
  const [showIsotipoModal, setShowIsotipoModal] = useState({
    current: "",
    active: false,
  });
  const token = getCookie("session_token") || getCurrentUser().token;
  // const [errorImgIsotipo, setErrorImgIsotipo] = useState(false);
  // const [errorImgLogotipo, setErrorImgLogotipo] = useState(false);

  const createSlugName = (name) => {
    let slug = name.toLowerCase();
    slug = slug.replaceAll(" ", "");
    slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return slug;
  };

  const onFileChange = (e, type) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    const picture = files[0];
    if (type === "logo") {
      setPictureData(picture);
      createImage(files[0], "logo");
    } else {
      setPictureDataLogotipo(picture);
      createImage(files[0], "logotipo");
    }
  };

  const createImage = (file, type) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      const preview = e.target.result;
      let image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        if (type === "logo") {
          setImgPreview(preview);
          // if (this.width > 800 || this.height < 1000) {
          //   setErrorImgIsotipo(true);
          // } else {
          //   setErrorImgIsotipo(false);
          // }
        } else {
          setImgPreviewLogotipo(preview);
          // if (this.width < 1000 || this.height > 500) {
          //   setErrorImgLogotipo(true);
          // } else {
          //   setErrorImgLogotipo(false);
          // }
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const createNewInstitution = async (form) => {
    const name = form.institutionName;
    const location = form.institutionLocation;
    const pyme_plan_name = form.pymesProgram;
    const slug = createSlugName(name);

    let institutionData = {
      name,
      // slug_landing: slug,
      location,
      pyme_plan_name,
      color: colorCompany,
    };

    if (pictureData?.name?.includes("http") === false) {
      /* local img */
      institutionData = {
        ...institutionData,
        logo: pictureData,
      };
    }
    if (pictureDataLogotipo?.name?.includes("http") === false) {
      /* local img */
      institutionData = {
        ...institutionData,
        logotype: pictureDataLogotipo,
      };
    }

    const configs = { "content-type": "multipart/form-data" };

    let formData = new FormData();
    for (let data in institutionData) {
      formData.append(data, institutionData[data]);
    }

    await api
      .axiosAuthPatch(
        `api/v1/corporation/${company.id}/`,
        token,
        formData,
        configs
      )
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          notifyCool("Informacion actualizada");
          dispatch(fetchCorpData());
        } else {
          notifyBad("¡Hubo un error!");
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          institutionName: formState?.name || "",
          institutionLocation: formState?.location || "",
          pymesProgram: formState?.pyme_plan_name || "",
        }}
        onSubmit={(values) => {
          // if (errorImgIsotipo || errorImgLogotipo) {
          //   return;
          // }
          createNewInstitution(values);
        }}
        validationSchema={Yup.object({
          institutionName: Yup.string()
            .required("Este campo es obligatorio")
            .matches(
              /^[a-zA-z0-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-z0-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-z0-ZÀ-ÿ\u00f1\u00d1]+$/g,
              "Formato no válido"
            ),
          institutionLocation: Yup.string().required(
            "Este campo es obligatorio"
          ),
          pymesProgram: Yup.string().required("Este campo es obligatorio"),
        })}
      >
        {() => (
          <>
            <div className="stepBoxForm">
              <h1 className="configuration-titleContainer">
                Acerca de tu programa institucional
              </h1>
              <FormFormik>
                <Container fluid className="stepBoxContainer">
                  <Row>
                    <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                      <Form.Group>
                        <label>Nombre de tu entidad</label>
                        <Field
                          name="institutionName"
                          id="institutionName"
                          className="inputNewBrand main-btn-align form-control"
                          placeholder="Nombre comercial"
                          type="text"
                        />
                        <ErrorMessage
                          name="institutionName"
                          component="div"
                          className="frmError"
                          style={{ marginTop: "0px" }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                      <Form.Group>
                        <label>Ubicación principal</label>

                        <Field
                          name="institutionLocation"
                          id="institutionLocation"
                          className="inputNewBrand main-btn-align form-control"
                          placeholder="Ciudad"
                          type="text"
                        />
                        <ErrorMessage
                          name="institutionLocation"
                          component="div"
                          className="frmError"
                          style={{ marginTop: "0px" }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                      <Form.Group>
                        <label>
                          Elige el nombre del programa para tu red de empresas{" "}
                          <span>Máximo 200 caracteres</span>
                        </label>
                        <Field
                          name="pymesProgram"
                          id="pymesProgram"
                          className="inputNewBrand main-btn-align form-control"
                          placeholder="Ejemplo: Programa de apoyo para PyMEs"
                          type="text"
                        />
                        <ErrorMessage
                          name="pymesProgram"
                          component="div"
                          className="frmError"
                          style={{ marginTop: "0px" }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="container-logos">
                    <div className="container-logo">
                      <div className="container-logoLabel">
                        <p className="label-logo">Isotipo de tu entidad</p>
                        <img
                          src={MoreInfo}
                          alt="Más información"
                          onClick={() =>
                            setShowIsotipoModal({
                              current: "isotipo",
                              active: true,
                            })
                          }
                        />
                      </div>
                      <div className="container-file">
                        <div
                          style={{
                            height: 100,
                            width: 100,
                            display: "inline-block",
                            borderRadius: "50%",
                            border: "dashed 1px #979797",
                            backgroundImage: `url(${
                              imgPreview ? imgPreview : logoBgAdd
                            })`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                          className="configuration-containerIMG"
                        />
                        <div className="container-btnFile">
                          <label
                            htmlFor="file-upload"
                            className="btn-style main-btn-align"
                            style={{
                              width: 200,
                              backgroundColor: "#ECECEC",
                              color: "#232323",
                              justifyContent: "center",
                              fontFamily: "Inter",
                              fontWeight: 500,
                              fontSize: "16px",
                            }}
                          >
                            Seleccionar Archivo
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            name="file"
                            onChange={(e) => onFileChange(e, "logo")}
                          />
                        </div>
                      </div>
                      {/* {errorImgIsotipo && (
                        <div className="frmError" style={{ marginTop: "0px" }}>
                          No cumple con las medidas recomendadas
                        </div>
                      )} */}
                    </div>
                    <div className="container-logo">
                      <div className="container-logoLabel">
                        <p className="label-logo">Logotipo de tu entidad</p>
                        <img
                          src={MoreInfo}
                          alt="Más información"
                          onClick={() =>
                            setShowIsotipoModal({
                              current: "logotipo",
                              active: true,
                            })
                          }
                        />
                      </div>
                      <div className="container-file">
                        <div className="container-imgLogotipo">
                          <div
                            style={{
                              height: 60,
                              width: 100,
                              display: "inline-block",
                              border: "dashed 1px #979797",
                              backgroundImage: `url(${
                                imgPreviewLogotipo
                                  ? imgPreviewLogotipo
                                  : logoBgAdd
                              })`,
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                            }}
                            className="configuration-containerIMG-logotype"
                          />
                        </div>
                        <div className="container-btnFile">
                          <label
                            htmlFor="file-uploadLogotipo"
                            className="btn-style main-btn-align"
                            style={{
                              width: 200,
                              backgroundColor: "#ECECEC",
                              color: "#232323",
                              justifyContent: "center",
                              fontFamily: "Inter",
                              fontWeight: 500,
                              fontSize: "16px",
                            }}
                          >
                            Subir otro archivo
                          </label>
                          <input
                            id="file-uploadLogotipo"
                            type="file"
                            name="fileLogotipo"
                            onChange={(e) => onFileChange(e, "logotipo")}
                          />
                        </div>
                      </div>
                      {/* {errorImgLogotipo && (
                        <div className="frmError" style={{ marginTop: "0px" }}>
                          No cumple con las medidas recomendadas
                        </div>
                      )} */}
                    </div>
                  </Row>

                  <Row>
                    <Col
                      md={12}
                      style={{
                        paddingLeft: 8,
                        paddingRight: 0,
                        marginBottom: "25px",
                      }}
                    >
                      <label style={{ marginBottom: "10px" }}>
                        Color más cercano a tu imagen
                      </label>
                      <CompactPicker
                        color={colorCompany}
                        onChangeComplete={(color) => setColorCompany(color.hex)}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col md={12} style={{ padding: "0px 0px", paddingLeft: 8 }}>
                      <button
                        type="submit"
                        className={"frmButton"}
                        style={{ marginTop: 0, marginBottom: "25px" }}
                      >
                        Guardar
                      </button>
                    </Col>
                  </Row>
                </Container>
              </FormFormik>
              <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
            <ModalHelpLogos
              showIsotipoModal={showIsotipoModal}
              setShowIsotipoModal={setShowIsotipoModal}
            />
          </>
        )}
      </Formik>
    </>
  );
};
