import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Col, Form } from "react-bootstrap";
import Check from "../../assets/images/onboarding/Check.png";
import { useDispatch } from "react-redux";
import { fetchCorpData } from "../../features/corp_data/corpData";

import api from "../../utils/axiosUsage";
import { notifyBad, notifyCool } from "../../utils/toastNotifications";
import { getCookie } from "../../utils/cookiesUtils";
import { getCurrentUser } from "../../utils/authUtils";

export const FormInstitutionEdit = ({ formState, user, company }) => {
  const dispatch = useDispatch();
  const token = getCookie("session_token") || getCurrentUser().token;
  const [employees, setEmployees] = useState(
    formState.segmentation_type_empleados || false
  );
  const [sectors, setSectors] = useState(
    formState.segmentation_type_sectores || false
  );
  const [sales, setSales] = useState(
    formState.segmentation_type_ventas || false
  );
  const [segmentedOther, setSegmentedOther] = useState(
    formState.segmentation_type_otros || false
  );
  const [segmentedNone, setSegmentedNone] = useState(
    formState.segmentation_type_ninguno || false
  );

  const [email, setEmail] = useState(
    formState.communication_type_email || false
  );
  const [sms, setSms] = useState(
    formState.communication_type_sms_whatsapp || false
  );
  const [socialMedia, setSocialMedia] = useState(
    formState.communication_type_rr_ss_grupos || false
  );
  const [events, setEvents] = useState(
    formState.communication_type_eventos || false
  );
  const [webinars, setWebinars] = useState(
    formState.communication_type_eventos || false
  );
  const [comunnicationOther, setComunnicationOther] = useState(
    formState.communication_type_otro || false
  );

  const [showErrorsSegmented, setShowErrorsSegmented] = useState(false);
  const [showErrorsCommunication, setShowErrorsCommunication] = useState(false);

  const onSegmentedNone = () => {
    setEmployees(false);
    setSectors(false);
    setSales(false);
    setSegmentedOther(false);
    setSegmentedNone(true);
  };

  const validateSegmented = () => {
    if (!employees && !sectors && !sales && !segmentedOther && !segmentedNone) {
      setShowErrorsSegmented(true);
      return false;
    } else {
      setShowErrorsSegmented(false);
      return true;
    }
  };
  const validateCommunication = () => {
    if (
      !email &&
      !sms &&
      !socialMedia &&
      !events &&
      !webinars &&
      !comunnicationOther
    ) {
      setShowErrorsCommunication(true);
      return false;
    } else {
      setShowErrorsCommunication(false);
      return true;
    }
  };

  const updateCompany = async (form) => {
    const typeInstitution = form.typeInstitution;
    const numberInNetwork = form.numberInNetwork;

    const companyData = {
      institution_type: typeInstitution,
      smbs_number_in_network: numberInNetwork,
      segmentation_type_empleados: employees,
      segmentation_type_sectores: sectors,
      segmentation_type_ventas: sales,
      segmentation_type_otros: segmentedOther,
      segmentation_type_ninguno: segmentedNone,
      communication_type_email: email,
      communication_type_sms_whatsapp: sms,
      communication_type_rr_ss_grupos: socialMedia,
      communication_type_eventos: webinars,
      communication_type_otro: comunnicationOther,
    };

    await api
      .axiosAuthPatch(`/api/v1/corporation/${company.id}/`, token, companyData)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          notifyCool("Informacion actualizada");
          dispatch(fetchCorpData());
        } else {
          notifyBad("¡Hubo un error!");
        }
      });
  };

  return (
    <Formik
      initialValues={{
        typeInstitution: formState.institution_type || "OP",
        numberInNetwork: formState.smbs_number_in_network || "",
      }}
      onSubmit={(values) => {
        if (!validateSegmented() || !validateCommunication()) {
          return;
        }
        updateCompany(values);
      }}
      validationSchema={Yup.object({
        typeInstitution: Yup.string().required("Este campo es obligatorio"),
        numberInNetwork: Yup.number()
          .required("Este campo es obligatorio")
          .integer("No es un número válido")
          .positive("No es un número válido"),
      })}
    >
      {() => (
        <>
          <div className="stepBoxForm">
            <h1 className="configuration-titleContainer">
              Acerca de la institución
            </h1>
            <FormFormik>
              <Container fluid className="stepBoxContainer">
                <Row>
                  <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <label>Tipo de entidad</label>
                    <Field
                      name="typeInstitution"
                      as={"select"}
                      className="inputNewBrand main-btn-align form-control"
                    >
                      <option value="">Selecciona</option>
                      <option value="Cámara de comercio">
                        Cámara de comercio
                      </option>
                      <option value="Secretaría de Gobierno">
                        Secretaría de Gobierno
                      </option>
                      <option value="Caja de compensación">
                        Caja de compensación
                      </option>
                      <option value="Banco de desarrollo">
                        Banco de desarrollo
                      </option>
                      <option value="Banco empresarial">
                        Banco empresarial
                      </option>
                      <option value="SaaS">SaaS</option>
                      <option value="Fintech">Fintech</option>
                      <option value="Comunidad o programa">
                        Comunidad o programa
                      </option>
                      <option value="Otro">Otro</option>
                    </Field>
                    <ErrorMessage
                      name="typeInstitution"
                      component="div"
                      className="frmError"
                      style={{ marginTop: "0px" }}
                    />
                  </Col>
                  <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>Número de PyMes de su red</label>

                      <Field
                        name="numberInNetwork"
                        id="numberInNetwork"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="Número de empresas en tu red"
                        type="number"
                      />
                      <ErrorMessage
                        name="numberInNetwork"
                        component="div"
                        className="frmError"
                        style={{ marginTop: "0px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>¿Cómo las tienes segmentadas?</label>
                      <div className="container-checks">
                        <div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setEmployees((prev) => !prev)}
                              className={
                                employees
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {employees && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Número de empleados
                            </p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSectors((prev) => !prev)}
                              className={
                                sectors
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {sectors && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Tipo de sectores
                            </p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSales((prev) => !prev)}
                              className={
                                sales
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {sales && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Número de ventas
                            </p>
                          </div>
                        </div>
                        <div className="container-checkSecond">
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSegmentedOther((prev) => !prev)}
                              className={
                                segmentedOther
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {segmentedOther && (
                                <img src={Check} alt="check" />
                              )}
                            </div>
                            <p className="onboarding-checkTxt">Otros</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={onSegmentedNone}
                              className={
                                segmentedNone
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {segmentedNone && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Ninguno</p>
                          </div>
                        </div>
                      </div>
                      {showErrorsSegmented && (
                        <div className="frmError" style={{ marginTop: "0px" }}>
                          Este campo es obligatorio
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>¿Cómo comunican con su red?</label>
                      <div className="container-checks">
                        <div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setEmail((prev) => !prev)}
                              className={
                                email
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {email && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Mail</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSms((prev) => !prev)}
                              className={
                                sms
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {sms && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              SMS o WhatsApp
                            </p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSocialMedia((prev) => !prev)}
                              className={
                                socialMedia
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {socialMedia && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Redes sociales y grupos
                            </p>
                          </div>
                        </div>
                        <div className="container-checkSecond">
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setEvents((prev) => !prev)}
                              className={
                                events
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {events && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Eventos</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setWebinars((prev) => !prev)}
                              className={
                                webinars
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {webinars && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Webinars</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() =>
                                setComunnicationOther((prev) => !prev)
                              }
                              className={
                                comunnicationOther
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {comunnicationOther && (
                                <img src={Check} alt="check" />
                              )}
                            </div>
                            <p className="onboarding-checkTxt">Otro</p>
                          </div>
                        </div>
                      </div>
                      {showErrorsCommunication && (
                        <div className="frmError" style={{ marginTop: "0px" }}>
                          Este campo es obligatorio
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col
                    md={12}
                    style={{
                      padding: "30px 0px",
                      paddingLeft: 8,
                      marginBottom: "20px",
                    }}
                  >
                    <button type="submit" className={"frmButton"}>
                      Guardar
                    </button>
                  </Col>
                </Row>
              </Container>
            </FormFormik>
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </>
      )}
    </Formik>
  );
};
