import styled from "styled-components";

export const MainTitle = styled.p`
  // font-family: "Cy", sans-serif;
  // font-style: normal;
  // font-weight: bold;
  // font-size: 2rem;
  // line-height: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: flex-start;
  display: flex;
  div {
    margin-bottom: 12px;
    margin-right: 12px;
  }

  font-family: Raleway;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
`;

export const HomeTitle = styled.p`
  // font-family: "Cy", sans-serif;
  // font-style: normal;
  // font-weight: bold;
  // font-size: 2rem;
  // line-height: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: flex-start;
  display: flex;
  div {
    margin-bottom: 12px;
    margin-right: 12px;
  }
  color: #3c62eb;
  font-family: Raleway;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: -0.02em;
`;

export const NewHomeTitle = styled.h1`
  margin-top: 50px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: flex-start;
  display: flex;
  div {
    margin-bottom: 12px;
    margin-right: 12px;
  }
  color: #000000;
  font-family: Raleway;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: -0.02em;
`;

export const NewHomeSubTitle = styled.h2`
  margin-top: 30px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: flex-start;
  display: flex;
  color: #000000;
  font-family: Raleway;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: -0.02em;
`;

export const CorporationLogoContainer = styled.div`
  margin-bottom: 1.2rem;
  margin-top: 1.2rem;
  width: 150px;
  img {
    width: 100%;

    height: auto;
  }
`;
export const TitleDescription = styled.span`
  font-family: "Inter", sans-serif;
  margin-left: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #979797;
`;

export const BtnActionTitle = styled.a`
  background: #fff;
  height: 42px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  padding: 13px 20px;
  text-align: center;
  border: solid 1px #d8dbe6;
  border-radius: 10px;
  margin-left: 12px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  :hover {
    background: #2c5999;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const LograLink = styled.a`
  // border-bottom: 2px solid
  //   ${(props) =>
    props.theme.mainColor ? props.theme.mainColor : "#2c5999"};
  color: ${(props) =>
    props.theme.mainColor ? props.theme.mainColor : "#2c5999"};
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 19.2px;
  font-size: 16px;
  margin-top: 5px;
  :hover {
    text-decoration: none;
    color: ${(props) =>
      props.theme.mainColor ? props.theme.mainColor : "#2c5999"};
  }
`;

export const LograCard = styled.div`
  background: #fff;
  border: 1px solid #c5cfd6;
  border-radius: 6px;
  display: block;
  margin: 0px;
  padding: 30px;
  h4 {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #14142b;
  }
  p {
    margin: 15px 0px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #14142b;
  }
  .landingLink {
    background: #f0f0f0;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      right: 10%;
    }
  }
  .fileCopyImg {
    cursor: pointer;
  }
  .sharedLinks {
    display: flex;
    li:first-child {
      margin-left: 0px;
    }
    li {
      margin-left: 15px;
    }
  }

  .resourcesBox {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    .noBorder {
      border-bottom: none;
    }
    .resourceRow {
      display: flex;
      justify-content: space-between;
      border-bottom: solid thin #f0f0f0;
      margin-top: 20px;
      align-items: center;
      .boxColumn {
        display: flex;
        margin-bottom: 12px;
        h6 {
          margin-left: 12px;
          font-family: "Cy";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
        }
        span {
          color: #999;
          font-size: 14px;
          margin-left: 12px;
          margin-top: 3px;
        }
        .boxText {
          display: flex;
          flex-direction: column;
        }
      }
      button {
        background: #2c5999;
        border-radius: 10px;
        border: none;
        color: #fff;
        width: 136px;
        height: 36px;
        font-size: 14px;
        margin-bottom: 12px;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;

        :hover {
          background: #fff;
          color: #000;
          text-decoration: none;
          cursor: pointer;
          border: solid thin #999;
        }
      }
    }
  }
`;

export const SubTitle = styled.p`
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 100%;
  margin-bottom: 30px;
`;
export const StyledLink = styled.div`
  margin-top: 10px;
`;
export const FormContainer = styled.div`
  padding: 1.5rem;
`;

export const Progress = styled.div`
  padding: 30px 0;
  max-width: 420px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
export const ProgressQuote = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #6e7191;
  margin-bottom: 50px;
`;
export const ProfileCard = styled.div`
  background: #fff;
  border-radius: 12px;
  display: block;
  margin: 20px 0 10px;
  padding: 30px;
  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
  .titleCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      background: rgba(47, 98, 170, 0.2);
      margin-right: 10px;
      padding: 9px;
    }
  }
  h4 {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #14142b;
  }
  .noData {
    color: #999;
    font-weight: normal;
  }
`;
export const BoxNoData = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  margin: 40px;
  justify-content: center;
  align-items: center;
  p {
    padding: 10px 29px;
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #999999;
  }
`;
export const ProfileProgressCard = styled.div`
  background: #fff;
  border-radius: 12px;
  display: block;
  margin-top: 30px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
  flex-wrap: wrap;
  .progressTitle {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #14142b;
  }
`;
export const ProfileProgressCardExtended = styled.div`
  background: #fff;
  border-radius: 12px;
  display: block;
  margin-top: 0px;
  margin-bottom: 40px;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 16px rgb(17 17 17 / 10%);
  p {
    color: #999999;
    font-size: 16px;
  }
  .progressTitle {
    font-family: "Cy", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #14142b;
  }
`;
export const ProgressInline = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

export const ProgressBox = styled.div`
  display: block;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  flex-wrap: wrap;
  .completed {
    width: 87px;
    height: 12px;
    margin: 0px 4px;
    background: #2c5999;
    border-radius: 40px;
  }
  .uncompleted {
    width: 87px;
    height: 12px;
    margin: 0px 4px;
    background: #e0e0e0;
    border-radius: 40px;
  }
`;
export const ProfileLogo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  img {
    width: 100px;
    margin-right: 15px;
    border: 1px solid #dde3e9;
    box-sizing: border-box;
    border-radius: 10px;
  }
`;
export const ProfileInfo = styled.div`
  h3 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #979797;
  }
  h2 {
    font-family: "Cy", sans-serif;
    font-style: normal;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #14142b;
  }
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
`;

export const ProfileBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  h3 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #979797;
  }
`;

export const ProfileLocation = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

export const ProfileProgress = styled.div`
  background: #dde3e9;
  border-radius: 12px;
  margin-top: 50px;
  padding: 20px;
`;
export const OperationOption = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #14142b;
    margin-bottom: 12px;
  }
`;
export const TagOption = styled.p`
  background: #d8dbe6;
  height: 42px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  padding: 13px 20px;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  margin-right: 15px;
  margin-bottom: 5px;
`;
export const DateSelection = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: end;
  margin-top: 30px;
  select {
    max-width: 300px;
    margin-left: 15px;
  }
`;
export const DashboardCard = styled.div`
  background: #fff;
  border: 1px solid #c5cfd6;
  border-radius: 12px;
  margin-top: 30px;
  padding: 50px 20px;
  display: flex;
`;
export const DashboardCardBig = styled.div`
  background: #fff;
  border: 1px solid #c5cfd6;
  border-radius: 12px;
  margin-top: 30px;
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
`;
export const DashboardCardInfo = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #979797;
    margin-bottom: 10px;
  }
  h2 {
    font-weight: 600;
  }
  img {
    margin-right: 10px;
  }
`;
export const DataDashboard = styled.div`
  background: #fff;
  border: 1px solid #c5cfd6;
  border-radius: 12px;
  margin-top: 30px;
  padding: 50px 30px;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
`;
export const DataDashboardTable = styled.div`
  // width: 50%;
  width: 100%;
  table {
    width: 100%;
  }
  thead {
    font-size: 14px;
    opacity: 0.7;
  }
  td {
    padding: 10px;
  }
  img {
    margin-right: 10px;
  }
  tr {
    padding: 10px 0;
  }
  tbody tr:nth-child(even) {
    background-color: #f0f0f0;
  }
`;
export const DataDashboardTablePymes = styled.div`
  width: 100%;
  table {
    width: 100%;
  }
  thead {
    font-size: 14px;
    opacity: 0.7;
  }
  td {
    padding: 10px;
  }
  img {
    margin-right: 10px;
  }
  tr {
    padding: 10px 0;
  }
  tbody tr:nth-child(even) {
    background-color: #f0f0f0;
  }
`;
export const DataDashboardGraph = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
`;
