import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import api from "../../utils/axiosUsage";
import { Loading } from "@logra/component-library";
import StartSlider from "../../Components/StartSlider/StartSlider";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/pages/invitation/index.scss";
import { clearAllCookies } from "../../utils/cookiesUtils";
import { clearSessionData } from "../../utils/authStorageUtils.tsx";
import FormNewMember from "./NewMember";

const Invitation = (url) => {
  const { token } = useParams();
  const session_active = useSelector((state) => state.user_session);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  /**
   *
   * This is the current code for invitations it only purpose is to pass the token and to display the current invitation if it exists and to pass the invitation object to the FormNewMember in order to create a new user
   */

  const history = useHistory();
  const [invitation, setInvitation] = useState({
    email: "",
    active: false,
    team: "",
    id: "",
    sender: "",
  });

  useEffect(() => {
    if (session_active.is_active) {
      clearSessionData(dispatch);
    } else {
      clearLocalStorage();
      clearAllCookies();
    }
    getInvitation(token).then(() => setLoading(true));
    return () => {};
  }, []);

  function clearLocalStorage() {
    localStorage.clear();
  }

  const getInvitation = async (token) => {
    try {
      const { data } = await api.axiosAuthGet(
        `/api/v1/public-invitation-ro/${token}/`
      );
      setInvitation({
        active: data.active,
        email: data.addressee,
        team: data.smb_team,
        id: data.id,
        sender: data.sender,
        corp: data.corp_id,
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <>
      <Container fluid className="landing-invitation-content">
        <Row className="invitationLayout-container">
          <Col xs={12} md={12} lg={6} className="columnIntro">
            <div className="invitationMember-containerForm">
              <div style={{ padding: "0.7rem" }}>
                <img src="/images/logos/logo.svg" alt="logra" />
              </div>
              {invitation && loading ? (
                invitation.active ? (
                  <div className="invitationFormLayout">
                    <h1> Bienvenido a Logra!</h1>
                    <p className="invitationMember-formMsg"></p>
                    <FormNewMember invitation={invitation} />
                    <p className="invitationMember-login">
                      ¿Ya tienes una cuenta?
                    </p>
                    <button
                      className="invitationPage-btnHaveLogin"
                      onClick={() => history.push("/")}
                    >
                      Inicia sesión
                    </button>
                  </div>
                ) : (
                  <>
                    <h1>Lo sentimos, la invitación ha expirado 😔</h1>
                    <p className="invitationMember-login">Inicia sesión</p>
                    <button
                      className="invitationPage-btnHaveLogin"
                      onClick={() => history.push("/")}
                    >
                      Inicia sesión
                    </button>
                  </>
                )
              ) : (
                <div>
                  <Loading />
                </div>
              )}
            </div>
          </Col>
          <div className="startCover">
            <StartSlider />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Invitation;
