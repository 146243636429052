import { Provider } from "react-redux";
import LograStore from "./store/Index";
import ErrorBoundary from "./Components/ErrorBarrel/ErrorBarrel";
import { RouterMain } from "./Routes/RouterMain";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Provider store={LograStore}>
      <ErrorBoundary>
        <RouterMain />
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
