import styled from "styled-components";

const TitleH1 = styled.div`
  margin-top: 60px;
  margin-bottom: 20px;
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
`;

const TitleH2 = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0px;
  color: #999999;
  margin-bottom: 45px;
`;

const InvitationFormik = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const InvitationInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  input {
    border-radius: 5px;
    width: 80%;
    border: 1px solid #d2d7de;
    height: 46px;
    padding: 10px;
    &:focus {
      outline: 2px solid #2c5999;
    }`;

const SubmitFormikButton = styled.button`
  width: 15%;
  background-color: #7a4199;
  border: none;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0px;
  color: white;
  border-radius: 5px;
  height: 46px;

  &:disabled {
    opacity: 0.5;
  }
`;

const TeamsContainer = styled.div`
  width: 100%;
  padding: 0.3rem;
  height: 13rem;
  overflow: auto;
`;

export {
  TitleH1,
  InvitationFormik,
  InvitationInputContainer,
  SubmitFormikButton,
  TeamsContainer,
  TitleH2,
};
