import React from "react";
import { useHistory } from "react-router-dom";
import { notifyBad } from "../../utils/toastNotifications";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { NewUserForm } from "../../Components/Invitations/NewUser";
import { ConnectInvitedUser } from "../../api/api-invitations.tsx";
import {
  setAuthCookies,
  setGlobalState,
  setLocalStorageValues,
} from "../../utils/authStorageUtils.tsx";

const FormNewMember = ({ invitation }) => {
  /**
   *
   * This component works as a complement to Invitations,the handler functions were abstracted in order to avoid adding responsability to the users form
   */

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [storedUser, setStoredUser] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  // Si el usuario no es dueño de una entidad, mostrar error y retornar false.
  function validateUser(isCorpOwner, isStaffDigitizeMe, user, company) {
    if (!isCorpOwner) {
      setError(true);
      notifyBad("Tienes que ser dueño de una entidad");
      setErrorMessage("Tienes que ser dueño de una entidad");
      return false;
    }
    return true;
  }

  // Función para manejar la conexión del usuario invitado.
  const HandleConnect = async (values) => {
    const { email, name, password, confirmPassword } = values;
    // Crea un nuevo usuario invitado y lo conecta a la corporación correspondiente
    // utilizando la información de invitación proporcionada.
    const createUser = await ConnectInvitedUser({
      email,
      name,
      password,
      confirmPassword,
      invitation: invitation,
      corp_id: invitation.corp,
    });
    if (createUser.success) {
      const { connectedUserData, companyData } = createUser;
      const isValidUser = validateUser(
        connectedUserData.is_corp_owner,
        connectedUserData.is_staff_digitizeme,
        connectedUserData,
        companyData
      );
      if (isValidUser) {
        // Si el usuario es válido, establecer las cookies y valores locales.
        setAuthCookies(
          connectedUserData,
          connectedUserData.is_staff_digitizeme
        );
        setLocalStorageValues(connectedUserData, companyData);
        // Si el usuario es válido, establecer las cookies y valores locales.
        setGlobalState(dispatch, connectedUserData.is_staff_digitizeme);
        // Redirigir al usuario a la página de inicio.
        history.push("/home");
      } else {
        setError(true);
        notifyBad("Algo fallo en el inicio de sesion");
        setErrorMessage("Algo fallo en el inicio de sesion");
      }
    } else {
      // Si no se pudo crear el usuario, mostrar error.
      setError(true);
      notifyBad(createUser.errors[0]);
      setErrorMessage(createUser.errors[0]);
    }
  };

  const errorStyles = {
    display: error ? "block" : "none",
    padding: "10px",
    marginBottom: 15,
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };

  return (
    <div>
      <NewUserForm
        invitation={invitation}
        submitHandler={HandleConnect}
        setStoredUser={setStoredUser}
      />
      <div style={errorStyles}>{errorMessage}</div>
    </div>
  );
};

export default FormNewMember;
