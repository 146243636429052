import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";
import { Button } from "react-bootstrap";
import React from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import api from "../../utils/axiosUsage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useState } from "react";
import { getCurrentUser } from "../../utils/authUtils";

const ConnectAccount = ({ handler }) => {
  const [users, setUsers] = useState([]);
  const formik = useFormik({
    initialValues: {
      selectedOption: "",
    },
    validationSchema: Yup.object({
      selectedOption: Yup.string().required("Debe seleccionar un usuario"),
    }),

    onSubmit: (values) => {
      handler(values);
      // console.log(values);
    },
  });

  useEffect(() => {
    getUsers();
    return () => {};
  }, []);

  const getUsers = async () => {
    try {
      const userData = getCurrentUser();
      const response = await api.axiosAuthGet(
        "/api/v1/profile-crud/",
        userData.token
      );
      if (response.status === 200) {
        setUsers(response.data.results);
      } else {
        setUsers([]);
      }
    } catch (err) {
      throw new Error(err);
    }
  };
  return (
    <div key="connect-form-account" className="ConnectAccount-container">
      <Form className="AdminloginContainer" onSubmit={formik.handleSubmit}>
        <Form.Group>
          <label>Selecciona un usuario</label>
          <select
            name="selectedOption"
            value={formik.values.selectedOption}
            onChange={formik.handleChange}
          >
            <option value="">Elegir una opcion</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {`${user.id} - ${user.name}`}
              </option>
            ))}
          </select>
        </Form.Group>
        <Form.Group>
          <Button type="submit" className="stepFormBtn">
            {formik.isSubmitting ? "Asignando..." : "Asignar"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default ConnectAccount;
