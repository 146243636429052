import React from "react";
import { Modal } from "react-bootstrap";
import Close from "../../assets/images/icons/close.png";
import IsotipoImg from "../../assets/images/modals/isotipoExample.png";
import LogotipoImg from "../../assets/images/modals/logotipoExample.png";
// import "../../assets/styles/modals/modal-soon.scss";
import "../../assets/styles/modals/modal-helpLogos.scss";

export const ModalHelpLogos = ({ showIsotipoModal, setShowIsotipoModal }) => {
  return (
    <Modal
      size="lg"
      centered
      show={showIsotipoModal.active}
      onHide={() =>
        setShowIsotipoModal({
          current: "",
          active: false,
        })
      }
    >
      <Modal.Body className="modalLogos-container">
        <img
          src={Close}
          alt="Cerrar"
          className="modalLogos-close"
          onClick={() =>
            setShowIsotipoModal({
              current: "",
              active: false,
            })
          }
        />
        <div className="modalLogos-contentContainer">
          <h1>
            {showIsotipoModal.current === "isotipo"
              ? "Elige el isotipo de tu entidad"
              : "Elige el logotipo de tu entidad"}
          </h1>
          <p>
            Selecciona una imagen con las medidas aproximadas para obtener un
            mejor resultado. 🚀
          </p>

          {showIsotipoModal.current === "isotipo" ? (
            <p>
              El isotipo se utilizará para los <span>espacios pequeños</span>.
            </p>
          ) : (
            <p>
              El logotipo se utilizará para los{" "}
              <span>espacios más grandes</span>.
            </p>
          )}

          <div className="modalLogos-exampleContainer">
            {showIsotipoModal.current === "isotipo" ? (
              <>
                <img src={IsotipoImg} alt="Ejemplo de isotipo" />
                <p>
                  Te recomendamos una medida de ancho máximo de{" "}
                  <span>800 pixeles</span> y de alto mínimo de{" "}
                  <span>800 pixeles</span>.
                </p>
              </>
            ) : (
              <>
                <img src={LogotipoImg} alt="Ejemplo de isotipo" />
                <p>
                  Te recomendamos una medida de ancho mínimo de{" "}
                  <span>1000 pixeles</span> y de alto máximo de{" "}
                  <span>500 pixeles</span>.
                </p>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
