import { HeaderSubtextCombo, Loading } from "@logra/component-library";
import React, { useMemo, useState } from "react";

/**

Renders an iframe containing an Amazon Web Services dashboard.
The component has an optional tooltip that can be used to display information related to the dashboard.
@param {string} width - The width of the iframe in pixels.
@param {string} height - The height of the iframe in pixels.
@param {string} url - The URL of the AWS dashboard to display in the iframe.
@param {Object} tooltip - An optional object containing the information to display in the tooltip.
@param {string} tooltip.title - The title to display in the tooltip.
@param {string} tooltip.subtext - The subtext to display in the tooltip.
@param {number} tooltip.titleSize - The font size of the title in rem.
@param {number} tooltip.subtextSize - The font size of the subtext in rem.
@param {string} tooltip.titleColor - The color of the title.
@param {string} tooltip.subtextColor - The color of the subtext.
@param {number} tooltip.buttonSize - The font size of the button in rem.
@param {number} tooltip.paddingBottom - The padding at the bottom of the tooltip in rem.
@param {number} tooltip.paddingTop - The padding at the top of the tooltip in rem.
@param {object} tooltip.styles - The object from the inline styles to the tooltips container.
@returns {JSX.Element} The AWS dashboard iframe.
*/

const AwsDashboard = ({ width, height, url, tooltip }) => {
  const memoizedUrl = useMemo(() => url, [url]);
  const [isLoading, setIsLoading] = useState(true);

  //Variables del tooltip por defecto
  let title = "";
  let subtext = "";
  let titleSize = 0;
  let subtextSize = 0;
  let titleColor = "";
  let buttonSize = 0;
  let subtextColor = "";
  let paddingBottom = 0;
  let paddingTop = 0;
  let styles = {};

  if (tooltip) {
    const {
      title: tooltipTitle,
      subtext: tooltipSubtext,
      titleSize: tooltipTitleSize,
      subtextSize: tooltipSubtextSize,
      titleColor: tooltipTitleColor,
      buttonSize: tooltipButtonSize,
      subtextColor: tooltipSubtextColor,
      paddingBottom: tooltipPaddingBottom,
      paddingTop: tooltipPaddingTop,
      styles: tooltipStyles,
    } = tooltip;

    // Actualizar las variables del tooltip solo si se proporciona el objeto tooltip
    title = tooltipTitle || title;
    subtext = tooltipSubtext || subtext;
    titleSize = tooltipTitleSize || titleSize;
    subtextSize = tooltipSubtextSize || subtextSize;
    titleColor = tooltipTitleColor || titleColor;
    buttonSize = tooltipButtonSize || buttonSize;
    subtextColor = tooltipSubtextColor || subtextColor;
    paddingBottom = tooltipPaddingBottom || paddingBottom;
    paddingTop = tooltipPaddingTop || paddingTop;
    styles = tooltipStyles || styles;
  }

  const handleLoad = () => {
    setIsLoading(false);
  };

  const loadingStyles = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const iframeStyles = {
    display: isLoading ? "none" : "block",
    paddingBottom: "3rem",
    marginLeft: "1rem",
    marginTop: "2.3rem",
  };

  return (
    <>
      {isLoading && (
        <div className="aws-dashboard-loading" style={loadingStyles}>
          <Loading />
        </div>
      )}
      {tooltip && (
        <div
          id="aws-dashboard-tooltip"
          role="tooltip"
          aria-hidden={!isLoading}
          style={styles ? styles : ""}
        >
          <HeaderSubtextCombo
            title={title}
            subtext={subtext}
            titleSize={titleSize}
            subtextSize={subtextSize}
            buttonSize={buttonSize}
            titleColor={titleColor}
            subtextColor={subtextColor}
            paddingBottom={paddingBottom}
            paddingTop={paddingTop}
          />
        </div>
      )}

      <iframe
        title="aws-dashboard"
        width={width}
        height={height}
        src={memoizedUrl}
        onLoad={handleLoad}
        style={iframeStyles}
        aria-label="Amazon Web Services dashboard"
        aria-describedby={tooltip ? "aws-dashboard-tooltip" : ""}
      ></iframe>
    </>
  );
};

export default AwsDashboard;
