import { useState } from "react";

import { useSelector } from "react-redux";
import { Badge, Card, Col, Row, Stack } from "react-bootstrap";
import { IoDocumentText } from "react-icons/io5";
import { PiHashBold } from "react-icons/pi";
import PropTypes from 'prop-types';

import * as AffiliatesApi from "../../../api/api-affiliates";
import { getCurrentCompany } from "../../../utils/authUtils";
import { SolidButton } from "../../../assets/styles/ui/Buttons";
import { notifyBad, notifyCool } from "../../../utils/toastNotifications";
import { CustomModal } from "../../../shared/components/CustomModal";

const DOCUMENT_TYPE = {
  "CO1N": "NIT",
  "CO1C": "Cédula de ciudadanía",
}

export default function PymeCard({
  accessToken,
  companyName,
  documentNumber,
  documentType,
  active,
  setAffiliate,
}) {
  // ----[states]----
  const [showModal, setShowModal] = useState(false);

  // ----[hooks]----
  const corporation_data =
    useSelector((state) => state.corp_data.data) || getCurrentCompany();

  // ----[functions]----
  const handleDisableLicense = async () => {
    try {
      const response = await AffiliatesApi.disableLicense(accessToken, documentNumber);
      setAffiliate(response.data);
      notifyCool("Empresa deshabilitada correctamente");
    } catch (_) {
      notifyBad("Error al deshabilitar la empresa");
    } finally {
      setShowModal(false);
    }
    await AffiliatesApi.disableLicense(accessToken, documentNumber);
  }

  // ----[render]----
  return (
    <>
      <Card>
        <Card.Header as="div">
          <Stack direction="horizontal">
            <h3 className="fs-5">{companyName}</h3>
            <Badge
              className="text-uppercase ms-auto"
              bg={active ? "success" : "secondary"}
            >
              {active
                ? "Activa"
                : "Desactivada"
              }
            </Badge>
          </Stack>
        </Card.Header>
        <Card.Body>
          {
            DOCUMENT_TYPE[documentType] && (
              <Row>
                <Col>
                  <Stack direction="horizontal" gap={2}>
                    <IoDocumentText color={corporation_data.color} />
                    <Card.Text className="fw-bold">
                      Tipo de documento:
                    </Card.Text>
                    <Card.Text className="ps-2">{DOCUMENT_TYPE[documentType]}</Card.Text>
                  </Stack>
                </Col>
              </Row>
            )
          }
          <Row className="mt-2">
            <Col>
              <Stack direction="horizontal" gap={2}>
                <PiHashBold color={corporation_data.color} />
                <Card.Text className="fw-bold">
                  Número de documento:
                </Card.Text>
                <Card.Text className="ps-2">{documentNumber}</Card.Text>
              </Stack>
            </Col>
          </Row>
          <div className="d-flex mt-3 justify-content-end">
            <SolidButton
              color="danger"
              disabled={!active}
              onClick={() => setShowModal(true)}
              autoFocus
            >Desactivar empresa</SolidButton>
          </div>
        </Card.Body>
      </Card>
      <CustomModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        isSubmitting={false}
        onConfirm={handleDisableLicense}
        title="¿Estás seguro?"
        message="La empresa será deshabilitada y no podrá realizar operaciones"
        variant="danger"
      />
    </>
  );
}

PymeCard.propTypes = {
  accessToken: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  documentNumber: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  setAffiliate: PropTypes.func.isRequired,
};