import axios from "axios"

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const disableLicense = async (accessToken, document_number) =>
  axios.get(`${API_BASE_URL}/api/v1/manage_affiliate/disable/${document_number}/`, {
    headers: { 'Authorization': `Bearer ${accessToken}` }
  })

export const searchAffiliate = async (accessToken, document_number) =>
  axios.get(`${API_BASE_URL}/api/v1/manage_affiliate/search/${document_number}/`, {
    headers: { 'Authorization': `Bearer ${accessToken}` }
  })