import React from "react";
import PropTypes from "prop-types";
import "../../assets/styles/pages/configuration/team-data.scss";
import useTruncate from "../../hooks/useTruncate";
import teamsIcon from "../../assets/images/icons/teamsIcon.png";
import defaultAvatar from "../../assets/images/icons/user.svg";
import testAvatar from "../../assets/images/icons/test_avatar.jpg";
import { Link } from "react-router-dom";
import { Loading } from "@logra/component-library";

const Member = ({ avatar, name }) => {
  const validatedName = useTruncate(name, 16);
  return (
    <div className="member-row">
      <img
        className="member-avatar"
        src={avatar ? avatar : defaultAvatar}
        alt="member-avatar"
      />
      <p className="member-name">{name ? validatedName : "Usuario logra"}</p>
    </div>
  );
};

Member.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
};

const TeamData = ({ team, loading }) => {
  return (
    <div className="team-data-container">
      <div className="team-data-header">
        <div className="team-data-header-row">
          <div className="teams-data-logoContainer">
            <img
              className="team-data-logoImg"
              src={teamsIcon}
              alt="team-data-logo"
            />
          </div>
          <p className="team-data-headerTitle">Equipo</p>
        </div>
        <Link
          to={{ pathname: "/configuration/edit_profile", state: { step: "3" } }}
          className="team-data-header-btn"
        >
          + Agregar
        </Link>
      </div>
      <div className="team-data-body">
        {loading ? (
          <div className="teamdataLoading-container">
            <Loading />
          </div>
        ) : team.length === 0 ? (
          <p>No hay miembros añadidos</p>
        ) : (
          <>
            {team.map((member, index) => (
              <Member
                key={index}
                name={member.name}
                avatar={member.profile_photo}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

TeamData.propTypes = {
  team: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      profile_photo: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
};

export default TeamData;
