import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteCookie, getCookie } from "../utils/cookiesUtils";
import { deactivateSession } from "../features/user_session/userSessionSlice";
import { getTimeTokenRest } from "../utils/tokenValidation";

function useSessionVerification() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const clearSessionInformation = () => {
      dispatch(deactivateSession());
      deleteCookie("session_token");
      deleteCookie("userSession");
      localStorage.removeItem("user");
      localStorage.removeItem("company");
      setIsLoading(false);
    };

    const sessionToken = getCookie("session_token");
    const userSession = getCookie("userSession");

    if (!userSession || !sessionToken) {
      clearSessionInformation();
    } else {
      const sessionTokenLife = getTimeTokenRest(sessionToken);
      if (sessionTokenLife <= 1) {
        clearSessionInformation();
        window.location.reload();
        return;
      }
      setIsLoading(false);
    }
  }, [dispatch]);

  return isLoading;
}

export default useSessionVerification;
