import { createSlice } from "@reduxjs/toolkit";
import fetchDashboardType from "./dashboardData";

const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    dashboard_type: null,
    dashboard_url: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardType.fulfilled, (state, action) => {
      const { dashboard_type, dashboard_url } = action.payload;
      state.dashboard_type = dashboard_type;
      state.dashboard_url = dashboard_url;
    });
    builder.addCase(fetchDashboardType.rejected, (state, action) => {
      state.dashboard_type = null;
      state.dashboard_url = null;
      state.error = "Fallo";
    });
  },
});

export default dashboardSlice.reducer;
