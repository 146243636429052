import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, Form as FormFormik } from "formik";

export const NewUserForm = ({ invitation, setStoredUser, submitHandler }) => {
  const initialValues = {
    email: invitation.email || "",
    password: "",
    confirmPassword: "",
    name: "",
    lastname: "",
  };
  const yupValidation = Yup.object({
    email: Yup.string().required("Este campo es obligatorio"),
    name: Yup.string().required("Este campo es obligatorio"),
    lastname: Yup.string().required("Este campo es obligatorio"),
    password: Yup.string()
      .required("Este campo es obligatorio")
      .min(8, "La contraseña requiere 8 caracteres"),
    confirmPassword: Yup.string()
      .required("Este campo es obligatorio")
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
  });

  const submitFrm = (form) => {
    setStoredUser(form);
    submitHandler(form);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        submitFrm(values);
      }}
      validationSchema={yupValidation}
    >
      {(formik) => (
        <FormFormik
          className="invitationMember-form"
          style={{ width: "100%", padding: "0.5rem" }}
        >
          <div className="emailField">
            <p className="invitationMember-label">Correo</p>
            <Field type="email" name="email" placeholder="Tu email" readOnly />
            <ErrorMessage
              name="email"
              className="invitationMember-error"
              component="p"
            />
          </div>
          <div className="nameField">
            <p className="invitationMember-label">¿Cuál es tu nombre?</p>
            <Field type="text" name="name" placeholder="Tu nombre" />
            <ErrorMessage
              name="name"
              className="invitationMember-error"
              component="p"
            />
          </div>
          <div className="lastNameField">
            <p className="invitationMember-label">¿Y tu apellido?</p>
            <Field type="text" name="lastname" placeholder="Tu apellido" />
            <ErrorMessage
              name="lastname"
              className="invitationMember-error"
              component="p"
            />
          </div>
          <div className="passwordField">
            <p className="invitationMember-label">Contraseña</p>
            <Field type="password" name="password" placeholder="Contraseña" />
            <ErrorMessage
              name="password"
              className="invitationMember-error"
              component="p"
            />
          </div>
          <div className="confirmPasswordField">
            <p className="invitationMember-label">Confirmar contraseña</p>
            <Field
              type="password"
              name="confirmPassword"
              placeholder="Confirmar contraseña"
            />
            <ErrorMessage
              name="confirmPassword"
              className="invitationMember-error"
              component="p"
            />
          </div>
          <button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? "Ingresando ...." : "Unirse"}
          </button>
        </FormFormik>
      )}
    </Formik>
  );
};
