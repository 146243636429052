import { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";

import PymeCard from "../../features/licenses/components/PymeCard";
import { LicenseForm } from "../../features/licenses/components/LicenseForm";
import { getCurrentUser } from "../../utils/authUtils";
import { H1, BodyIntro } from "../../assets/styles/styled/TextStyles"
import { NotFoundPyme } from "../../features/licenses/components/NotFoundPyme";
import "../../assets/styles/pages/licenses.scss";


export default function LicensesPage() {
  // ----[states]----
  const [token, setToken] = useState(null);
  const [affiliate, setAffiliate] = useState(null);

  // ----[effects]----
  useEffect(() => {
    setToken(getCurrentUser().token)
  }, []);

  // ----[render]----
  return (
    <Container fluid className="p-5 h-100 ">
      <Row>
        <Col>
          <div className="pb-3"><H1>Desactiva la licencia de una empresa</H1></div>
          <div><BodyIntro>Usando el número de NIT o Cédula Ciudadana, desactiva al instante su acceso a la plataforma.</BodyIntro></div>
        </Col>
      </Row>
      <Row className="mt-5 form-max-width">
        <Col xs={12}>
          <LicenseForm
            setAffiliate={setAffiliate}
            accessToken={token ?? ""}
          />
        </Col>
        <Col xs={12} sm={8} className="ps-4 pt-4">
          {affiliate && (
            Object.keys(affiliate).length === 0
              ? <NotFoundPyme />
              : <PymeCard
                accessToken={token}
                companyName={affiliate?.company_name}
                documentNumber={affiliate?.document_number}
                active={affiliate?.affiliation_active}
                documentType={affiliate?.document_type}
                setAffiliate={setAffiliate}
              />
          )}
        </Col>
      </Row>
    </Container>
  );
}