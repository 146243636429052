import { createSlice } from "@reduxjs/toolkit";
import { fetchReferalData } from "./referalData";

const referalDataSlice = createSlice({
  name: "referal_data",
  initialState: { data: null, timeStamp: null, error: null, loading: false },
  reducers: {
    resetData: (state) => {
      state.data = null;
      state.timeStamp = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferalData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReferalData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.referal_data;
        state.timeStamp = action.payload.timeStamp;
      })
      .addCase(fetchReferalData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default referalDataSlice.reducer;
