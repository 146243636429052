import styled from "styled-components";

export const H1 = styled.h1`
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 120%;
`;

export const H2 = styled.h2`
  font-family: "Cy", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
`;

export const H3 = styled.h3`
  font-family: "Cy", sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
`;

export const BodyIntro = styled.p`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 140%;
`;

export const BodyText = styled.p`
  font-weight: normal;
  line-height: 140%;
`;

export const BoldText = styled.p`
  font-weight: bold;
  line-height: 130%;
`;

export const Caption = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 120%;
`;

export const CaptionAlt = styled.p`
  color: #979797;
  font-family: "Cy", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 120%;
`;

export const Tag = styled.p`
  background: #d6d8e7;
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 100%;
  padding: 9px 15px;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  margin-right: 15px;
`;

export const TagCompetencia = styled(Tag)`
  background: #dd74a4;
  color: #80083f;
`;

export const TagEquipo = styled(Tag)`
  background: #51105a;
  color: #a77bad;
`;

export const TagFinanzas = styled(Tag)`
  background: #670e0a;
  color: #e26963;
`;

export const TagMarketing = styled(Tag)`
  background: #d2d6ed;
  color: #5c6cae;
`;

export const TagInnovacion = styled(Tag)`
  background: #e8dacb;
  color: #efa54d;
`;

export const TagProceso = styled(Tag)`
  background: #e2c3c1;
  color: #ed4e43;
`;

export const TagPlan = styled(Tag)`
  background: #c4e2df;
  color: #429d93;
`;

export const TagLegal = styled(Tag)`
  background: #ebf3f5;
  color: #83bcd1;
`;

export const TagNegocio = styled(Tag)`
  background: #e8d2d4;
  color: #97444a;
`;

export const TagStart = styled.p`
  background: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 100%;
  padding: 9px 15px;
  text-align: center;
  display: inline-block;
  border-radius: 20px;
  margin-right: 15px;
  color: #89c5cc !important;
  transition: background 0.25s ease;
  &:hover {
    background: #eff0f6;
  }
`;
