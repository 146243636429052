import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import StartSlider from "../../Components/StartSlider/StartSlider";
import { NavigateToButton } from "../../utils/routerUtils";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ForgotParagraph,
  ForgotSubtitle,
  ForgotTitle,
  LinksContainer,
} from "./RecoveryPasswordStyles";
import { notifyBad, notifyCool } from "../../utils/toastNotifications";
import api from "../../utils/axiosUsage";
import { getCookie } from "../../utils/cookiesUtils";
import { getCurrentUser } from "../../utils/authUtils";

const ForgotPassword = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("El correo electrónico no es válido")
        .required("El correo electrónico es requerido"),
    }),
    onSubmit: async (values) => {
      const email = values.email;
      const lowercase_email = email.toLowerCase();
      const credentials = { email: lowercase_email };
      try {
        const reset_password_response = await api.axiosPost(
          "/api/v1/request-password-reset",
          credentials
        );
        if (
          reset_password_response.status === 200 ||
          reset_password_response.status === 201
        ) {
          notifyCool("El correo ha sido enviado.");
        } else {
          notifyBad("No se pudo reestablecer la contraseña")
          console.error("No se pudo hacer el restablecimiento de contraseña");
        }
      } catch (err) {
        console.error(err);
        notifyBad("No se ha encontrado el usuario");
        handleError("No se ha encontrado el usuario");
      }
    },
  });

  const { touched, errors } = formik;

  const formStyle = {
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  };

  return (
    <Container fluid className="landing-content">
      <Row className="rowPage" style={{ display: "flex", flexWrap: "wrap" }}>
        <Col xs={12} md={12} lg={6} className="columnIntro">
          <div className="home-topbar logra-logo">
            <img src="/images/logos/logo.svg" alt="Logra.io logo" />
          </div>
          <Form
            className="loginContainer"
            style={formStyle}
            onSubmit={formik.handleSubmit}
          >
            <ForgotTitle>
              Te enviaremos un correo con las instrucciones 📩
            </ForgotTitle>
            <ForgotSubtitle>Reestablece tu contraseña</ForgotSubtitle>
            <Form.Group
              controlId="formBasicEmail"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <ForgotParagraph>Correo</ForgotParagraph>
              <Form.Control
                type="email"
                placeholder="Correo electrónico"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
              ) : null}
            </Form.Group>
            <Button type="submit" className="btn-style btn-text">
              Ingresar
            </Button>
            <LinksContainer>
              <p>¿Ya tienes una cuenta?</p>
              <NavigateToButton url={"/"} description="Inicia sesión" />
            </LinksContainer>
            {errorMessage && (
              <div style={{ color: "#CC3333", marginTop: "10px" }}>
                {errorMessage}
              </div>
            )}
            <br />
            <br />
          </Form>
        </Col>
      </Row>
      <div className="startCover">
        <StartSlider />
      </div>
    </Container>
  );
};

export default ForgotPassword;
