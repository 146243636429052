import styled from "styled-components";
import PropTypes from 'prop-types';

const ButtonBgColor = {
  primary: {
    default: "#2C5999",
    hover: "#33B3D1",
  },
  danger: {
    default: "#b20101",
    hover: "#ff8372",
  },
};

export const SolidButton = styled.button`
  background-color: ${props => ButtonBgColor[props.color]?.default || ButtonBgColor.primary.default};
  border: none;
  ${props => props.disabled && "opacity: 0.5;"}
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: white;
  border-radius: 5px;
  width: ${props => props.block ? "100%" : "fit-content"};
  padding: 10px 20px;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  transition: 0.3s;

  ${props =>
    !props.disabled && `
      &:hover {
        background-color: ${ButtonBgColor[props.color]?.hover || ButtonBgColor.primary.hover};
      }
    `
  }
`;

SolidButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'danger']),
  block: PropTypes.bool,
};