import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import api from "../../../utils/axiosUsage";
import CreateCorporation from "../../../Components/Onboarding/CreateCorporation";
import ConnectCorporation from "../../../Components/Onboarding/ConnectCorporation";
import { notifyBad, notifyCool } from "../../../utils/toastNotifications";
import { getCookie } from "../../../utils/cookiesUtils";
import { getCurrentUser } from "../../../utils/authUtils";
import { useHistory } from "react-router-dom";

const FrmSetCorporation = (props) => {
  const { storedUser, setInstitution, editedUser } = props;
  const history = useHistory();
  const [isCreateCorp, setIsCreateCorp] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const token = getCookie("session_token") || getCurrentUser().token;

  // Handle error when user creation fails
  const handleUserCreationError = () => {
    notifyBad("¡Hubo un error!");
    setError(true);
    setErrorMessage("Hubo un error en la creacion de usuario");
  };

  // Handle error when user connection fails
  const handleUserConnectionError = () => {
    notifyBad("¡Hubo un error!");
    setError(true);
    setErrorMessage("Hubo un error en la asignacion de usuario");
  };

  // Handle general error
  const handleGeneralError = () => {
    notifyBad("Ocurrió un error");
    setError(true);
    setErrorMessage("Ocurrio un error general");
  };

  // Handle user creation button click
  const handleCreateUser = () => {
    setIsCreateCorp(true);
    setShowOptions(true);
  };

  // Handle user connection button click
  const handleConnectUser = () => {
    setIsCreateCorp(false);
    setShowOptions(true);
  };

  // Handle user assignment to corporation
  const handleAsignUserToCorporation = async (corporationId) => {
    try {
      const corporation_data = await api.axiosAuthGet(
        `/api/v1/corporation/${corporationId}`
      );
      setInstitution(corporation_data.data);
      const response = await api.axiosAuthPatch(
        `/api/v1/profile-crud/${storedUser.id}/`,
        token,
        {
          corp: corporationId,
          is_corp_owner: true,
        }
      );
      if (response.status === 200 || response.status === 201) {
        notifyCool("Usuario asignado satisfactoriamente");
        history.push("/home");
      } else {
        handleUserConnectionError();
      }
    } catch (err) {
      handleGeneralError();
    }
  };

  const handleCreateNewCorporation = async (corpData) => {
    if (!corpData) {
      handleGeneralError();
      return false;
    }
    const config_headers = { "content-type": "multipart/form-data" };
    const response = await api.axiosAuthPost(
      "/api/v1/corporation/",
      token,
      corpData,
      config_headers
    );
    if (response.status === 200 || response.status === 201) {
      setInstitution(response.data);
      const corp_id = response.data.id;
      const edit_profile = await api.axiosAuthPatch(
        `/api/v1/profile-crud/${storedUser.id}/`,
        token,
        {
          is_corp_owner: true,
          corp: corp_id,
        }
      );
      if (edit_profile.status === 200 || edit_profile.status === 201) {
        notifyCool("Institucion asignada correctamente");
        history.push("/home");
      } else {
        handleUserConnectionError();
      }
    } else {
      handleUserCreationError();
    }
  };

  const errorMessageStyles = {
    display: error ? "block" : "none",
    padding: "10px",
    marginBottom: 15,
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };

  return (
    <Container fluid className="onboardingStepForm">
      <Row className="onboardingStepRow">
        <Col>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {showOptions ? (
              <>
                {isCreateCorp ? (
                  <CreateCorporation
                    handler={handleCreateNewCorporation}
                    editedUser={editedUser}
                  />
                ) : (
                  <ConnectCorporation handler={handleAsignUserToCorporation} />
                )}
              </>
            ) : (
              <div className="stepBoxSelectOption">
                <p>Selecciona una opcion</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={errorMessageStyles}>{errorMessage}</div>
          <div className="stepFormSelectionBtns">
            <Button className="stepFormBtn" onClick={handleCreateUser}>
              Asignalo a una nueva corporacion
            </Button>
            <Button className="stepFormBtn" onClick={handleConnectUser}>
              Asignalo a una corporacion existente
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FrmSetCorporation;
