import { Col, Row } from "react-bootstrap";

import NotFoundSvg from "../../../assets/svgs/general/not-found.svg";

export function NotFoundPyme() {
  return (
    <div className="mt-5">
      <Row className="justify-content-center">
        <Col xs={5} sm={3}>
          <img className="img-fluid" src={NotFoundSvg} alt="Not Found" />
        </Col>
      </Row>
      <h3 className="text-center mt-4">Empresa no encontrada</h3>
    </div>
  );
}