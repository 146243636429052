import { useState } from "react";
import { useEffect } from "react";

function useTruncate(text, maxLength) {
  const [truncatedText, setTruncatedText] = useState(text);

  useEffect(() => {
    if (text.length > maxLength) {
      setTruncatedText(text.slice(0, maxLength - 3) + "...");
    } else {
      setTruncatedText(text);
    }
  }, [text, maxLength]);

  return truncatedText;
}

export default useTruncate;
