import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCompany, getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";
import { getCookie } from "../../utils/cookiesUtils";

export const fetchTeamData = createAsyncThunk(
  "user/fetchTeamData",
  async () => {
    const company = getCompany();
    const currentUser = getCurrentUser();
    const corp_id = company.id || currentUser.corp;
    const token = getCookie("session_token") || currentUser.token;
    const response = await api.axiosAuthGet(
      `/api/v1/corporation-kpis-v2/`,
      token
    );
    console.log('ejecuto fetch team data');
    return {
      team_data: response.data,
      timeStamp: Date.now(),
    };
  }
);
