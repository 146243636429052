import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCurrentUser } from "../../utils/authUtils";
import { getCookie } from "../../utils/cookiesUtils";
import api from "../../utils/axiosUsage";
import * as Yup from "yup";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";

const SelectCorporation = (props) => {
  const { handler } = props;
  const [corporations, setCorporations] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const userData = getCurrentUser();
        const token = userData.token || getCookie("session_token");
        const response = await api.axiosAuthGet("/api/v1/corporation/", token);
        if (response.status === 200) {
          setCorporations(response.data.results);
        } else {
          setCorporations([]);
        }
      } catch (err) {
        throw new Error(err);
      }
    };
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      selectedOption: "",
    },
    validationSchema: Yup.object({}),

    onSubmit: (values) => {
      handler(values.selectedOption);
    },
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <MainTitle>Selecciona una entidad</MainTitle>
      <Form.Group>
        <SelectWrapper
          name="selectedOption"
          value={formik.values.selectedOption}
          onChange={formik.handleChange}
        >
          <Option value=""> Selecciona una entidad</Option>
          {corporations.map((corp, index) => (
            <Option key={`${index}-${corp.id}`} value={corp.id}>
              {`${corp.id} - ${corp.name}`}
            </Option>
          ))}
        </SelectWrapper>
      </Form.Group>
      <Button type="submit">Seleccionar una entidad</Button>
    </Form>
  );
};

export default SelectCorporation;

const SelectWrapper = styled.select`
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
    border-color: blue;
  }
`;
const Option = styled.option``;
