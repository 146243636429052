// import React from "react";
// import { LograLink } from "../../assets/styles/pages/general/GeneralStyles";
import { Container, Col, Row, Form } from "react-bootstrap";
import * as Yup from "yup";
import React, { useState } from "react";
import { useFormik } from "formik";

const CreateAccount = ({ handler }) => {
  const [error, setError] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      passwordConfirm: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required("Este campo es obligatorio"),
      firstname: Yup.string().required("Este campo es obligatorio"),
      lastname: Yup.string().required("Este campo es obligatorio"),
      password: Yup.string()
        .required("Este campo es obligatorio")
        .min(8, "La contraseña requiere 8 caracteres"),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref("password"), null], "Las contraseñas NO coinciden")
        .required("Este campo es obligatorio"),
    }),

    onSubmit: (values) => {
      handler(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Container fluid>
        <Row>
          <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
            <Form.Group>
              <label>Email</label>
              <Form.Control
                id="email"
                name="email"
                className="inputNewBrand main-btn-align"
                type="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </Form.Group>
            {formik.touched.email && formik.errors.email ? (
              <div className="frmError">{formik.errors.email}</div>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
            <Form.Group>
              <label>Nombre</label>
              <Form.Control
                className="inputNewBrand main-btn-align"
                type="text"
                name="firstname"
                id="firstname"
                onChange={formik.handleChange}
                placeholder="Tu nombre"
                onBlur={formik.handleBlur}
                value={formik.values.firstname}
              />
            </Form.Group>
            {formik.touched.firstname && formik.errors.firstname ? (
              <div className="frmError">{formik.errors.firstname}</div>
            ) : null}
          </Col>
          <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
            <Form.Group>
              <label>Apellido: </label>
              <Form.Control
                id="lastname"
                name="lastname"
                className="inputNewBrand main-btn-align"
                type="text"
                placeholder="Tu apellido"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastname}
              />
            </Form.Group>
            {formik.touched.lastname && formik.errors.lastname ? (
              <div className="frmError">{formik.errors.lastname}</div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
            <Form.Group>
              <label>
                Contraseña <span>Mínimo 8 caracteres</span>
              </label>
              <Form.Control
                id="password"
                name="password"
                className="inputNewBrand main-btn-align"
                type="password"
                placeholder="Contraseña"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </Form.Group>
            {formik.touched.password && formik.errors.password ? (
              <div className="frmError">{formik.errors.password}</div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
            <Form.Group>
              <label>Confirma tu contraseña</label>
              <Form.Control
                id="passwordConfirm"
                name="passwordConfirm"
                className="inputNewBrand main-btn-align"
                type="password"
                placeholder="Confirma tu contraseña"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirm}
              />
            </Form.Group>
            {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
              <div className="frmError">{formik.errors.passwordConfirm}</div>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col md={12} style={{ paddingLeft: 8 }}>
            <div
              style={{
                display: error ? "block" : "none",
                padding: "10px",
                marginBottom: 15,
                fontWeight: "bold",
                color: "#CC3333",
                backgroundColor: "#FFEEEE",
              }}
            >
              El usuario ya está registrado
            </div>
            <button
              type="submit"
              className="frmButton"
              // className={!formik.isValid ? "frmButtonDisabled" : "frmButton"}
              // disabled={!formik.isValid}
            >
              Crear usuario
            </button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default CreateAccount;
