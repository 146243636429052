import { combineReducers } from "@reduxjs/toolkit";
import userDataSlice from "../features/userdata/userDataSlice";
import graphDataSlice from "../features/graphdata/graphDataSlice";
import crudUserDataSlice from "../features/cruduserData/crudUserDataSlice";
import userSessionSlice from "../features/user_session/userSessionSlice";
import corpDataSlice from "../features/corp_data/corpDataSlice";
import teamDataSlice from "../features/TeamData/teamDataSlice";
import dashboardSlice from "../features/dashboard/dashboardDataSlice";
import referalDataSlice from "../features/referalData/referalDataSlice";

const mainReducer = combineReducers({
  user_data: userDataSlice,
  graph_data: graphDataSlice,
  crud_data: crudUserDataSlice,
  user_session: userSessionSlice,
  corp_data: corpDataSlice,
  team_data: teamDataSlice,
  dashboard: dashboardSlice,
  referal_data: referalDataSlice,
});

export default mainReducer;
