import { deactivateSession } from "../features/user_session/userSessionSlice";
import { clearAllCookies, createCookie, deleteCookie } from "./cookiesUtils";

// Constants
const SESSION_TOKEN_COOKIE_NAME = "session_token";
const SESSION_USER_COOKIE_NAME = "userSession";
const SESSION_ADMIN_COOKIE_NAME = "adminSession";
const LOCAL_STORAGE_USER_KEY = "user";
const LOCAL_STORAGE_COMPANY_KEY = "company";

// Utility functions for local storage
const getUser = () => {
  const user = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
  return user ? JSON.parse(user) : {};
};

const setUser = (user) => {
  localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
};

const getCompany = () => {
  const company = localStorage.getItem(LOCAL_STORAGE_COMPANY_KEY);
  return company ? JSON.parse(company) : {};
};

const setCompany = (company) => {
  localStorage.setItem(LOCAL_STORAGE_COMPANY_KEY, JSON.stringify(company));
};

export const setNewUser = async (user, access_token, refresh_token, corpId) => {
  try {
    setUser({
      id: user.id,
      name: user.name,
      lastname: user.first_lastname,
      fullName: `${user.name} ${user.first_lastname}`,
      email: user.email,
      token: access_token,
      access_token: access_token,
      refresh_token: refresh_token,
      corp: corpId,
      companyId: user.companyId,
      nickname: user.nickname,
      jobPosition: user.jobPosition,
      businessArea: user.businessArea,
      isStaffDev: user.is_staff_digitizeme,
      team: user.team,
      /* new */
      refreshToken: user.refreshToken,
    });
  } catch (error) {
    throw new Error(`Error setting CRUD user: ${error}`);
  }
};

export const setNewCompany = (company, id) => {
  try {
    setCompany({
      id: company.id,
      name: company.name,
      slug_landing: company.slug_landing,
      location: company.location,
      pyme_plan_name: company.pyme_plan_name,
      logo: company.logo,
      logotype: company.logotype,
      color: company.color,
      institution_type: company.institution_type,
    });
  } catch (error) {
    throw new Error(`Error setting CRUD company: ${error}`);
  }
};

const isLoggedIn = () => {
  const user = getUser();
  return !!user.email;
};

const getCurrentUser = () => getUser();
const getCurrentCompany = () => getCompany();
const setCurrentUser = (user) => setUser(user);

export {
  isLoggedIn,
  getCurrentUser,
  setCurrentUser,
  getCurrentCompany,
  getCompany,
  setCompany,
};
