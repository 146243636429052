import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import StartSlider from "../../Components/StartSlider/StartSlider";

import "../../assets/styles/pages/index.scss";
import { LograLink } from "../../assets/styles/pages/general/GeneralStyles";

const StartPage = () => {
  const history = useHistory();
  const [, setUserEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  const setEmail = (event) => {
    setEmailValid(true);
    setUserEmail(event.target.value);
  };

  const validateEmail = (email) => {
    // const pattern =
    //   /[a-zA-Z0-9]+[\\.]?([a-zA-Z0-9]+)?[\\@][a-z]{3,9}[\\.][a-z]{2,5}/g;
    const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g;
    const result = pattern.test(email);
    return result;
  };
  return (
    <Container fluid className="landing-content">
      <Row className="rowPage" style={{ display: "flex", flexWrap: "wrap" }}>
        <Col xs={12} md={12} lg={6} className="columnIntro">
          <img
            src="/images/logos/logo.svg"
            alt="logra-io-logo"
            className="logoPage"
          />
          <p className="component-min-wrapper custom-h4">Hola 😃</p>
          <p className="component-min-wrapper custom-h1">
            Registra tu comunidad aquí.
          </p>
          <p className="component-min-wrapper product-description">
            ¿Cómo vas a impulsar tu red de empresas? Te lo explicamos en 3 pasos
            👉
          </p>
          <div style={{ width: "100%", padding: 10 }}>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const userEmail = event.target.userEmail.value;
                const result = validateEmail(userEmail);

                if (result) {
                  history.push("/onboarding", { userEmail });
                } else {
                  setEmailValid(false);
                }
              }}
            >
              <Form.Group>
                <label className="labelInput" htmlFor="userEmail">
                  ¿Cuál es tu correo?
                </label>
                <Form.Control
                  className="inputNewBrand main-btn-align"
                  type="email"
                  placeholder="nombre@ejemplo.com"
                  onChange={(e) => {
                    setEmail(e);
                  }}
                  id="userEmail"
                  name="userEmail"
                />
                {!emailValid && (
                  <div style={{ color: "red", padding: 10 }}>
                    El formato de correo electrónico es inválido
                  </div>
                )}
              </Form.Group>

              {
                <Button type="submit" className="btn-style main-btn-align">
                  Empecemos
                </Button>
              }
            </form>
          </div>
          <br />
          <br />
          <p className="component-min-wrapper common-text-2">
            ¿Ya tienes una cuenta?
            <br />
            <LograLink href="/login">Inicia sesión</LograLink>
          </p>
        </Col>
      </Row>
      <div className="startCover">
        <StartSlider />
      </div>
    </Container>
  );
};

export default StartPage;
