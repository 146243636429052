import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";

export const ReferalShare = ({url}) => {
  return (
    <>
      <FacebookShareButton
          url={url}
        >
          <FacebookIcon size={45} round={true} />
        </FacebookShareButton>
        <WhatsappShareButton
          url={url}
        >
          <WhatsappIcon size={45} round={true} />
        </WhatsappShareButton>
        <LinkedinShareButton
          url={url}
        >
          <LinkedinIcon size={45} round={true} />
        </LinkedinShareButton>
        <FacebookMessengerShareButton
          url={url}
        >
          <FacebookMessengerIcon size={45} round={true} />
        </FacebookMessengerShareButton>
    </>
  )
}
