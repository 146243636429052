import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ResponsiveImage from "../../Components/ResponsiveImage/ResponsiveImage";
import CommunityIcon from "../../assets/images/icons/building_blue.svg";
import UserIcon from "../../assets/images/icons/user.svg";
import { Link } from "react-router-dom";
import "../../assets/styles/pages/adminpanel/admin_panel.scss";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";

const CorporationsOptions = () => {
  const RedirectBox = ({ title, description, image, alt, route }) => {
    return (
      <div className="redirect-box-container">
        <div className="redirect-box-image">
          <ResponsiveImage
            src={image}
            width={"60px"}
            height={"60px"}
            alt={alt}
          />
        </div>
        <div className="redirect-box-text">
          <Link to={route}>
            <p className="redirect-box-textTitle">{title}</p>
            <p className="redirect-box-textDescription">{description} </p>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Container style={{ paddingTop: "30px", marginLeft: "35px" }}>
      <MainTitle style={{ marginBottom: 45, marginTop: 15 }}>
        Panel de administración
      </MainTitle>
      <Row>
        <Col className="panelboxes-container">
          <RedirectBox
            title="Entidades"
            description="Crea una entidad"
            alt={"Usuarios"}
            image={CommunityIcon}
            route="/admin/corporations/create"
          />
          <RedirectBox
            title="Entidades"
            description="Asigna una entidad a un usuario"
            alt={"Comunidades"}
            image={UserIcon}
            route="/admin/corporations/assign"
          />
          <RedirectBox
            title="Entidades"
            description="Edita una entidad existente"
            alt={"Comunidades"}
            image={UserIcon}
            route="/admin/corporations/edit"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CorporationsOptions;
