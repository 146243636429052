import { useRef } from "react";

import { ErrorMessage, Field, Formik, Form as FormikForm } from "formik";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import * as Yup from "yup";

import * as AffiliatesApi from "../../../api/api-affiliates.js";
import { SolidButton } from "../../../assets/styles/ui/Buttons.js";
import "../../../assets/styles/pages/licenses.scss";


export function LicenseForm({ accessToken, setAffiliate }) {
  // ----[hooks]----
  const formikRef = useRef(null);

  // ----[constants & instances]----
  const initialValues = {
    document_number: "",
  };

  const yupValidation = Yup.object({
    document_number: Yup.number().required("Este campo es obligatorio"),
  });

  // ----[functions]----

  const handleSubmit = async () => {
    try {
      const response = await AffiliatesApi.searchAffiliate(accessToken, formikRef.current.values.document_number);
      setAffiliate(response.data);
    } catch (error) {
      setAffiliate({});
    } finally {
      formikRef.current.resetForm();
    }
  };


  // ----[render]----
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={yupValidation}
        validateOnBlur={false}
      >
        {formik => (
          <FormikForm className="form-max-width">
            <Row className="pl-2">
              <Col xs={12} sm={8} >
                <label htmlFor="document_number">Número de documento</label>
                <Field
                  id="document_number"
                  type="number"
                  name="document_number"
                  placeholder="Escribe el número de documento"
                  className="form-input"
                />
                <ErrorMessage
                  name="document_number"
                  className="error-msg"
                  component="p"
                />
              </Col>
              <Col xs={12} sm={8} className="pt-4 mt-2">
                <SolidButton
                  block
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Procesando..." : "Buscar empresa"}
                </SolidButton>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </>
  );
}

LicenseForm.propTypes = {
  accessToken: PropTypes.string.isRequired,
  setAffiliate: PropTypes.func.isRequired,
};