import styled from "styled-components";
import { H1 } from "../../assets/styles/styled/TextStyles";

export const Title = styled(H1)`
  padding: 20px 0;
  margin-left: 0px;
  font-size: 1.6rem;
  line-height: 135%;
  //   span {
  //     color: white;
  //   }
`;

const ForgotTitle = styled.h1`
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #999999;
`;

const ForgotSubtitle = styled.h2`
  font-family: "Cy", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  display: flex;
  align-items: baseline;
`;

const ForgotParagraph = styled.p`
  margin-bottom: 1rem;
`;

const LinksContainer = styled.div`
  margin-top: 25px;
`;

const LograLink = styled.span`
  display: inline-block;
  cursor: pointer;
  color: #2c5999;
  text-decoration: underline;
  font-family: "Cy", sans-serif;
  margin-top: 10px;
  font-weight: 700;
  line-height: 28px;
  cursor: pointer;
  :hover {
    text-decoration: none;
    color: #2c5999;
  }
`;

export {
  ForgotTitle,
  ForgotSubtitle,
  ForgotParagraph,
  LinksContainer,
  LograLink,
};
