import { useHistory, useLocation, useParams } from "react-router-dom";

function NavigateTo(url) {
  const history = useHistory();
  history.push(url);
}

function GoBackTo() {
  const history = useHistory();
  history.goBack();
}

function useQueryParams() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

function useRouteParams() {
  return useParams();
}

function NavigateToButton({ url, description }) {
  const history = useHistory();

  function handleClick() {
    history.push(url);
  }
  const ButtonStyles = {
    display: "inline-block",
    background: "none",
    border: "none",
    padding: "0",
    textDecoration: "underline",
    cursor: "pointer",
    color: "#2c5999",
    fontFamily: "Cy, sans-serif",
    fontWeight: "700",
    width: "auto",
    boxSizing: "border-box",
  };

  return (
    <button style={ButtonStyles} onClick={handleClick}>
      {description}
    </button>
  );
}

export {
  NavigateTo,
  GoBackTo,
  useQueryParams,
  useRouteParams,
  NavigateToButton,
};
