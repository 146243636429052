import Cookies from "js-cookie";

export function createCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  const path = "; path=/";
  const cookieString = name + "=" + encodeURIComponent(value) + expires + path;
  document.cookie = cookieString;
}

export function getCookie(name) {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
    }
  }
  return null;
}

export function getCookieExpiration(name) {
  const value = `; ${document.cookie}`;
  console.log("valor", value);
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const expires = parts.pop().split(";").shift();
    return new Date(expires);
  }
}
export function deleteCookie(name) {
  document.cookie = name + "=; Max-Age=-99999999; path=/";
}

export function clearAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function getAllCookies() {
  const cookies = document.cookie.split(";");
  const result = {};
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim().split("=");
    const name = cookie[0];
    const value = decodeURIComponent(cookie[1]);
    result[name] = value;
  }
  return result;
}
