import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../utils/authUtils";
import { getCookie } from "../../utils/cookiesUtils";
import { notifyBad, notifyCool } from "../../utils/toastNotifications";
import { ListMembers } from "../../Components/FormComponents/ListMembers";
import { Loading } from "@logra/component-library";
import { Form as FormFormik, Formik, ErrorMessage, Field } from "formik";
import api from "../../utils/axiosUsage";
import * as Yup from "yup";
import { Col, Container, Row } from "react-bootstrap";
import {
  InvitationFormik,
  InvitationInputContainer,
  SubmitFormikButton,
  TeamsContainer,
  TitleH1,
  TitleH2,
} from "./TeamStyles";

const validateBtn = (value, error) => {
  if (value !== "" && !error) {
    return false;
  } else {
    return true;
  }
};

const initialValues = { email: "" };
const validationSchema = Yup.object({
  email: Yup.string()
    .email("No es un formato de correo válido")
    .required("Es requerido"),
});

const TeamMembers = () => {
  const [fetchedTeam, setFetchedTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = getCurrentUser().token || getCookie("session_token");

  useEffect(() => {
    getTeamPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamPeople = async () => {
    const response = await api.axiosAuthGet(`/api/v1/get_corp_owners/`, token);
    if (response.status === 200) {
      const getMembers = response.data[0].map((member) => ({
        email: member[1],
        profile_photo: null,
        job_position: "",
      }));
      setFetchedTeam(getMembers);
    } else {
      setFetchedTeam([]);
    }
    setIsLoading(false);
  };

  const user_email =
    useSelector((state) => state.crud_data.email) || getCurrentUser().email;

  const handleSendInvite = async (email) => {
    if (user_email === email) {
      setError(true);
      // setErrorMessage("No puedes invitarte a ti mismo a una comunidad");
      notifyBad("No puedes invitarte a ti mismo a una comunidad");
      return false;
    }
    const form = {
      addressee: email,
      type: "Corporation",
    };

    try {
      const response = await api.axiosAuthPost(
        "/api/v1/invitations/",
        token,
        form
      );
      if (response.status === 201 || response.status === 200) {
        notifyCool(`Invitación enviada satisfactoriamente a ${email}`);
      } else if (response.response.status === 403) {
        notifyBad("No se pudo invitar al usuario porque ya está registrado");
      } else {
        notifyBad("Algo falló en el envio de invitación");
      }
      setError(false);
    } catch (error) {
      notifyBad("Hubo un error");
    }
  };

  const errorMessageStyles = {
    display: error ? "block" : "none",
    marginLeft: "15px",
    marginRight: "15px",
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="profilePage-membersHeader">
            <TitleH1>Miembros de tu equipo</TitleH1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TitleH2>Miembros de tu equipo</TitleH2>
          <TeamsContainer>
            {isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Loading />
              </div>
            ) : (
              <>
                {fetchedTeam &&
                  fetchedTeam.length > 0 &&
                  (fetchedTeam.length <= 1 ? (
                    <p className="profilePage-noMembersMsg">
                      Aun no agregas miembros a tu equipo para que puedan
                      participar contigo en la ejecucion de las rutas de
                      crecimiento👇
                    </p>
                  ) : (
                    fetchedTeam.map((member, i) => (
                      <ListMembers key={i} member={member} />
                    ))
                  ))}
              </>
            )}
          </TeamsContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formik) => {
              handleSendInvite(values.email);
              formik.resetForm();
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <FormFormik>
                <InvitationFormik>
                  <p>Correo</p>
                  <InvitationInputContainer>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Escribe tu correo"
                    />

                    <div style={errorMessageStyles}>{errorMessage}</div>
                    <SubmitFormikButton
                      type="submit"
                      disabled={validateBtn(
                        formik.values.email,
                        formik.errors.email
                      )}
                    >
                      Invitar
                    </SubmitFormikButton>
                  </InvitationInputContainer>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="profilePage-formError"
                  />
                </InvitationFormik>
              </FormFormik>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamMembers;
