import { v4 as uuidv4 } from "uuid";

export const createSlugName = (name) => {
  if (!name) return false;
  let slug = name.toLowerCase();
  slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  slug = slug.replace(/\s+/g, "-");
  return slug;
};

export const generateUniqueId = () => {
  return uuidv4();
};

export function delayedExecution(func, delayTime) {
  setTimeout(func, delayTime);
}
