import React from "react";
import { Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getSizeTeamName } from "../../utils/companies";

export const CompaniesList = ({ list, searching }) => {
  const history = useHistory();
  return (
    <Col className="companies-containerTable">
      <h3 className="companies-listTitle">Empresas con perfil configurado</h3>
      {list.length === 0 ? (
        <p className="companies-link" onClick={() => history.push("/referal")}>
          Comienza a invitar empresas a tu red
        </p>
      ) : (
        <>
          <Table striped borderless hover>
            <thead>
              <tr>
                <td>Empresa</td>
                <td>Equipo</td>
                <td>Oficina</td>
                <td>Sector</td>
                <td>Oferta</td>
                <td>Clientes</td>
              </tr>
            </thead>
            <tbody>
              {/* Pymesz total */}
              {/* EMPRESAS CON PERFIL CONFIGURADO */}
              {searching.isSearching
                ? searching.results.map((smbs, index) => (
                    <tr key={index}>
                      <td>{smbs.smb_name}</td>
                      <td>{getSizeTeamName(smbs.company_size)}</td>
                      <td>{smbs.wfh}</td>
                      <td>{smbs.industry_sector}</td>
                      <td>{smbs.service_type}</td>
                      <td>{smbs.client_profile}</td>
                    </tr>
                  ))
                : list.map((smbs, index) => (
                    <tr key={index}>
                      <td>{smbs.smb_name}</td>
                      <td>{getSizeTeamName(smbs.company_size)}</td>
                      <td>{smbs.wfh}</td>
                      <td>{smbs.industry_sector}</td>
                      <td>{smbs.service_type}</td>
                      <td>{smbs.client_profile}</td>
                    </tr>
                  ))}
            </tbody>
          </Table>
          {searching.isSearching && searching.results.length === 0 && (
            <p className="companies-notSearch">
              ¡No se encontraron resultados 😕!
            </p>
          )}
        </>
      )}
    </Col>
  );
};
