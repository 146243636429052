import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
// import { ListMembers } from "../ListMembers";
import { ListMembers } from "../FormComponents/ListMembers";
import { getCurrentCompany, getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";
import { notifyBad, notifyCool } from "../../utils/toastNotifications";
import "../../assets/styles/pages/configuration/team-invitation.scss";
import { useSelector } from "react-redux";
import { getCookie } from "../../utils/cookiesUtils";
import TeamData from "./TeamData";

const FrmMembers = ({ team }) => {
  const [teamData, setTeamData] = useState(team ? team : []);
  const company = useSelector((state) => state.corp_data.data);
  const token = getCurrentUser().token || getCookie("session_token");

  const validateBtn = (value, error) => {
    if (value !== "" && !error) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const companyId = company?.id || getCurrentCompany().id;
        const { data: teamData } = await api.axiosAuthGet(
          `/api/v1/view-team/${companyId}/`,
          token
        );
        setTeamData(teamData.members);
      } catch (error) {
        console.log(error);
      } finally {
        setTeamData([]);
      }
    }
    fetchData();
  }, [company, token]);

  const onSendInvite = async (email) => {
    const user = getCurrentUser();
    const form = {
      addressee: email,
      type: "Corporation",
    };
    try {
      const { data } = await api.axiosAuthPost(
        "/api/v1/invitations/",
        user.token,
        form
      );

      if (data === undefined) {
        notifyBad("Hubo un error");
        return;
      }
      notifyCool("Se envió la invitación");
    } catch (error) {
      console.log(error);
      notifyBad("Hubo un error");
    }
  };

  return (
    <div className="stepBoxForm invitationForm-Container">
      <div className="profilePage-membersHeader">
        <h1 className="configuration-titleContainer">Miembros de tu equipo</h1>
      </div>
      <h2 className="configuration-subtitleContainer">Miembros de tu equipo</h2>
      <div className="profilePage-membersContainer">
        {TeamData.length <= 1 ? (
          <p className="profilePage-noMembersMsg">
            Aun no agregas miembros a tu equipo para que puedan participar
            contigo en la ejecucion de las rutas de crecimiento👇
          </p>
        ) : (
          teamData.map((member, i) => <ListMembers key={i} member={member} />)
        )}
      </div>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={(values, formik) => {
          onSendInvite(values.email);
          formik.resetForm();
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("No es un formato de correo válido")
            .required("Es requerido"),
        })}
      >
        {(formik) => (
          <FormFormik className="profilePage-formContainer">
            <p>Correo</p>
            <div className="profilePage-inputContainer">
              <Field
                name="email"
                type="email"
                placeholder="Escribe tu correo"
              />
              <button
                type="submit"
                disabled={validateBtn(formik.values.email, formik.errors.email)}
              >
                Invitar
              </button>
            </div>
            <ErrorMessage
              name="email"
              component="div"
              className="profilePage-formError"
            />
          </FormFormik>
        )}
      </Formik>
    </div>
  );
};

export default FrmMembers;
