/**
 * Componente Referal
 *
 * Este componente muestra una sección que incluye recursos para lanzar un programa y una sección que muestra una liga personalizada
 * para invitar a la red de empresas.
 *
 * Props:
 * - Ninguna.
 *
 * Estados:
 * - Ninguno.
 *
 * Ejemplo de uso:
 *
 * <Referal />
 */

import React, { useEffect, useState } from "react";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Loading } from "@logra/component-library";

import FileCopy from "../../assets/images/icons/FileCopy.svg";
import file_icon from "../../assets/images/icons/file_icon.svg";

import { getCompany, getCurrentUser } from "../../utils/authUtils";
import "../../assets/styles/pages/referal.scss";
import { ReferalShare } from "../../Components/referal/ReferalShare";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";
import { useSelector } from "react-redux";
import { getCookie } from "../../utils/cookiesUtils";
import { fetchCorpData } from "../../features/corp_data/corpData";

const Referal = () => {
  // Selector para obtener el estado de los datos de la compañía y obtener el token de sesión del usuario actual
  const dispatch = useDispatch();
  const company_data =
    useSelector((state) => state.corp_data.data) || getCompany();
  const token = getCookie("session_token") || getCurrentUser().token;
  const [isLoading, setIsLoading] = useState(false);
  // Obtener el nombre de dominio de la compañía y crear un slug del nombre de la compañía
  const domain_name = company_data.program_domain_name;
  const sluged_name = company_data.slug_landing;

  useEffect(() => {
    setIsLoading(true);

    dispatch(fetchCorpData()).then(() => {
      setIsLoading(false);
    });

    return () => {};
  }, [dispatch]);

  // Función para copiar el enlace en el portapapeles y mostrar un mensaje de éxito o error
  const copySlugToClipboard = () => {
    navigator.clipboard
      .writeText(
        domain_name === null
          ? `${process.env.REACT_APP_DOMAIN_PYMES_APP}/${sluged_name}`
          : verifyTypeofLink(company_data.program_domain_name)
      )
      .then(() => {
        toast.success("Liga copiada");
      })
      .catch((error) => {
        console.error("Error copiando liga: ", error);
      });
  };

  // Función para renderizar un tooltip que indica que se puede copiar el enlace
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Copiar
      </Tooltip>
    );
  };

  // Función para verificar el tipo de enlace (si es http, https o un dominio personalizado) y devolver la url correcta
  const verifyTypeofLink = (existingDomain) => {
    if (typeof existingDomain === "string" && existingDomain.length > 0) {
      if (
        existingDomain.startsWith("http://") ||
        existingDomain.startsWith("https://")
      ) {
        return existingDomain;
      } else {
        return `https://${existingDomain}`;
      }
    } else {
      return process.env.REACT_APP_DOMAIN_PYMES_APP;
    }
  };

  // Componente para mostrar un enlace con un ícono de descarga y un nombre de archivo
  function FileLink({ iconSrc, fileSrc, fileName }) {
    return (
      <div>
        <img
          style={{ width: "40px", height: "40px", marginRight: "1rem" }}
          src={iconSrc}
          alt="file-download"
        />
        <a href={fileSrc} target="_blank" rel="noreferrer">
          {fileName}
        </a>
      </div>
    );
  }

  const loadingStyles = {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  if (isLoading) {
    return (
      <div className="home-containerLoading" style={loadingStyles}>
        <Loading />
      </div>
    );
  }

  return (
    <div
      className="referal-container"
      style={{ padding: "10px", paddingTop: "20px" }}
    >
      <MainTitle style={{ marginBottom: 50, marginTop: 20 }}>
        Lanza y crece tu programa exitosamente ✅
      </MainTitle>
      <section className="referal-section">
        <h3>¿Listo para invitar a tu red de empresas?</h3>
        <p>
          Utiliza esta liga única que puedes personalizar a tu imagen para
          invitar a tu red.
        </p>

        <div className="landingLink">
          {domain_name === null
            ? `${process.env.REACT_APP_DOMAIN_PYMES_APP}/${sluged_name}`
            : verifyTypeofLink(company_data.program_domain_name)}
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <img
              src={FileCopy}
              alt="FileCopy"
              className="fileCopyImg"
              onClick={() => copySlugToClipboard()}
            />
          </OverlayTrigger>
        </div>
        <p>Compartir en redes sociales:</p>
        <ReferalShare
          url={verifyTypeofLink(company_data.program_domain_name)}
        />
      </section>
      <section className="referal-section">
        <h3>Recursos para lanzar tu programa</h3>
        <div className="referal-resourcesAndImg">
          <FileLink
            iconSrc={file_icon}
            fileSrc="https://logra-assets.s3.amazonaws.com/pdf/Gu%C3%ADa+de+Comunicaci%C3%B3n+-+Alianzas+Institucionales.pdf"
            fileName="Manual de comunicación pdf"
          />
        </div>
      </section>
    </div>
  );
};

export default Referal;
