export const themes = {
  light: {
    text1: "black",
    text2: "rgba(0,0,0,0.7)",
    primary: "#2C5999",
    backgroundColor: "#fdfdfd",
  },
  dark: {
    text1: "white",
    text2: "rgba(255,255,255,0.7)",
    primary: "#D9DBE9",
    backgroundColor: "rgba(0,0,0,0.75)",
  },
};
