import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCookie } from "../../utils/cookiesUtils";
import api from "../../utils/axiosUsage";
import { getCurrentCompany, getCurrentUser } from "../../utils/authUtils";

export const fetchDashboardType = createAsyncThunk(
  "dashboard/fetchDashboardType",
  async () => {
    const currentCompany = getCurrentCompany();
    const currentUser = getCurrentUser();
    const token = getCookie("session_token");
    const corp_id = currentCompany.id || currentUser.corp;
    const response = await api.axiosAuthGet(
      `/api/v1/corporation/${corp_id}/`,
      token
    );
    let dashboard_url = null;
    if (response.data.analytics_version === "QUICKSIGHT") {
      const dashboard_url_response = await api.axiosAuthGet(
        "/api/analytics/corporation-kpis",
        token
      );
      dashboard_url = dashboard_url_response.data;
    }
    const dashboardType = {
      dashboard_type: response.data.analytics_version,
      dashboard_url: dashboard_url,
    };

    return dashboardType;
  }
);

export default fetchDashboardType;
