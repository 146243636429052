import { createSlice } from "@reduxjs/toolkit";

const userSessionSlice = createSlice({
  name: "user_session",
  initialState: {
    is_active: false,
    is_admin: false,
    is_premium: false, // add new field for premium/free session
  },
  reducers: {
    activateSession: (state) => {
      state.is_active = true;
    },
    deactivateSession: (state) => {
      state.is_active = false;
      state.is_admin = false;
      state.is_premium = false; // reset premium/free session when deactivating
    },
    setAdmin: (state, action) => {
      state.is_admin = action.payload;
    },
    unsetAdmin: (state) => {
      state.is_admin = false;
    },
    setPremium: (state) => {
      state.is_premium = true; // update is_premium to true when user has premium session
    },
  },
});

export const {
  activateSession,
  deactivateSession,
  setAdmin,
  unsetAdmin,
  setPremium,
} = userSessionSlice.actions;

export default userSessionSlice.reducer;
