import React from "react";

const ResponsiveImage = ({ src, alt, width, height }) => (
  <div
    className="responsive-image-container"
    style={{
      paddingBottom: `${(height / width) * 100}%`,
    }}
  >
    <img
      className="responsive-imageFile"
      src={src}
      alt={alt}
      width={width}
      height={height}
    />
  </div>
);

export default ResponsiveImage;
