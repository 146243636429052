export const fieldsFilter = {
  areaCompany: [
    "Administración y finanzas",
    "Dirección y estrategia",
    "Innovación",
    "Operación, logística y producción",
    "Recursos humanos, equipo y cultura",
    "Servicio al cliente",
    "Tecnología",
  ],
  teamSize: [
    {
      name: "Estoy solo",
      value: "1",
    },
    {
      name: "1 a 5 colaboradores",
      value: "1-5",
    },
    {
      name: "6 a 25 colaboradores",
      value: "6-25",
    },
    {
      name: "26 a 50 colaboradores",
      value: "26-50",
    },
    {
      name: "51 a 100 colaboradores",
      value: "51-100",
    },
    {
      name: "101 a 250 colaboradores",
      value: "101-250",
    },
    {
      name: "Más de 250 colaboradores",
      value: ">250",
    },
  ],
  sector: [
    "Actividades administrativas y servicios auxiliares",
    "Actividades artísticas, recreativas y de entretenimiento",
    "Actividades de los hogares como empleadores de personal doméstico",
    "Actividades financieras y de seguros",
    "Actividades inmobiliarias",
    "Actividades profesionales, científicas y técnicas",
    "Actividades sanitarias y de servicios sociales",
    "Administración pública y defensa",
    "Agricultura: explotación y cultivo de especies vegetales",
    "Comercio al por mayor y al por menor",
    "Construcción",
    "Consultoría",
    "Cultura",
    "Directivos de grande empresas",
    "Directivos de ONG",
    "Educación",
    "Explotación de recursos forestales",
    "Ganadería: dedicada a la cría de animales",
    "Hosteleria",
    "Industria extractiva",
    "Industria manufacturera",
    "Información y comunicaciones",
    "Investigación científica",
    "Legisladores",
    "Líderes científicos",
    "Líderes tecnológicos",
    "Minería. Explotación de los minerales",
    "Oficiales del gobierno",
    "Otros servicios",
    "Pesca. captura peces, moluscos, crustáceos y otros animales que se encuentran en el mar",
    "Suministro de agua, actividades de saneamiento, gestión de residuos y descontaminación",
    "Suministro de energía eléctrica, gas, vapor y aire acondicionado",
    "Tecnología de la información",
    "Transporte y almacenamiento",
  ],
  workPlace: [
    "Desde Casa",
    "Oficinas de un cliente",
    "Una sola oficina",
    "Varias Oficinas",
  ],
  typeOffer: ["Bienes", "Servicios", "Ambos"],
  customers: [
    {
      name: "Empresas (B2B)",
      value: "B2B",
    },
    {
      name: "Instituciones y gobierno (B2B)",
      value: "B2B",
    },
    {
      name: "Clientes finales (B2C)",
      value: "B2C",
    },
    {
      name: "Otros (B2C)",
      value: "B2C",
    },
  ],
};
