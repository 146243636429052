import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../../assets/styles/pages/onboarding.scss";
import Check from "../../../assets/images/onboarding/Check.png";
import { HeaderSubtextCombo } from "@logra/component-library";

const SetCorporationEditForm = (props) => {
  const { handler, institutionName } = props;
  const [employees, setEmployees] = useState(false);
  const [sectors, setSectors] = useState(false);
  const [sales, setSales] = useState(false);
  const [segmentedOther, setSegmentedOther] = useState(false);
  const [segmentedNone, setSegmentedNone] = useState(false);

  const [email, setEmail] = useState(false);
  const [sms, setSms] = useState(false);
  const [socialMedia, setSocialMedia] = useState(false);
  const [events, setEvents] = useState(false);
  const [webinars, setWebinars] = useState(false);
  const [comunnicationOther, setComunnicationOther] = useState(false);

  const [showErrorsSegmented, setShowErrorsSegmented] = useState(false);
  const [showErrorsCommunication, setShowErrorsCommunication] = useState(false);
  const [isDisabled] = useState(false);

  const handleSubmit = (form) => {
    const editedData = {
      smbs_number_in_network: form.numberInNetwork,
      institution_type: form.typeInstitution,
      program_domain_name: form.program_domain_name,
      segmentation_type_empleados: employees,
      segmentation_type_sectores: sectors,
      segmentation_type_ventas: sales,
      segmentation_type_otros: segmentedOther,
      segmentation_type_ninguno: segmentedNone,
      communication_type_email: email,
      communication_type_sms_whatsapp: sms,
      communication_type_rr_ss_grupos: socialMedia,
      communication_type_eventos: webinars,
      communication_type_otro: comunnicationOther,
    };
    handler(editedData);
  };

  const onSegmentedNone = () => {
    setEmployees(false);
    setSectors(false);
    setSales(false);
    setSegmentedOther(false);
    setSegmentedNone(!segmentedNone);
  };

  const validateSegmented = () => {
    if (!employees && !sectors && !sales && !segmentedOther && !segmentedNone) {
      setShowErrorsSegmented(true);
      return false;
    } else {
      setShowErrorsSegmented(false);
      return true;
    }
  };
  const validateCommunication = () => {
    if (
      !email &&
      !sms &&
      !socialMedia &&
      !events &&
      !webinars &&
      !comunnicationOther
    ) {
      setShowErrorsCommunication(true);
      return false;
    } else {
      setShowErrorsCommunication(false);
      return true;
    }
  };

  return (
    <Formik
      initialValues={{
        typeInstitution: "",
        numberInNetwork: "",
        program_domain_name: "",
      }}
      onSubmit={(values) => {
        if (!validateSegmented() || !validateCommunication()) {
          return;
        }
        // updateCompany(values);
        handleSubmit(values);
      }}
      validationSchema={Yup.object({
        typeInstitution: Yup.string().required("Este campo es obligatorio"),
        numberInNetwork: Yup.number()
          .required("Este campo es obligatorio")
          .integer("No es un número válido")
          .positive("No es un número válido"),
        program_domain_name: Yup.string(),
      })}
    >
      {() => (
        <>
          <h2 className="onSubTitle">
            {institutionName ? institutionName : "Corporacion"} es una entidad
            nueva por aquí 👋
          </h2>
          <h1 className="onTitle">Añade mas informacion de la corporacion</h1>
          <div className="stepBoxForm">
            <FormFormik>
              <Container fluid className="stepBoxContainer">
                <Row>
                  <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <label>Tipo de entidad</label>
                    <Field
                      name="typeInstitution"
                      as={"select"}
                      className="inputNewBrand main-btn-align form-control"
                    >
                      <option value="">Selecciona</option>
                      <option value="Cámara de comercio">
                        Cámara de comercio
                      </option>
                      <option value="Secretaría de Gobierno">
                        Secretaría de Gobierno
                      </option>
                      <option value="Caja de compensación">
                        Caja de compensación
                      </option>
                      <option value="Banco de desarrollo">
                        Banco de desarrollo
                      </option>
                      <option value="Banco empresarial">
                        Banco empresarial
                      </option>
                      <option value="SaaS">SaaS</option>
                      <option value="Fintech">Fintech</option>
                      <option value="Comunidad o programa">
                        Comunidad o programa
                      </option>
                      <option value="Otro">Otro</option>
                    </Field>
                    <ErrorMessage
                      name="typeInstitution"
                      component="div"
                      className="frmError"
                      style={{ marginTop: "0px" }}
                    />
                  </Col>
                  <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>Número de PyMes de su red</label>

                      <Field
                        name="numberInNetwork"
                        id="numberInNetwork"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="Número de empresas en tu red"
                        type="number"
                      />
                      <ErrorMessage
                        name="numberInNetwork"
                        component="div"
                        className="frmError"
                        style={{ marginTop: "0px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={8}
                    style={{
                      paddingLeft: 8,
                      paddingRight: 0,
                      marginTop: "0.5rem",
                      marginBottom: "1.1rem",
                    }}
                  >
                    <Form.Group>
                      <label>Nombre del dominio del programa</label>
                      <HeaderSubtextCombo
                        title={"Nombre de dominio personalizado"}
                        subtext={
                          "El campo de nombre de dominio es la dirección web única que identifica el programa en Internet y se utiliza para acceder a él a través de la web."
                        }
                        titleSize={1.1}
                        subtextSize={0.9}
                        buttonSize={1.6}
                        titleColor={"gray"}
                        subtextColor={"black"}
                        paddingBottom={0.6}
                        paddingTop={0.6}
                      />
                      <Field
                        name="program_domain_name"
                        id="program_domain_name"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="Nombre del dominio"
                        type="text"
                      />
                      <ErrorMessage
                        name="program_domain_name"
                        component="div"
                        className="frmError"
                        style={{ marginTop: "0px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>¿Cómo las tienes segmentadas?</label>
                      <div className="container-checks">
                        <div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setEmployees((prev) => !prev)}
                              className={
                                employees
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {employees && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Número de empleados
                            </p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSectors((prev) => !prev)}
                              className={
                                sectors
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {sectors && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Tipo de sectores
                            </p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSales((prev) => !prev)}
                              className={
                                sales
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {sales && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Número de ventas
                            </p>
                          </div>
                        </div>
                        <div className="container-checkSecond">
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSegmentedOther((prev) => !prev)}
                              className={
                                segmentedOther
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {segmentedOther && (
                                <img src={Check} alt="check" />
                              )}
                            </div>
                            <p className="onboarding-checkTxt">Otros</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={onSegmentedNone}
                              className={
                                segmentedNone
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {segmentedNone && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Ninguno</p>
                          </div>
                        </div>
                      </div>
                      {showErrorsSegmented && (
                        <div className="frmError" style={{ marginTop: "0px" }}>
                          Este campo es obligatorio
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>¿Cómo comunican con su red?</label>
                      <div className="container-checks">
                        <div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setEmail((prev) => !prev)}
                              className={
                                email
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {email && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Mail</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSms((prev) => !prev)}
                              className={
                                sms
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {sms && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              SMS o WhatsApp
                            </p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setSocialMedia((prev) => !prev)}
                              className={
                                socialMedia
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {socialMedia && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">
                              Redes sociales y grupos
                            </p>
                          </div>
                        </div>
                        <div className="container-checkSecond">
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setEvents((prev) => !prev)}
                              className={
                                events
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {events && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Eventos</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() => setWebinars((prev) => !prev)}
                              className={
                                webinars
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {webinars && <img src={Check} alt="check" />}
                            </div>
                            <p className="onboarding-checkTxt">Webinars</p>
                          </div>
                          <div className="onboarding-checkbox">
                            <div
                              onClick={() =>
                                setComunnicationOther((prev) => !prev)
                              }
                              className={
                                comunnicationOther
                                  ? "onboarding-containerCheckbox"
                                  : "onboarding-containerCheckboxInactive"
                              }
                            >
                              {comunnicationOther && (
                                <img src={Check} alt="check" />
                              )}
                            </div>
                            <p className="onboarding-checkTxt">Otro</p>
                          </div>
                        </div>
                      </div>
                      {showErrorsCommunication && (
                        <div className="frmError" style={{ marginTop: "0px" }}>
                          Este campo es obligatorio
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                  <Col md={12} style={{ padding: "30px 0px", paddingLeft: 8 }}>
                    <button
                      type="submit"
                      className={isDisabled ? "frmButtonDisabled" : "frmButton"}
                      disabled={isDisabled}
                    >
                      Continuar
                    </button>
                  </Col>
                </Row>
              </Container>
            </FormFormik>
          </div>
        </>
      )}
    </Formik>
  );
};

export default SetCorporationEditForm;
