import styled from "styled-components";

const SidebarContainer = styled.div`
  width: ${({ isSidebarOpen }) => (isSidebarOpen ? "240px" : "88px")};
  height: 100%;
  background: #fafbfb;
  position: fixed;
  top: 0;
  left: ${({ isSidebarOpen }) => (isSidebarOpen ? "0" : "0")};
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #ebedf0;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ isSidebarOpen }) => (isSidebarOpen ? "80px" : "50px")};
  transition: height 0.3s ease-in-out;
`;

const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  align-items: flex-start;
`;

const MenuLogoItem = styled.li`
  padding: 10px 20px;
  color: black;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
  height: 60px;

  &:hover {
    border-left: ${({ colorSelected }) =>
      colorSelected ? `3px solid ${colorSelected}` : "none"};
  }
`;

const MenuLogoContainer = styled.div`
  width: 100%;
  height: 50px;
  margin: 20px 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 60%;
  border: 2px solid ${({ colorSelected }) => colorSelected};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &.expanded {
    width: 45px;
    height: 45px;
    padding: 2px;
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;

  &.expanded {
    width: 95%;
    height: 95%;
  }
`;

const MenuItem = styled.li`
  color: black;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  padding-left: 32px;
  padding-right: 32px;
  &:hover {
    border-left: 3px solid ${({ colorSelected }) => colorSelected};
  }

  &.selectedOption {
    // background-color: pink;
    font-weight: 700;
  }

  img {
    height: 24px;
    fill: ${({ colorSelected }) => colorSelected};
    object-fit: contain;
  }

  span {
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    visibility: ${({ isSidebarOpen }) =>
      isSidebarOpen ? "visible" : "hidden"};
    overflow: hidden;
    text-overflow: ellipsis;
    transition: visibility 0.3s ease-in-out;
    display: flex;
    align-items: center;
    max-height: calc(50px * 0.8);
    margin: 0 16px;
  }
`;

const ExpandButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

/**
 * Dropdown components
 *
 */

const DropdownMenu = styled.ul`
  position: absolute;
  left: ${({ isSidebarOpen }) => (isSidebarOpen ? "240px" : "88px")};
  bottom: calc(16vh - 20px);
  // bottom: calc(20vh - 5px);
  width: 250px;
  right: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  list-style: none;
  display: block;
  flex-wrap: wrap;
  z-index: 9999;
  font-size: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, left 0.3s ease-in-out;

  li:not(:last-child) {
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
  }
`;

const DropdownMenuItem = styled.li`
  white-space: normal;
  word-wrap: break-word;
  margin: 0;
  line-heigth: 1;
  padding: 0.8rem;
  font-size: 16px;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

const Credits = styled.span`
  font-size: 12px;
  font-weight: 700;
  padding: 10px;
  height: 60px;
  width: 100%;
  display: flex;
  visibility: ${({ isSidebarOpen }) => (isSidebarOpen ? "visible" : "hidden")};
  transition: visibility 0.1s ease-in-out;
  color: ${({ colorSelected }) => colorSelected};
  justify-content: center;
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  height: 100%;
  width: 25%vw;
  img {
    width: 18px;
    height: 18px;
    fill: ${({ colorSelected }) => colorSelected};
    object-fit: contain;
  }
`;

export {
  SidebarContainer,
  SidebarHeader,
  SidebarBody,
  SidebarFooter,
  MenuList,
  MenuLogoItem,
  MenuLogoContainer,
  LogoContainer,
  LogoImage,
  MenuItem,
  IconContainer,
  ExpandButton,
  DropdownMenu,
  DropdownMenuItem,
  Credits,
};
