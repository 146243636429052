import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentCompany, getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";
import { getCookie } from "../../utils/cookiesUtils";

export const fetchCorpData = createAsyncThunk(
  "user/fetchCorpData",
  async () => {
    const currentCompany = await getCurrentCompany();
    const currentUser = getCurrentUser();
    const corp_id = currentCompany.id || currentUser.corp;
    const token = getCookie("session_token");
    const response = await api.axiosAuthGet(
      `/api/v1/corporation/${corp_id}/`,
      token
    );
    return {
      corp_data: response.data,
      timeStamp: Date.now(),
    };
  }
);
