import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
import { CompactPicker } from "react-color";
import { Container, Row, Col, Form } from "react-bootstrap";
import logoBgAdd from "../../../assets/images/onboarding/logo-bg-add.png";
import MoreInfo from "../../../assets/images/icons/moreInfo.png";
import { ModalHelpLogos } from "../../../Components/modals/ModalHelpLogos";

const SetCorporationCreateForm = (props) => {
  const { handler } = props;
  const [imgPreview, setImgPreview] = useState(null);
  const [imgPreviewLogotipo, setImgPreviewLogotipo] = useState(null);
  const [pictureData, setPictureData] = useState(null);
  const [pictureDataLogotipo, setPictureDataLogotipo] = useState(null);
  const [colorCompany, setColorCompany] = useState("#fff");
  const [showErrorLogo, setShowErrorLogo] = useState(false);
  const [showErrorLogotipo, setShowErrorLogotipo] = useState(false);
  const [isDisabled] = useState(false);

  const [showIsotipoModal, setShowIsotipoModal] = useState({
    current: "",
    active: false,
  });

  const createSlugName = (name) => {
    let slug = name.toLowerCase();
    slug = slug.replaceAll(" ", "");
    slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return slug;
  };

  const validateLogo = () => {
    if (pictureData === null) {
      setShowErrorLogo(true);
      return false;
    } else {
      setShowErrorLogo(false);
      return true;
    }
  };

  const validateLogotipo = () => {
    if (pictureDataLogotipo === null) {
      setShowErrorLogotipo(true);
      return false;
    } else {
      setShowErrorLogotipo(false);
      return true;
    }
  };

  const convertDataIntoForm = (CrudData) => {
    const form_data = new FormData();
    for (let data in CrudData) {
      form_data.append(data, CrudData[data]);
    }
    return form_data;
  };

  const createImage = (file, type) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      const preview = e.target.result;
      let image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (type === "logo") {
          setImgPreview(preview);
        } else {
          setImgPreviewLogotipo(preview);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const onFileChange = (e, type) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    const picture = files[0];
    if (type === "logo") {
      setPictureData(picture);
      createImage(files[0], "logo");
    } else {
      setPictureDataLogotipo(picture);
      createImage(files[0], "logotipo");
    }
  };

  const initialFormikValue = {
    institutionName: "",
    institutionLocation: "",
    pymesProgram: "",
  };

  const yupValidation = Yup.object({
    institutionName: Yup.string()
      .required("Este campo es obligatorio")
      .matches(
        /^[a-zA-z0-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-z0-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-z0-ZÀ-ÿ\u00f1\u00d1]+$/g,
        "Formato no válido"
      ),
    institutionLocation: Yup.string().required("Este campo es obligatorio"),
    pymesProgram: Yup.string().required("Este campo es obligatorio"),
  });

  const handleSubmit = (form) => {
    const corpData = {
      name: form.institutionName,
      pyme_plan_name: form.pymesProgram,
      slug_landing: createSlugName(form.institutionName),
      location: form.institutionLocation,
      logo: pictureData,
      logotype: pictureDataLogotipo,
      color: colorCompany,
      on_trial: true,
      paid_until: "2022-01-01",
      smbs_number_in_network: 0,
    };
    const form_data = convertDataIntoForm(corpData);
    handler(form_data);
  };
  return (
    <Formik
      initialValues={initialFormikValue}
      onSubmit={(values) => {
        if (!validateLogo() || !validateLogotipo()) {
          return;
        }
        // createNewInstitution(values);
        handleSubmit(values);
      }}
      validationSchema={yupValidation}
    >
      {() => (
        <>
          <h2 className="onSubTitle">Asigna a a una comunidad</h2>
          <h1 className="onTitle">Personaliza el programa de la comunidad.</h1>
          <div className="stepBoxForm">
            <FormFormik>
              <Container fluid className="stepBoxContainer">
                <Row>
                  <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>Nombre de tu entidad</label>
                      <Field
                        name="institutionName"
                        id="institutionName"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="Nombre comercial"
                        type="text"
                      />
                      <ErrorMessage
                        name="institutionName"
                        component="div"
                        className="frmError"
                        style={{ marginTop: "0px" }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>Ubicación principal</label>
                      <Field
                        name="institutionLocation"
                        id="institutionLocation"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="Ciudad"
                        type="text"
                      />
                      <ErrorMessage
                        name="institutionLocation"
                        component="div"
                        className="frmError"
                        style={{ marginTop: "0px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>
                        Elige el nombre del programa para tu red de empresas{" "}
                        <span>Máximo 200 caracteres</span>
                      </label>
                      <Field
                        name="pymesProgram"
                        id="pymesProgram"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="Ejemplo: Programa de apoyo para PyMEs"
                        type="text"
                      />
                      <ErrorMessage
                        name="pymesProgram"
                        component="div"
                        className="frmError"
                        style={{ marginTop: "0px" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="container-logos">
                  <div className="container-logo">
                    <div className="container-logoLabel">
                      <p className="label-logo">Isotipo de tu entidad</p>
                      <img
                        src={MoreInfo}
                        alt="Más información"
                        onClick={() =>
                          setShowIsotipoModal({
                            current: "isotipo",
                            active: true,
                          })
                        }
                      />
                    </div>
                    <div className="container-file">
                      <div
                        style={{
                          height: 100,
                          width: 100,
                          display: "inline-block",
                          borderRadius: "50%",
                          border: "dashed 1px #979797",
                          backgroundImage: `url(${
                            imgPreview ? imgPreview : logoBgAdd
                          })`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      />
                      <div className="container-btnFile">
                        <label
                          htmlFor="file-upload"
                          className="btn-style main-btn-align"
                          style={{
                            width: 200,
                            backgroundColor: "#ECECEC",
                            color: "#232323",
                            justifyContent: "center",
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          Seleccionar Archivo
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          name="file"
                          onChange={(e) => onFileChange(e, "logo")}
                        />
                      </div>
                    </div>
                    {showErrorLogo && (
                      <div className="frmError" style={{ marginTop: "0px" }}>
                        Este campo es obligatorio
                      </div>
                    )}
                  </div>
                  <div className="container-logo">
                    <div className="container-logoLabel">
                      <p className="label-logo">Logotipo de tu entidad</p>
                      <img
                        src={MoreInfo}
                        alt="Más información"
                        onClick={() =>
                          setShowIsotipoModal({
                            current: "logotipo",
                            active: true,
                          })
                        }
                      />
                    </div>
                    <div className="container-file">
                      <div className="container-imgLogotipo">
                        <div
                          style={{
                            height: 60,
                            width: 100,
                            display: "inline-block",
                            border: "dashed 1px #979797",
                            backgroundImage: `url(${
                              imgPreviewLogotipo
                                ? imgPreviewLogotipo
                                : logoBgAdd
                            })`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </div>
                      <div className="container-btnFile">
                        <label
                          htmlFor="file-uploadLogotipo"
                          className="btn-style main-btn-align"
                          style={{
                            width: 200,
                            backgroundColor: "#ECECEC",
                            color: "#232323",
                            justifyContent: "center",
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "16px",
                          }}
                        >
                          Subir otro archivo
                        </label>

                        <input
                          id="file-uploadLogotipo"
                          type="file"
                          name="fileLogotipo"
                          onChange={(e) => onFileChange(e, "logotipo")}
                        />
                      </div>
                    </div>
                    {showErrorLogotipo && (
                      <div className="frmError" style={{ marginTop: "0px" }}>
                        Este campo es obligatorio
                      </div>
                    )}
                  </div>
                </Row>

                <Row>
                  <Col
                    md={12}
                    style={{
                      paddingLeft: 8,
                      paddingRight: 0,
                      marginBottom: "25px",
                    }}
                  >
                    <label style={{ marginBottom: "10px" }}>
                      Color más cercano a tu imagen
                    </label>

                    <CompactPicker
                      color={colorCompany}
                      onChangeComplete={(color) => setColorCompany(color.hex)}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "5px" }}>
                  <Col md={12} style={{ padding: "0px 0px", paddingLeft: 8 }}>
                    <button
                      type="submit"
                      className={isDisabled ? "frmButtonDisabled" : "frmButton"}
                      disabled={isDisabled}
                      style={{ marginTop: 0 }}
                    >
                      Continuar
                    </button>
                  </Col>
                </Row>
              </Container>
            </FormFormik>
          </div>
          <ModalHelpLogos
            showIsotipoModal={showIsotipoModal}
            setShowIsotipoModal={setShowIsotipoModal}
          />
        </>
      )}
    </Formik>
  );
};

export default SetCorporationCreateForm;
