import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Loading } from "@logra/component-library";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";

import { ModalNotCompanies } from "../../Components/modals/ModalNotCompanies";
import { PymesRegistered } from "../../Components/companies/PymesRegistered";
import "../../assets/styles/pages/companies.scss";
import { CompaniesList } from "../../Components/companies/CompaniesList";
import { FilterCompanies } from "../../Components/companies/FilterCompanies";
import { getMaxValues } from "../../utils/companies";
import { useSelector } from "react-redux";

const Companies = () => {
  /**
   *
   * This is Legacy code
   */

  const [companiesList, setCompaniesList] = useState([]);
  const [pymesRegistered, setPymesRegistered] = useState({
    total: 0,
    configuredProfile: 0,
  });

  const [showModalNotCompanies, setShowModalNotCompanies] = useState(false);
  const [sectorsData, setSectorsData] = useState({
    total: 0,
    sectors: [],
    totalSectors: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searching, setSearching] = useState({
    isSearching: false,
    results: [],
    init: true,
  });

  const [resetValues, setResetValues] = useState({
    pymesRegisted: {
      total: 0,
      configuredProfile: 0,
    },
    sectors: {
      total: 0,
      sectors: [],
      totalSectors: 0,
    },
  });

  const graph_data = useSelector((state) => state.graph_data.data);

  const convertDataIntoGraph = async (data) => {
    try {
      setIsLoading(true);
      if (data.metrics.users_count === 0) {
        setShowModalNotCompanies(true);
      }
      const totalProfileUpdated = data.metrics.users_pyme_profile_count;
      setPymesRegistered({
        total: data.metrics.users_count,
        configuredProfile: totalProfileUpdated,
      });
      setCompaniesList(data.smbs_registered_details);
      setIsLoading(false);
      const maxValues = getMaxValues(data.smbs_registered_details);
      setSectorsData({
        total: data.smbs_registered_details.length,
        sectors: maxValues,
        totalSectors: data.smbs_registered_details.length,
      });
      setResetValues({
        pymesRegistered: {
          // total: data.smbs_registered_details.length,
          total: data.metrics.users_count,
          configuredProfile: totalProfileUpdated,
        },
        sectors: {
          total: data.smbs_registered_details.length,
          sectors: maxValues,
          totalSectors: data.smbs_registered_details.length,
        },
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  // const getData = async () => {
  //   setIsLoading(true);
  //   const userData = await getCurrentUser();
  //   try {
  //     const params = {
  //       user_id: userData.id,
  //       corp_id: userData.companyId,
  //     };
  //     const { data } = await api.axiosAuthPost(
  //       "/api/v1/corporation-kpis/",
  //       userData.token,
  //       params
  //     );

  //     if (data.metrics.users_count === 0) {
  //       setShowModalNotCompanies(true);
  //     }

  //     // const totalProfileUpdated = getProfileUpdated(
  //     //   data.smbs_registered_details
  //     // );
  //     const totalProfileUpdated = data.metrics.users_pyme_profile_count;

  //     setPymesRegistered({
  //       total: data.metrics.users_count,
  //       configuredProfile: totalProfileUpdated,
  //     });

  //     setCompaniesList(data.smbs_registered_details);
  //     setIsLoading(false);

  //     const maxValues = getMaxValues(data.smbs_registered_details);
  //     setSectorsData({
  //       total: data.smbs_registered_details.length,
  //       sectors: maxValues,
  //       totalSectors: data.smbs_registered_details.length,
  //     });
  //     setResetValues({
  //       pymesRegistered: {
  //         // total: data.smbs_registered_details.length,
  //         total: data.metrics.users_count,
  //         configuredProfile: totalProfileUpdated,
  //       },
  //       sectors: {
  //         total: data.smbs_registered_details.length,
  //         sectors: maxValues,
  //         totalSectors: data.smbs_registered_details.length,
  //       },
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (graph_data) {
      convertDataIntoGraph(graph_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="rowLimit  rowDashboard rowFilter">
        <Col lg={6}>
          <MainTitle style={{ marginBottom: 50 }}>
            Listado y perfil de las empresas registradas
          </MainTitle>
        </Col>
        <Col lg={6}></Col>
      </Row>
      <FilterCompanies
        companiesList={companiesList}
        setSearching={setSearching}
        searching={searching}
        setPymesRegistered={setPymesRegistered}
        setSectorsData={setSectorsData}
        resetValues={resetValues}
      />

      {isLoading ? (
        <Row className="rowLimit  rowDashboard rowWidth">
          <div className="companies-containerLoading">
            <Loading />
          </div>
        </Row>
      ) : (
        <>
          <Row className="rowLimit  rowDashboard rowWidth graphicsRow">
            <PymesRegistered
              pymesRegistered={pymesRegistered}
              sectorsData={sectorsData}
            />
          </Row>
          <Row className="rowLimit  rowDashboard rowWidth">
            <CompaniesList list={companiesList} searching={searching} />
          </Row>
        </>
      )}
      <ModalNotCompanies
        show={showModalNotCompanies}
        close={setShowModalNotCompanies}
      />
    </>
  );
};

export default Companies;
