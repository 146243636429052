import { createSlice } from "@reduxjs/toolkit";
import { fetchCorpData } from "./corpData";

const corpDataSlice = createSlice({
  name: "corp_data",
  initialState: { data: null, timeStamp: null, error: null, loading: false },
  reducers: {
    resetData: (state) => {
      state.data = null;
      state.timeStamp = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorpData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCorpData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.corp_data;
        state.timeStamp = action.payload.timeStamp;
      })
      .addCase(fetchCorpData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default corpDataSlice.reducer;
