import React, { useState } from "react";
import { Container, Col } from "react-bootstrap";

import "../../../assets/styles/pages/design-app.scss";
import "../../../assets/styles/pages/index.scss";

import { getCookie } from "../../../utils/cookiesUtils";
import { getCurrentUser } from "../../../utils/authUtils";
import api from "../../../utils/axiosUsage";
import { notifyBad, notifyCool } from "../../../utils/toastNotifications";
import { useHistory } from "react-router-dom";
import SelectCorporation from "../../../Components/corporations/SelectCorporation";
import EditExistingCorp from "../../../Components/corporations/EditExistingCorp";
import DetailsForCorp from "../../../Components/corporations/DetailsExistingCorp";
import { blobToFile, urlToBlob } from "../../../utils/filesUtils";

const EditCorporationManager = () => {
  const [step, setStep] = useState(1);
  const [corporation, setCorporation] = useState({});
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = getCookie("session_token") || getCurrentUser().token;
  const changeStep = (val) => {
    setStep(val);
  };

  // Handle error when corp edition fails
  const handleCorpEditError = () => {
    notifyBad("¡Hubo un error!");
    setError(true);
    setErrorMessage("Hubo un error en la edicion de la corporacion");
  };

  // Handle general error
  const handleEmptyValues = () => {
    notifyBad("Debes ingresar un valor");
    setError(true);
    setErrorMessage("No puedes enviar un valor vacio");
  };

  const handleSelectCorporation = async (corpId) => {
    if (!corpId) {
      handleEmptyValues();
      return;
    }
    try {
      const response = await api.axiosAuthGet(
        `/api/v1/corporation/${corpId}/`,
        token
      );
      const corporation = response.data;
      setCorporation(corporation);
      notifyCool("Corporacion asignada");
      changeStep(step + 1);
    } catch (err) {
      handleCorpEditError();
      console.error("Hubo un error al intentar asignar una corporación: ", err);
    }
  };

  const handleEditCorporation = async (values) => {
    if (!values && !corporation) {
      handleEmptyValues();
      return;
    }

    try {
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("pyme_plan_name", values.pyme_plan_name);
      formdata.append("location", values.location);
      formdata.append("color", values.color);
      formdata.append("on_trial", values.on_trial);
      formdata.append("paid_until", values.paid_until);
      formdata.append("smbs_number_in_network", values.smbs_number_in_network);

      if (values.logo instanceof File) {
        formdata.append("logo", values.logo);
      } else if (values.logo && values.logo.trim() !== "") {
        const logoBlob = await urlToBlob(values.logo);
        const logoFile = await blobToFile(logoBlob, "logo.png", "image/png");
        formdata.append("logo", logoFile);
      }

      if (values.logotype instanceof File) {
        formdata.append("logotype", values.logotype);
      } else if (values.logotype && values.logotype.trim() !== "") {
        const logotypeBlob = await urlToBlob(values.logotype);
        const logotypeFile = await blobToFile(
          logotypeBlob,
          "logotype.png",
          "image/png"
        );
        formdata.append("logotype", logotypeFile);
      }

      const configs = { "content-type": "multipart/form-data" };
      const response = await api.axiosAuthPatch(
        `/api/v1/corporation/${corporation.id}/`,
        token,
        formdata,
        configs
      );

      if (response.status === 200 || response.status === 201) {
        notifyCool("Corporacion editada satisfactoriamente");
        changeStep(step + 1);
      }
    } catch (err) {
      handleCorpEditError();
      console.error("Hubo un error al intentar editar la corporación: ", err);
    }
  };

  const handleCorporationDetails = async (values) => {
    if (!values && !corporation) {
      handleEmptyValues();
      return;
    }
    try {
      const response = await api.axiosAuthPatch(
        `/api/v1/corporation/${corporation.id}/`,
        token,
        values
      );
      if (response.status === 200 || response.status === 201) {
        notifyCool("Corporacion editada satisfactoriamente");
        history.push("/home");
      }
    } catch (err) {
      handleCorpEditError();
      console.error("Hubo un error al intentar editar la corporación: ", err);
    }
  };

  const steps = [
    {
      id: 1,
      description: "Selecciona una entidad",
      frm: <SelectCorporation handler={handleSelectCorporation} />,
    },
    {
      id: 2,
      description: "Edita una entidad existente",
      frm: (
        <EditExistingCorp
          handler={handleEditCorporation}
          formState={corporation}
        />
      ),
    },
    {
      id: 3,
      description: "Añade mas detalles a la entidad existente",
      frm: (
        <DetailsForCorp
          handler={handleCorporationDetails}
          formState={corporation}
        />
      ),
    },
  ];

  const renderMenuOptions = () => {
    const menu = Object.values(steps).map((opt) => {
      return (
        <li key={`menu${opt.id}`} className={step === opt.id ? "active" : ""}>
          {opt.description}
        </li>
      );
    });
    return menu;
  };

  const errorMessageStyles = {
    display: error ? "block" : "none",
    padding: "10px",
    marginBottom: 15,
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };

  return (
    <Container fluid className="stepPage-container">
      <Col lg={12} className="stepPage-content">
        <img
          src="/images/logos/logo.svg"
          alt="logo"
          style={{ marginLeft: "30px", marginBottom: "30px" }}
        />
        <div
          className="adminForms"
          style={{ overflow: "auto", padding: "1.5rem" }}
        >
          {steps[step - 1].frm}
        </div>
        <div style={errorMessageStyles}>{errorMessage}</div>
      </Col>
      <div className="colStepsDescription">
        <h3>Edita entidades</h3>
        <p>Como administrador de logra puedes editar una entidad</p>
        <ul>{renderMenuOptions()}</ul>
      </div>
    </Container>
  );
};

export default EditCorporationManager;
