import React from "react";

const OptionsIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width="20"
      height="6"
      viewBox="0 0 20 6"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.833252C9.57148 0.833252 9.15257 0.960325 8.79627 1.1984C8.43996 1.43648 8.16225 1.77487 7.99826 2.17077C7.83427 2.56668 7.79137 3.00232 7.87497 3.42261C7.95857 3.84291 8.16492 4.22897 8.46794 4.53198C8.77095 4.835 9.15701 5.04135 9.57731 5.12495C9.9976 5.20856 10.4332 5.16565 10.8291 5.00166C11.2251 4.83767 11.5634 4.55996 11.8015 4.20365C12.0396 3.84735 12.1667 3.42845 12.1667 2.99992C12.1667 2.42528 11.9384 1.87418 11.5321 1.46785C11.1257 1.06153 10.5746 0.833252 10 0.833252ZM2.41667 0.833252C1.98814 0.833252 1.56924 0.960325 1.21293 1.1984C0.856626 1.43648 0.578919 1.77487 0.414929 2.17077C0.250939 2.56668 0.208032 3.00232 0.291633 3.42261C0.375234 3.84291 0.581589 4.22897 0.884603 4.53198C1.18762 4.835 1.57368 5.04135 1.99397 5.12495C2.41426 5.20856 2.84991 5.16565 3.24582 5.00166C3.64172 4.83767 3.98011 4.55996 4.21819 4.20365C4.45626 3.84735 4.58333 3.42845 4.58333 2.99992C4.58333 2.42528 4.35506 1.87418 3.94873 1.46785C3.5424 1.06153 2.9913 0.833252 2.41667 0.833252ZM17.5833 0.833252C17.1548 0.833252 16.7359 0.960325 16.3796 1.1984C16.0233 1.43648 15.7456 1.77487 15.5816 2.17077C15.4176 2.56668 15.3747 3.00232 15.4583 3.42261C15.5419 3.84291 15.7483 4.22897 16.0513 4.53198C16.3543 4.835 16.7403 5.04135 17.1606 5.12495C17.5809 5.20856 18.0166 5.16565 18.4125 5.00166C18.8084 4.83767 19.1468 4.55996 19.3849 4.20365C19.6229 3.84735 19.75 3.42845 19.75 2.99992C19.75 2.42528 19.5217 1.87418 19.1154 1.46785C18.7091 1.06153 18.158 0.833252 17.5833 0.833252Z"
        fill={color}
      />
    </svg>
  );
};

export default OptionsIcon;
