import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Main } from "../Components/Atoms/Main";
// import { ModalExpiredSession } from "../Components/modals/ModalExpiredSession";
import { GlobalStyle } from "../Components/Styles/GlobalStyle";
import Invitation from "../Pages/Invitation/Invitation";
import RecoveryPassword from "../Pages/RecoveryPassword/RecoveryPassword";
import { ListedRoutes } from "./ListedRoutes";
import { useEffect } from "react";
import { clearSessionData } from "../utils/authStorageUtils.tsx";
import { useDispatch } from "react-redux";

const getValidRoutes = (isAuth, isAdmin) => {
  const validRoutes = [];
  ListedRoutes.forEach((route) => {
    const isPublic = route.isPublic;
    const isPrivate = route.isPrivate;
    const isAdminRoute = route.isAdmin;
    if (isPrivate && !isAuth) {
      return;
    } else if (isPrivate && isAdminRoute && !isAdmin) {
      return;
    } else if (isPublic && isAuth) {
      return;
    }
    validRoutes.push(route);
  });

  // Include /invited/:token route for all users
  validRoutes.push({
    path: "/app/invitation/:token",
    exact: true,
    component: Invitation,
  });

  // Include /invited/:token route for all users
  validRoutes.push({
    path: "/resetpassword/:token",
    exact: true,
    component: RecoveryPassword,
  });

  return validRoutes;
};

const renderRoutes = (validRoutes) => {
  return validRoutes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      exact={route.exact}
      component={route.component}
    />
  ));
};

export const CombinedRoutes = ({ isAuth, isAdmin }) => {
  const validRoutes = getValidRoutes(isAuth, isAdmin);
  const history = useHistory();
  const dispatch = useDispatch();

  const SESSION_EXPIRATION_TIME_SECONDS = 172800; // 48 horas en segundos
  function checkSessionExpiration() {
    const sessionTime = localStorage.getItem("session_time");
    if (sessionTime) {
      const elapsedTimeSeconds = (Date.now() - parseInt(sessionTime)) / 1000;
      if (elapsedTimeSeconds > SESSION_EXPIRATION_TIME_SECONDS) {
        console.log("Limpiado");
        clearSessionData(dispatch);
        history.push("/");
      }
    }
  }

  useEffect(() => {
    const unlisten = history.listen(() => checkSessionExpiration());
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Main
      expanded={true}
      style={
        isAuth
          ? { marginLeft: 50, height: "100vh" }
          : { marginLeft: 0, background: "white" }
      }
    >
      <div className="main-content">
        <GlobalStyle />
        <Switch>
          {renderRoutes(validRoutes)}
          <Redirect to={isAuth ? "/home" : "/"} />
        </Switch>
      </div>
    </Main>
  );
};
