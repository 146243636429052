import React, { useState, useEffect } from "react";
import "../../assets/styles/pages/configuration/configuration-page.scss";
import { Container, Row, Col } from "react-bootstrap";
import CommunityProfile from "./CommunityProfile";
import { useSelector } from "react-redux";
import TeamData from "./TeamData";
import { getCurrentCompany, getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";

const ConfigurationPage = () => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  /**
   * This component should render three options and host three components
   * The about component
   * Team component
   * Team enterprise
   */

  const mock_arr = [
    { profile_photo: "", name: "test1" },
    {
      profile_photo: "",
      name: "test2",
    },
  ];
  useEffect(() => {
    getTeamMember(user, user.token);
    return () => {};
  }, []);

  const user = useSelector((state) => state.user_data.data);
  const getTeamMember = async (user, token) => {
    try {
      const userData = await getCurrentUser();
      const company = await getCurrentCompany();
      const { data } = await api.axiosAuthGet(
        `/api/v1/view-team/${user.corp}/`,
        token
      );
      if (!data) {
        setTeam(mock_arr);
      } else {
        setTeam(data.members);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <Row>
        <Col className="configuration-box-container">
          <CommunityProfile />
        </Col>
        <Col className="configuration-box-container">
          <TeamData team={team} loading={loading} />
        </Col>
        {/* <Col lg={5}><CommunityData /></Col> */}
      </Row>
    </Container>
  );
};

export default ConfigurationPage;
