import { AxiosResponse } from "axios";
import api from "../utils/axiosUsage";
import { getTimeTokenRest } from "../utils/tokenValidation";

interface EditParams {
  id: number;
  endpoint: string;
  params: object;
  token: string;
}
/**
 * Edita un elemento del endpoint especificado.
 * @param id - El ID del elemento a obtener.
 * @param endpoint - El endpoint del API.
 * @param token - El token de autenticación.
 * @param params - Un objeto con los parametros requeridos para editar
 * @returns El elemento obtenido.
 */

export const HandleEdit = async (params: EditParams) => {
  if (!params.id || !params.endpoint || !params.token || !params.params) {
    throw new Error("Faltan parametros");
  }

  try {
    const editElementResponse = await api.axiosAuthPatch(
      params.endpoint,
      params.token,
      params.params
    );
    if (
      editElementResponse.status !== 200 &&
      editElementResponse.status !== 201
    ) {
      throw new Error(
        `Fallo en editar el elemento por error : ${editElementResponse.status}`
      );
    }
    return editElementResponse.data;
  } catch (err) {
    throw new Error(`Fallo en editar el elemento: ${err.message}`);
  }
};

interface getElementParams {
  id: number;
  endpoint: string;
  token: string;
  hasTrailingSlash: boolean;
}
/**
 * Obtiene un elemento del endpoint especificado.
 * @param id - El ID del elemento a obtener.
 * @param endpoint - El endpoint del API.
 * @param token - El token de autenticación.
 * @param hasTrailingSlash - Indica si se debe agregar una barra al final del endpoint.
 * @returns El elemento obtenido.
 */

export const getElement = async (params: getElementParams) => {
  if (
    !params.endpoint ||
    !params.token ||
    !params.id ||
    !params.hasTrailingSlash
  ) {
    throw new Error("Faltan parametros");
  }

  try {
    const endpoint = params.hasTrailingSlash
      ? `${params.endpoint}/${params.id}/`
      : `${params.endpoint}/${params.id}`;

    const getElementResponse = await api.axiosAuthGet(endpoint, params.token);
    if (
      getElementResponse.status !== 200 &&
      getElementResponse.status !== 201
    ) {
      throw new Error(
        `Fallo en obtener el elemento con id ${params.id} en el endpoint ${params.endpoint}. El error fue: ${getElementResponse.status}`
      );
    } else {
      return getElementResponse.data;
    }
  } catch (err) {
    throw new Error(`Fallo en obtener el elemento: ${err.message}`);
  }
};

interface createAuthElementParams {
  params: object;
  token: string;
  endpoint: string;
}

/**
 * Crea un elemento autenticado del endpoint especificado.
 * @param params - El objeto con los params esperados.
 * @param endpoint - El endpoint del API.
 * @param token - El token de autenticación.
 * @returns El elemento obtenido.
 */

export const createAuthElement = async (params: createAuthElementParams) => {
  if (!params.endpoint || !params.params || !params.token) {
    throw new Error("Faltan parametros");
  }

  try {
    const createAuthElementResponse = (await api.axiosAuthPost(
      params.endpoint,
      params.params,
      params.token
    )) as { status: number; data: any };

    if (
      createAuthElementResponse.status !== 200 &&
      createAuthElementResponse.status !== 201
    ) {
      throw new Error(
        `Fallo en crear el elemento en el endpoint ${params.endpoint}. El error fue: ${createAuthElementResponse.status}`
      );
    } else {
      return createAuthElementResponse.data;
    }
  } catch (err) {
    throw new Error(`Fallo en crear el elemento: ${err.message}`);
  }
};

interface createElementParams {
  params: object;
  endpoint: string;
  hasTrailingSlash: boolean;
}

/**
 * Crea un elemento para endpoint publico sin necesidad de autenticacion.
 * @param params - El objeto con los params esperados.
 * @param endpoint - El endpoint del API.
 * @param hasTrailingSlash - Indica si se debe agregar una barra al final del endpoint.
 * @returns El elemento obtenido.
 */

export const createElement = async (params: createElementParams) => {
  if (!params.endpoint || !params.params || !params.hasTrailingSlash) {
    throw new Error("Faltan parametros");
  }
  try {
    const createElementResponse = (await api.axiosPost(
      params.endpoint,
      params.params
    )) as { status: number; data: any };

    if (
      createElementResponse.status !== 200 &&
      createElementResponse.status !== 201
    ) {
      throw new Error(
        `Fallo en crear el elemento en el endpoint ${params.endpoint}. El error fue: ${createElementResponse.status}`
      );
    } else {
      return createElementResponse.data;
    }
  } catch (err) {
    throw new Error(`Fallo en crear el elemento: ${err.message}`);
  }
};

interface getAllElementsParams {
  endpoint: string;
  token: string;
  hasTrailingSlash: boolean;
}
/**
 * Obtiene todos los elementos de un endpoint especifico.
 * NO DEBERIA SER USADA.
 * @param endpoint - El endpoint del API.
 * @param token - El token de autenticación.
 * @param hasTrailingSlash - Indica si se debe agregar una barra al final del endpoint.
 * @returns El elemento obtenido.
 */

export const getAllElements = async (params: getAllElementsParams) => {
  if (!params.endpoint || !params.token || !params.hasTrailingSlash) {
    throw new Error("Faltan parametros");
  }
  try {
    const endpoint = params.hasTrailingSlash
      ? `${params.endpoint}/`
      : `${params.endpoint}`;

    const getAllElementsResponse = await api.axiosAuthGet(
      endpoint,
      params.token
    );
    if (
      getAllElementsResponse.status !== 200 &&
      getAllElementsResponse.status !== 201
    ) {
      throw new Error(
        `Fallo en obtener todos los elementos del endpoint ${params.endpoint}. El error fue: ${getAllElementsResponse.status}`
      );
    } else {
      return getAllElementsResponse.data;
    }
  } catch (err) {
    throw new Error(`Fallo en obtener todos los elementos: ${err.message}`);
  }
};

//--------------------------------------

interface AsignUserParams {
  userId: number;
  token: string;
  corpId: number;
}

export async function asignUserToCorporation(params: AsignUserParams) {
  if (!params.userId || !params.token || !params.corpId) {
    throw new Error("Faltan parametros");
  }

  try {
    const editedData = {
      is_corp_owner: true,
      corp: params.corpId,
    };
    const editCrudUserReponse = await api.axiosAuthPatch(
      `/api/v1/profile-crud-user/${params.userId}`,
      params.token,
      editedData
    );

    if (
      editCrudUserReponse.status === 200 ||
      editCrudUserReponse.status === 201
    ) {
      /**
       * Ahora editamos el endpoint de account
       */

      const editAccountResponse = await api.axiosAuthPatch(
        "/api/v1/account",
        params.token,
        editedData
      );
      if (
        editAccountResponse.status === 200 ||
        editAccountResponse.status === 201
      ) {
        return editAccountResponse.data;
      } else {
        throw new Error("No se pudo editar el perfil de account ");
      }
    } else {
      throw new Error("No se pudo asignar usuario a una corporacion");
    }
  } catch (err) {
    throw err;
  }
}

interface CreateUserParams {
  credentials: {
    email: string;
    password: string;
  };
}

/**
 *Crea un nuevo usuario utilizando las credenciales proporcionadas.
 *@param params - Un objeto que contiene las credenciales del usuario a crear.
 *@param params.credentials.email - El correo electrónico del usuario.
 *@param params.credentials.password - La contraseña del usuario.
 *@returns Un objeto con la respuesta de la solicitud de creación de usuario.
 *@throws Un error si faltan las credenciales o si se produce un error al crear el usuario.
 */

export const CreateUser = async (params: CreateUserParams) => {
  if (!params.credentials) throw new Error("Faltan las credenciales");

  const { email, password } = params.credentials;
  const response = {
    success: false,
    data: null,
    errors: [],
  };

  try {
    const sign_in_response = await api.axiosPost("/api/v1/signup", {
      email,
      password,
    });

    if (sign_in_response.status === 200 || sign_in_response.status === 201) {
      response.success = true;
      response.data = sign_in_response.data;
    } else {
      throw new Error("Error al crear cuenta.");
    }
  } catch (error) {
    throw new Error("Error en general.");
  }

  return response;
};

// ------------------------------------

interface validateTokenParams {}
async function ValidateToken(params: validateTokenParams) {}

/**
 * Maneja el inicio de sesión de un usuario y devuelve la información necesaria para continuar.
 * @param {Object} params - Los parámetros para manejar el inicio de sesión.
 * @param {Object} params.credentials - Las credenciales del usuario para iniciar sesión.
 * @param {string} params.credentials.email - El correo electrónico del usuario.
 * @param {string} params.credentials.password - La contraseña del usuario.
 * @returns {Object} - La información del usuario y la corporación a la que pertenece, junto con posibles errores que se produzcan.
 * @property {boolean} success - Indica si se inició sesión correctamente.
 * @property {Array.<string>} errors - Una lista de errores que ocurrieron durante el inicio de sesión.
 * @property {Object} companyData - La información de la corporación a la que pertenece el usuario.
 * @property {Object} connectedUserData - La información del usuario conectado.
 */

interface HandleLoginParams {
  credentials: {
    email: string;
    password: string;
  };
}

export async function HandleLogin(params: HandleLoginParams) {
  const credentials = params.credentials;
  const errors: string[] = [];
  let success = false;
  let companyData: { [key: string]: any } = {};
  let connectedUserData: { [key: string]: any } = {};

  // Verificamos que se hayan ingresado las credenciales
  if (!credentials.email || !credentials.password) {
    errors.push("Debes ingresar tus credenciales");
    return { success, errors, companyData, connectedUserData };
  }

  try {
    // Realizamos una petición para iniciar sesión
    const response = await api.axiosPost("/api/v1/login/", credentials);

    if (response.status === 200 || response.status === 201) {
      // Extraemos el token de acceso y el token de refresh
      const access_token = response.data.access;
      const refresh_token = response.data.refresh;

      // Obtenemos la información de la cuenta del usuario
      const accountInfo = await api.axiosAuthGet(
        `/api/v1/account`,
        access_token
      );

      // Obtenemos el ID de la corporación a la que pertenece el usuario
      const corpId = accountInfo.data.corp;

      // Obtenemos la información de la corporación
      const companyInfo = await api.axiosAuthGet(
        `/api/v1/corporation/${corpId}/`,
        access_token
      );

      // Agregamos el ID de la corporación a la información de la cuenta del usuario
      accountInfo.data.companyId = corpId;

      // Actualizamos las variables que se devolverán
      companyData = companyInfo.data;
      connectedUserData = accountInfo.data;
      connectedUserData.token = access_token;
      connectedUserData.access_token = access_token;
      connectedUserData.refresh_token = refresh_token;
      success = true;
    } else {
      //Este escenario plantea que no se pudo realizar la peticion y algo fallo en el proceso,seguramente la respuesta del servidor fue un 400 o algo similar y por eso fallo
      errors.push(
        "Credenciales de acceso inválidos,usuario no es dueño de una entidad."
      );
    }
  } catch (error) {
    //Este es el catch que agrega el error en caso de que ni si quiera se haya podido hacer la solicitud al endpoint de login
    errors.push("Credenciales de acceso invalidos,vuelve a intentar");
  }

  return { success, errors, companyData, connectedUserData };
}

//--------------------------
