import React from "react";

const MenuIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 24 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 15.5H23.25V13H0.75V15.5ZM0.75 9.25H23.25V6.75H0.75V9.25ZM0.75 0.5V3H23.25V0.5H0.75Z"
        fill={color}
      />
    </svg>
  );
};

export default MenuIcon;
