import React, { useState } from "react";
import api from "../../../utils/axiosUsage";
import { Button, Col, Container, Row } from "react-bootstrap";
import { notifyBad, notifyCool } from "../../../utils/toastNotifications";
import CreateAccount from "../../../Components/Onboarding/CreateAccount";
import ConnectAccount from "../../../Components/Onboarding/ConnectAccount";

const FrmSetUserAccount = (props) => {
  const { changeStep, step, setStoredUser, setSelectedId } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [showOptions, setShowOptions] = useState(false);

  const handleUserCreationError = () => {
    setError(true);
    setErrorMessage("No se pudo crear el usuario");
    notifyBad("¡Hubo un error!");
  };

  const handleUserConnectionError = () => {
    setError(true);
    setErrorMessage("No se pudo conectar el usuario");
    notifyBad("¡Hubo un error!");
  };

  const handleGeneralError = () => {
    setError(true);
    setErrorMessage("Ocurrió un error");
  };

  const handleFormSubmission = async (form, url) => {
    const { email, password } = form;

    try {
      const sign_in_response = await api.axiosPost(url, { email, password });
      const login_response = await api.axiosPost("/api/v1/login/", {
        email,
        password,
      });
      if (login_response.status === 200 || login_response.status === 201) {
        // const token = login_response.data.user_token;
        const token = login_response.data.access;
        const account_info = await api.axiosAuthGet(`/api/v1/account`, token);
        const account_data = account_info.data;
        const created_user = {
          id: account_data.id,
          name: account_data.name,
          email: account_data.email,
        };
        setStoredUser(created_user);
        console.log("token", token);
        console.log("info cuenta", account_data);
        console.log("cuenta creada ", created_user);
        notifyCool("Usuario creado satisfactoriamente");
        changeStep(step + 1);
      } else if (login_response.status === 422) {
        handleUserCreationError();
      } else {
        handleUserConnectionError();
      }
    } catch (error) {
      handleGeneralError();
    }
  };

  const handleCreateUser = () => {
    setIsCreateUser(true);
    setShowOptions(true);
  };

  const handleConnectUser = () => {
    // setSelectedUserId()
    setIsCreateUser(false);
    setShowOptions(true);
  };

  const handleCreateNewAccount = async (form) => {
    const { firstname, lastname, email, password } = form;
    const url = "/api/v1/signup";
    await handleFormSubmission({ firstname, lastname, email, password }, url);
  };

  const handleConnectUserAccount = async (form) => {
    const url = "/api/v1/login/";
    await handleFormSubmission(form, url);
  };
  const handleAsignUser = (form) => {
    const user_id = form.selectedOption;
    setStoredUser({ id: user_id });
    notifyCool(`Usuario nro ${user_id} asignado satisfactoriamente`);
    changeStep(step + 1);
  };

  const errorMessageStyles = {
    display: error ? "block" : "none",
    padding: "10px",
    marginBottom: 15,
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };

  return (
    <Container className="onboardingStepForm" fluid>
      <Row className="onboardingStepRow">
        <Col>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            {showOptions ? (
              <>
                {isCreateUser ? (
                  <CreateAccount handler={handleCreateNewAccount} />
                ) : (
                  <ConnectAccount handler={handleAsignUser} />
                )}
              </>
            ) : (
              <div className="stepBoxSelectOption">
                <p>Selecciona una opcion</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={errorMessageStyles}>{errorMessage}</div>
          <div className="stepFormSelectionBtns">
            <Button className={`stepFormBtn`} onClick={handleCreateUser}>
              Crea un nuevo usuario
            </Button>
            <Button className={`stepFormBtn`} onClick={handleConnectUser}>
              Conecta un usuario
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FrmSetUserAccount;
