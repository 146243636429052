import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { notifyCool } from "../../utils/toastNotifications";
import api from "../../utils/axiosUsage";
import AlertSuccess from "../../Components/Atoms/AlertSuccess";
import StartSlider from "../../Components/StartSlider/StartSlider";
import { ForgotSubtitle } from "./RecoveryPasswordStyles";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { delayedExecution } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { clearAllCookies, deleteCookie } from "../../utils/cookiesUtils";
import { deactivateSession } from "../../features/user_session/userSessionSlice";

function RecoveryPassword() {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { token } = useParams();
  const session_active = useSelector((state) => state.user_session);
  const dispatch = useDispatch();
  const history = useHistory();
  function handleEmptyPassword() {
    setError(true);
    setErrorMessage("Debes de ingresar tu nueva contraseña");
  }

  function handleNotMatchPasswords() {
    setError(true);
    setErrorMessage("Las contraseñas NO coinciden");
  }

  function handleChangingErrorPassword() {
    setError(true);
    setErrorMessage("Hubo un fallo en el cambio de contraseña");
  }

  const Logout = (dispatch) => {
    dispatch(deactivateSession());
    localStorage.removeItem("user_params");
    localStorage.removeItem("company");
    deleteCookie("session_token");
    deleteCookie("sessionId");
  };

  function clearLocalStorage() {
    localStorage.clear();
    console.log("Local storage cleared.");
  }

  useEffect(() => {
    if (session_active.is_active) {
      Logout(dispatch);
    } else {
      clearLocalStorage();
      clearAllCookies();
    }
    return () => {};
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("La contraseña es requerida")
        .min(6, "La contraseña debe tener al menos 6 caracteres"),
      confirmPassword: Yup.string()
        .required("Confirma tu contraseña")
        .oneOf([Yup.ref("password")], "Las contraseñas no coinciden"),
    }),
    onSubmit: (values) => HandleSubmit(values),
  });

  async function HandleSubmit(values) {
    const password = values.password;
    const confirmPassword = values.confirmPassword;

    if (password === "" || confirmPassword === "") {
      handleEmptyPassword();
      return false;
    }
    if (password !== confirmPassword) {
      handleNotMatchPasswords();
      return false;
    }

    try {
      const credentials = {
        confirmation_code: token,
        new_password1: password,
        new_password2: confirmPassword,
      };
      const response = await api.axiosPost(
        "/api/v1/restore-password",
        credentials
      );
      if (response.status === 200 || response.status === 201) {
        notifyCool("La contraseña fue actualizada satisfactoriamente");
        delayedExecution(() => history.push("/"), 3000);
      } else {
        handleChangingErrorPassword();
      }
    } catch (err) {
      console.error(err);
    }
  }

  const formStyle = {
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  };

  const errorStyles = {
    display: error ? "block" : "none",
    padding: "10px",
    marginBottom: 15,
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };

  return (
    <>
      <Container fluid className="landing-content backgroundStripe">
        <AlertSuccess
          showAlert={false}
          setShowAlert={setShowAlert}
          message={alertMessage}
        />
        <Row className="rowPage" style={{ display: "flex", flexWrap: "wrap" }}>
          <Col xs={12} md={12} lg={6} className="columnIntro">
            <div className="home-topbar logra-logo">
              <img src="/images/logos/logo.svg" alt="Logra.io logo" />
            </div>
            <Form
              className="loginContainer"
              style={formStyle}
              onSubmit={formik.handleSubmit}
            >
              <ForgotSubtitle>Tu nueva contraseña</ForgotSubtitle>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div>{formik.errors.password}</div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="formBasicConfirmPassword"
                style={{ marginTop: "-20px" }}
              >
                <Form.Label>Confirmar contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirmar contraseña"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div>{formik.errors.confirmPassword}</div>
                ) : null}
              </Form.Group>
              <div style={errorStyles}>{errorMessage}</div>
              <Button type="submit" className="btn-style btn-text">
                Ingresar
              </Button>
            </Form>
          </Col>
        </Row>
        <div className="startCover">
          <StartSlider />
        </div>
      </Container>
    </>
  );
}

export default RecoveryPassword;
