import React, { useState } from "react";
import Lodash from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Container, Row, Col, Form } from "react-bootstrap";

import api from "../../utils/axiosUsage";
import { LograLink } from "../../assets/styles/pages/general/GeneralStyles";
import { ToastContainer } from "react-toastify";
import { notifyBad } from "../../utils/toastNotifications";
import { createSessionCookies } from "../../utils/authUtils";

const FrmAccount = (props) => {
  const { userEmail, changeStep, step, changePersonName } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const [error, setError] = useState(false);

  const createNewAccount = async (form) => {
    const name = Lodash.get(form, "firstname");
    const lastname = Lodash.get(form, "lastname");
    const email = userEmail;
    const password = Lodash.get(form, "password");

    const credentials = { name, lastname, email, password };

    api
      .axiosPost("/api/v1/signup", credentials)
      .then(async (response) => {
        if (response.status === 201) {
          const loginCredentials = { email, password };
          const signIn = await api.axiosPost(
            "/api/v1/login/",
            loginCredentials
          );
          // const token = signIn.data.user_token;
          const token = signIn.data.access;
          // const sessionCookies = createSessionCookies(token);
          // changePersonName(signIn.data.name);
          changePersonName(name);
          changeStep(step + 1);
        } else {
          console.log("El Usuario no pudo ser Creado!");
          notifyBad("¡Hubo un error!");
        }
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      password: "",
      passwordConfirm: "",
    },

    validationSchema: Yup.object({
      firstname: Yup.string().required("Este campo es obligatorio"),
      lastname: Yup.string().required("Este campo es obligatorio"),
      password: Yup.string()
        .required("Este campo es obligatorio")
        .min(8, "La contraseña requiere 8 caracteres"),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref("password"), null], "Las contraseñas NO coinciden")
        .required("Este campo es obligatorio"),
    }),

    onSubmit: (values) => {
      createNewAccount(values);
    },
  });

  return (
    <>
      <h2 className="onSubTitle">Bienvenido a Logra 👋</h2>
      <h1 className="onTitle">Termina de crear tu cuenta.</h1>
      <div className="stepBoxForm">
        <form onSubmit={formik.handleSubmit}>
          <Container fluid>
            <Row>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Nombre</label>
                  <Form.Control
                    id="firstname"
                    name="firstname"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Tu nombre"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                  />
                </Form.Group>
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="frmError">{formik.errors.firstname}</div>
                ) : null}
              </Col>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Apellido</label>
                  <Form.Control
                    id="lastname"
                    name="lastname"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Tu apellido"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                  />
                </Form.Group>
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="frmError">{formik.errors.lastname}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>
                    Contraseña <span>Mínimo 8 caracteres</span>
                  </label>
                  <Form.Control
                    id="password"
                    name="password"
                    className="inputNewBrand main-btn-align"
                    type="password"
                    placeholder="Contraseña"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                </Form.Group>
                {formik.touched.password && formik.errors.password ? (
                  <div className="frmError">{formik.errors.password}</div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Confirma tu contraseña</label>
                  <Form.Control
                    id="passwordConfirm"
                    name="passwordConfirm"
                    className="inputNewBrand main-btn-align"
                    type="password"
                    placeholder="Confirma tu contraseña"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.passwordConfirm}
                  />
                </Form.Group>
                {formik.touched.passwordConfirm &&
                formik.errors.passwordConfirm ? (
                  <div className="frmError">
                    {formik.errors.passwordConfirm}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3 termAndCondition">
                  <Form.Check
                    type="checkbox"
                    onChange={(event) => {
                      setIsChecked(event.currentTarget.checked);
                      isChecked ? setIsDisabled(true) : setIsDisabled(false);
                    }}
                    checked={isChecked}
                  />
                  <span>
                    Acepto los <a href="/">Términos y Condiciones</a> y el{" "}
                    <a href="/">Aviso de privacidad</a>
                  </span>
                </Form.Group>
              </Col>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Col md={12} style={{ paddingLeft: 8 }}>
                <div
                  style={{
                    display: error ? "block" : "none",
                    padding: "10px",
                    marginBottom: 15,
                    fontWeight: "bold",
                    color: "#CC3333",
                    backgroundColor: "#FFEEEE",
                  }}
                >
                  El usuario ya está registrado
                </div>
                {error && <LograLink href="/">Regresar al Sitio</LograLink>}
                <button
                  type="submit"
                  className={isDisabled ? "frmButtonDisabled" : "frmButton"}
                  disabled={isDisabled}
                >
                  Continuar
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FrmAccount;
