import React from "react";
import "../../assets/styles/slider/startSlider.scss";
import Slider from "infinite-react-carousel";

import sliderImage1 from "../../assets/images/slider/sliderImg4.png";
import sliderImage2 from "../../assets/images/slider/slider-img-2.png";
import sliderImage3 from "../../assets/images/slider/slider-img-3.png";
import { useState, useEffect } from "react";

const StartSlider = () => {
  const [sliderVisible, setIsSliderVisible] = useState(false);
  const settings = {
    arrows: false,
    dots: true,
    duration: 10,
    shift: 100,
    autoplaySpeed: 4000,
    dotsClass: "custom-dots",
    autoplay: true,
    className: "mainSliderContainer",
  };

  useEffect(() => {
    setIsSliderVisible(true);
  }, []);

  return (
    sliderVisible && (
      <div>
        <Slider {...settings}>
          <div>
            <div className="boxSliderImage">
              <img
                src={sliderImage2}
                alt="slider-img-1"
                className="responsive sliderImage"
              />
            </div>
            <div className="boxSliderParagraph">
              <p className="slider-paragraph-1">
                Lanza tu programa en minutos 💪
              </p>
              <p className="slider-paragraph-2">
                Personaliza tu programa a tu imagen e invita a tu red de
                empresas en diagnosticar sus retos.
              </p>
            </div>
          </div>
          <div>
            <div className="boxSliderImage">
              <img src={sliderImage1} alt="slider-img-2" />
            </div>
            <div className="boxSliderParagraph">
              <p className="slider-paragraph-1">
                Impulsa el crecimiento de tu red 📈
              </p>
              <p className="slider-paragraph-2">
                Nuestra plataforma recomienda a cada empresa tácticas, expertos
                y herramientas para que logre resultados rápidos.
              </p>
            </div>
          </div>
          <div>
            <div className="boxSliderImage">
              <img src={sliderImage3} alt="slider-img-3" />
            </div>
            <div className="boxSliderParagraph">
              <p className="slider-paragraph-1">
                Analiza los datos de tus empresas 📊
              </p>
              <p className="slider-paragraph-2">
                Entiende los perfiles, retos de crecimiento y soluciones de tu
                red de empresas mientras monitoreas sus logros.
              </p>
            </div>
          </div>
        </Slider>
      </div>
    )
  );
};

export default StartSlider;
