import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import api from "../../utils/axiosUsage";
import * as Yup from "yup";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";
import { getCurrentUser } from "../../utils/authUtils";
import { getCookie } from "../../utils/cookiesUtils";
import { useState } from "react";

const ConnectCorporation = ({ handler, editedUser }) => {
  const [corporations, setCorporations] = useState([]);
  const formik = useFormik({
    initialValues: {
      selectedOption: "",
    },
    validationSchema: Yup.object({}),

    onSubmit: (values) => {
      handler(values.selectedOption);
    },
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const userData = getCurrentUser();
        const token = userData.token || getCookie("session_token");
        const response = await api.axiosAuthGet("/api/v1/corporation/", token);
        if (response.status === 200) {
          setCorporations(response.data.results);
        } else {
          setCorporations([]);
        }
      } catch (err) {
        throw new Error(err);
      }
    };
    getData();
  }, []);

  return (
    <div>
      <Form className="corpsContainer" onSubmit={formik.handleSubmit}>
        <MainTitle>Selecciona una entidad</MainTitle>
        <Form.Group>
          <select
            name="selectedOption"
            value={formik.values.selectedOption}
            onChange={formik.handleChange}
          >
            <option value="">Elegir una opcion</option>
            {corporations.map((corp) => (
              <option key={corp.id} value={corp.id}>
                {`${corp.id} - ${corp.name}`}
              </option>
            ))}
          </select>
        </Form.Group>
        <Button type="submit" className="btn-style btn-text">
          Asignar
        </Button>
      </Form>
    </div>
  );
};

export default ConnectCorporation;
