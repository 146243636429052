import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { fieldsFilter } from "../home/fieldsFilter";
import ResetImg from "../../assets/images/icons/resetSearch.png";
import {
  getFilterCompanies,
  getMaxValues,
  getProfileUpdated,
} from "../../utils/companies";

export const FilterCompanies = ({
  companiesList,
  setSearching,
  searching,
  setPymesRegistered,
  setSectorsData,
  resetValues
}) => {
  const [query, setQuery] = useState({
    team: "",
    placeJob: "",
    sector: "",
    offer: "",
    customers: "",
  });

  useEffect(() => {
    const getQuerysArray = Object.values(query);
    const queryClean = getQuerysArray.filter((search) => search !== "");

    const filterCompanies = getFilterCompanies(companiesList, queryClean);

    const totalProfileUpdated = getProfileUpdated(filterCompanies);
    const maxValues = getMaxValues(filterCompanies);
    if (searching.init) {
      setSearching({
        isSearching: false,
        results: [],
        init: false,
      });
    } else {
      setSearching({
        isSearching: true,
        results: filterCompanies,
        init: false,
      });
      setPymesRegistered({
        total: filterCompanies.length,
        configuredProfile: totalProfileUpdated,
      });
      setSectorsData({
        total: filterCompanies.length,
        sectors: maxValues,
        totalSectors: filterCompanies.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const clearFilters = async () => {
    setQuery({
      team: "",
      placeJob: "",
      sector: "",
      offer: "",
      customers: "",
    });
    setSearching({
      isSearching: false,
      results: [],
      init: true,
    });
    setPymesRegistered({
      total: resetValues.pymesRegistered.total,
      configuredProfile: resetValues.pymesRegistered.configuredProfile,
    });
    setSectorsData({
      total: resetValues.sectors.total,
      sectors: resetValues.sectors.sectors,
      totalSectors: resetValues.sectors.totalSectors,
    });
  };

  return (
    <>
      <Row className="rowLimit  rowDashboard rowFilter">
        <Col>
          <Form.Group>
            <label>Miembros en tu equipo</label>
            <Form.Control
              id="businessModel"
              name="businessModel"
              as="select"
              className="inputNewBrand main-btn-align"
              value={query.team}
              onChange={({ target }) => {
                setQuery({ ...query, team: target.value });
              }}
            >
              <option value="">Selecciona</option>
              {fieldsFilter.teamSize.map((teamSize) => (
                <option value={teamSize.value} key={teamSize.name}>
                  {teamSize.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Lugar de trabajo</label>
            <Form.Control
              id="businessModel"
              name="businessModel"
              as="select"
              className="inputNewBrand main-btn-align"
              value={query.placeJob}
              onChange={({ target }) =>
                setQuery({ ...query, placeJob: target.value })
              }
            >
              <option value="">Selecciona</option>

              {fieldsFilter.workPlace.map((workPlace) => (
                <option value={workPlace} key={workPlace}>
                  {workPlace}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Sector o industria</label>
            <Form.Control
              id="businessModel"
              name="businessModel"
              as="select"
              className="inputNewBrand main-btn-align"
              value={query.sector}
              onChange={({ target }) =>
                setQuery({ ...query, sector: target.value })
              }
            >
              <option value="">Selecciona</option>
              {fieldsFilter.sector.map((sector) => (
                <option value={sector} key={sector}>
                  {sector}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Tipo de oferta</label>
            <Form.Control
              id="businessModel"
              name="businessModel"
              as="select"
              className="inputNewBrand main-btn-align"
              value={query.offer}
              onChange={({ target }) =>
                setQuery({ ...query, offer: target.value })
              }
            >
              <option value="">Selecciona</option>

              {fieldsFilter.typeOffer.map((offer) => (
                <option value={offer} key={offer}>
                  {offer}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Perfil de clientes</label>
            <Form.Control
              id="businessModel"
              name="businessModel"
              as="select"
              className="inputNewBrand main-btn-align"
              value={query.customers}
              onChange={({ target }) =>
                setQuery({ ...query, customers: target.value })
              }
            >
              <option value="">Selecciona</option>

              {fieldsFilter.customers.map((customers) => (
                <option value={customers.value} key={customers.name}>
                  {customers.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row className="rowLimit  rowDashboard rowFilter">
        <Col>
          {searching.isSearching === true && (
            <div onClick={clearFilters} className="companies-resetSearch">
              <img src={ResetImg} alt="Limpiar filtros" />
              <p>Limpiar filtros de búsqueda</p>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
