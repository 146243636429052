import React from "react";
import { Loading } from "@logra/component-library";
import "../../assets/styles/pages/loading/loading.scss";

export const LoadingApp = () => {
  return (
    <div className="loadingApp-container">
      <Loading />
    </div>
  );
};
