import ConfigurationEditProfile from "../Components/configuration/ConfigurationEditProfile";
import FrmMembers from "../Components/configuration/FormMember";
import Companies from "../Pages/Companies/Companies";
import Configuration from "../Pages/Configuration/Configuration";
import Home from "../Pages/Home/Home";
import LoginPage from "../Pages/Login/LoginPage";
import OnboardingSteps from "../Pages/Onboarding/steps";
import Referal from "../Pages/Referal/Referal";
import LicensesPage from "../Pages/Licenses/LicensesPage";
import StartPage from "../Pages/Start/StartPage";
import TeamMembers from "../Pages/Members/TeamMembers";
import ForgotPassword from "../Pages/RecoveryPassword/ForgotPassword";
/**
 * Admin components
 */
import CorporationDashboard from "../Pages/AdminPanel/CorporationDashboard";
import CreateCorporationManager from "../Pages/AdminPanel/CorporationCreate/CreateCorporationManager";
import EditCorporationManager from "../Pages/AdminPanel/CorporationEdit/EditCorporationManager";
import UserAssignmentManager from "../Pages/AdminPanel/UserAssignment/UserAssignmentManager";

export const ListedRoutes = [
  /**
   * Public Routes
   */
  {
    path: "/",
    exact: true,
    component: LoginPage,
    isPublic: true,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/signup",
    exact: true,
    component: StartPage,
    isPublic: true,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/old_onboarding",
    exact: true,
    component: OnboardingSteps,
    isPublic: true,
    isPrivate: false,
    isAdmin: false,
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgotPassword,
    isPublic: true,
    isPrivate: false,
    isAdmin: false,
  },

  /**
   * Private Routes
   */
  {
    path: "/configuration",
    exact: true,
    component: Configuration,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/configuration/edit_profile",
    exact: true,
    component: ConfigurationEditProfile,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/configuration/add_member",
    exact: true,
    component: FrmMembers,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/my-team",
    exact: true,
    component: TeamMembers,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/home",
    exact: true,
    component: Home,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/companies",
    exact: true,
    component: Companies,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/referal",
    exact: true,
    component: Referal,
    isPrivate: true,
    isAdmin: false,
  },
  {
    path: "/licenses",
    exact: true,
    component: LicensesPage,
    isPrivate: true,
    isAdmin: false,
  },

  /**
   * Admin Routes
   */
  {
    path: "/admin/dashboard",
    exact: true,
    component: CorporationDashboard,
    isPublic: false,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/admin/corporations/create",
    exact: true,
    component: CreateCorporationManager,
    isPublic: false,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/admin/corporations/assign",
    exact: true,
    component: UserAssignmentManager,
    isPublic: false,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/admin/corporations/edit",
    exact: true,
    component: EditCorporationManager,
    isPublic: false,
    isPrivate: true,
    isAdmin: true,
  },
];
