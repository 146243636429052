import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Col } from "react-bootstrap";
import FrmAccount from "./FrmAccount";
import FrmInstitutionProfile from "./FrmInstitutionProfile";

import "../../assets/styles/pages/design-app.scss";
import "../../assets/styles/pages/index.scss";
import "react-toastify/dist/ReactToastify.css";

import step1 from "../../assets/images/onboarding/step-1.svg";
import step2 from "../../assets/images/onboarding/step-2.svg";
import step3 from "../../assets/images/onboarding/step-3.svg";

import FrmAboutInstitution from "./FrmAboutInstitution";

const OnboardingSteps = () => {
  const [step, setStep] = useState(1);
  const location = useLocation();

  const [userEmail] = useState(location?.state?.userEmail);
  const [personName, setPersonName] = useState("");
  const [institutionName, setInstitutionName] = useState("");

  const changeStep = (val) => {
    setStep(val);
  };

  const changePersonName = (val) => {
    setPersonName(val);
  };

  const changeInstitutionName = (val) => {
    setInstitutionName(val);
  };

  const steps = [
    {
      id: 1,
      description: "Creación de cuenta",
      img: step1,
      frm: (
        <FrmAccount
          userEmail={userEmail}
          step={step}
          changeStep={changeStep}
          changePersonName={changePersonName}
        />
      ),
    },
    {
      id: 2,
      description: "Personaliza tu programa",
      img: step2,
      frm: (
        <FrmInstitutionProfile
          personName={personName}
          step={step}
          changeStep={changeStep}
          changeInstitutionName={changeInstitutionName}
        />
      ),
    },
    {
      id: 3,
      description: "Datos de tu entidad",
      img: step3,
      frm: (
        <FrmAboutInstitution
          institutionName={institutionName}
          step={step}
          changeStep={changeStep}
        />
      ),
    },
    /*
      To Do: Descomentar cuando se tengan las plantillas necesarias
    */
    /*
    {
      id: 4,
      description: "Invita más colaboradores",
      img: step4,
      frm: (
        <FrmInviteColaborators
          userEmail={userEmail}
          step={step}
          changeStep={changeStep}
          changePersonName={changePersonName}
        />
      ),
    },
    */
  ];

  const renderMenuOptions = () => {
    const menu = Object.values(steps).map((opt) => {
      return (
        <li key={`menu${opt.id}`} className={step === opt.id ? "active" : ""}>
          {opt.description}
        </li>
      );
    });
    return menu;
  };

  return (
    <Container fluid className="stepPage-container">
      <Col lg={12} className="stepPage-content">
        <img
          src="/images/logos/logo.svg"
          alt="logo"
          style={{ marginLeft: "30px", marginBottom: "100px" }}
        />
        <div>
          <img
            src={steps[step - 1].img}
            alt="step"
            style={{ marginLeft: "30px" }}
          />
        </div>
        {steps[step - 1].frm}
      </Col>
      <div className="colStepsDescription">
        <h3>Estás configurando tu cuenta</h3>
        <p>
          Conocer mejor a tu empresa nos permitirá crear la mejor ruta de
          crecimiento para ti y tu equipo.
        </p>
        <ul>{renderMenuOptions()}</ul>
      </div>
    </Container>
  );
};

export default OnboardingSteps;
