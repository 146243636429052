import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { GraphicSector } from "./GraphicSector";

ChartJS.register(ArcElement, Tooltip, Legend);
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "black",
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
      position: "right",
      onClick: () => {},
    },
  },
};

export const PymesRegistered = ({ pymesRegistered, sectorsData, }) => {
  const history = useHistory();
  const data = {
    labels: [`${pymesRegistered.total} crearon su cuenta`, `${pymesRegistered.configuredProfile} configuraron su perfil`],
    datasets: [
      {
        label: "# of Votes",
        data: [pymesRegistered.total, pymesRegistered.configuredProfile],
        backgroundColor: ["#3C62EB", "#B8D8E9"],
        borderColor: ["#3C62EB", "#B8D8E9"],
        borderWidth: 1,
      },
    ],
  };


  return (
    <>
      <Col className="companies-containerGraphicCircular">
        <div className="companies-containerPymesNum">
          <h5>Total de PyMEs registradas</h5>
          <p>{pymesRegistered.total || 0}</p>
        </div>

        {pymesRegistered.total === 0 ? (
          <p
            className="companies-link"
            onClick={() => history.push("/referal")}
          >
            Invita empresas a tu red
          </p>
        ) : (
          <div>
            <Pie data={data} options={options} />
          </div>
        )}
      </Col>
      <GraphicSector sectorsData={sectorsData} />
    </>
  );
};
