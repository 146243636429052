import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCrudUserData } from "../features/cruduserData/crudUserData";
import { fetchGraphData } from "../features/graphdata/graphData";
import { fetchUserData } from "../features/userdata/userData";
import { fetchTeamData } from "../features/TeamData/teamData";
import { fetchCorpData } from "../features/corp_data/corpData";
import { fetchReferalData } from "../features/referalData/referalData";
import fetchDashboardType from "../features/dashboard/dashboardData";
import { getCurrentUser } from "../utils/authUtils";
import { LoadingApp } from "../Components/Atoms/LoadingApp";
import useSessionVerification from "../hooks/useSessionVerification";
import { CombinedRoutes } from "./CombinatedRoutes";
import {
  activateSession,
  setAdmin,
} from "../features/user_session/userSessionSlice";
import { getCookie } from "../utils/cookiesUtils";
import { fetchDataPromise } from "../features/router/actions";
import CustomSidebar from "../Components/SideNavMenu/CustomSidebar";
import RefreshAccessToken from "../api/token.tsx";

/**
 * Componente RouterMain
 *
 * Este componente es el router principal de la aplicación.
 * Se encarga de manejar la autenticación del usuario y las cookies de sesión,
 * así como de cargar los datos necesarios para la aplicación.
 *
 * Si el usuario no está autenticado, se muestra un componente de carga.
 * Si el usuario está autenticado, se muestra un componente de barra lateral personalizada y
 * un conjunto de rutas combinadas basadas en los roles del usuario.
 *
 */

export const RouterMain = () => {
  const [areDataLoaded, setAreDataLoaded] = useState(false);

  const isLoading = useSessionVerification(); // Hook para verificar si se está cargando la sesión
  const dispatch = useDispatch(); // Función para despachar acciones a la store de Redux
  const currentUser = getCurrentUser(); // Función para obtener el usuario actual
  // Selección de variables del estado de Redux
  const { user_session, corp_data, graph_data, user_data } = useSelector(
    (state) => state
  );

  // Obtener la cookie de sesión y la cookie de administrador si es que existe
  const session_cookie = getCookie("userSession");
  const userSession_cookie = getCookie("userSession");
  const adminSession_cookie = getCookie("adminSession");

  //Este tiempo equivale al tiempo en el que se va a ejecutar la funcion de refreshAccessToken,son cinco minutos
  const REFRESH_TIME = 300000;
  // Efecto para activar la sesión si existe cookie de sesion y el usuario no tiene comportamiento inesperado

  useEffect(() => {
    //iniciamos intervalId
    let intervalId;
    if (!user_session.is_active && userSession_cookie) {
      dispatch(activateSession());
    }

    // if (!user_session.is_admin && adminSession_cookie) {
    //   console.log("entra");
    //   dispatch(setAdmin(true));
    // }

    if (Object.keys(currentUser).length !== 0 && user_session.is_active) {
      // Si el usuario está autenticado y no tiene un objeto vacío, se cargan los datos necesarios
      fetchDataPromise(
        [
          // fetchGraphData,
          fetchCrudUserData,
          fetchCorpData,
          // fetchReferalData, /* duda */
          // fetchTeamData,
          fetchUserData,
          fetchDashboardType,
        ],
        dispatch
      ).then(() => {
        setAreDataLoaded(true);
        //Aca corremos el timeout para que ejecute cada cinco minutos
        intervalId = setInterval(() => {
          RefreshAccessToken();
        }, REFRESH_TIME);
      });
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_session, session_cookie]);

  /**
   * Este valor depende del hook de useaSessionVerification donde verifica si existen las cookies ya que estas son las de manejo de sesion
   */
  if (isLoading) {
    return <LoadingApp />;
  }

  return (
    <React.Fragment>
      <Router>
        {user_session.is_active ? <CustomSidebar /> : <></>}
        <Switch>
          <CombinedRoutes
            isAuth={user_session.is_active}
            isAdmin={user_session.is_admin}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
};
