import api from "../utils/axiosUsage";

//El flujo es el siguiente la funcion recibe un objeto con nombre apellido correo y contraseña , se ejecuta la funcion connectInvitedUser,esta funcion tiene dos funciones dentro primero la de crear al usuario en base de las credenciales propuestas y la de conectar a dicho usuario una vez este usuario este conectado se van a usar las funciones de autenticacion y de actualizacion de estado no antes

interface createUserParams {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  lastname: string;
}
const STATUS_CREATED = 201;
const STATUS_SUCCESS = 200;

const createInvitedUser = async (credentials: createUserParams) => {
  try {
    const response = await api.axiosPost("/api/v1/signup", credentials);
    if (
      response.status === STATUS_CREATED ||
      response.status === STATUS_SUCCESS
    ) {
      return { ok: true, message: "Usuario creado" };
    } else {
      return {
        ok: false,
        message: "Respuesta del servidor fallida.",
      };
    }
  } catch (error) {
    return { ok: false, message: error.message };
  }
};

interface LoginParams {
  email: string;
  password: string;
  corp_id: number;
  invitation: {
    active: boolean;
    corp: number;
    email: string;
    id: string;
    sender: number;
    team: number;
  };
}

const logInvitedUser = async (credentials: LoginParams) => {
  let invitedUser = {};
  let company = {};
  const credenciales = {
    email: credentials.email,
    password: credentials.password,
  };
  const invitation = credentials.invitation;
  try {
    const loginResponse = await api.axiosPost("/api/v1/login/", credenciales);
    if (loginResponse.status === 200 || loginResponse.status === 201) {
      const access_token = loginResponse.data.access;
      const refresh_token = loginResponse.data.refresh;
      const accountInfo = await api.axiosAuthGet(
        `/api/v1/account`,
        access_token
      );
      // Obtenemos el ID de la corporación a la que pertenece el usuario
      const corpId = credentials.corp_id;
      const idAccount = accountInfo.data.id;
      // Obtenemos la información de la corporación
      const companyInfo = await api.axiosAuthGet(
        `/api/v1/corporation/${corpId}/`,
        access_token
      );

      //Actualizamos el objeto de usuario que vamos a pasar
      accountInfo.data.companyId = corpId;
      accountInfo.data.is_corp_owner = true;
      accountInfo.data.corp = corpId;
      accountInfo.data.team = invitation.team;

      // Editamos los perfiles y actualizamos los respectivos campos
      const EditParams = {
        team: invitation.team,
        corp: credentials.corp_id,
        is_corp_owner: true,
      };
      const edit_account_info = await api.axiosAuthPatch(
        "/api/v1/account",
        access_token,
        EditParams
      );
      const edit_profile_crud = await api.axiosAuthPatch(
        `/api/v1/profile-crud/${idAccount}/`,
        access_token,
        EditParams
      );

      // Actualizamos las variables que se devolverán
      company = companyInfo.data;
      invitedUser = {
        ...accountInfo.data,
        token: access_token,
        access_token,
        refresh_token,
      };

      return { ok: true, invitedUser, company };
    } else {
      return {
        ok: false,
        message: "Respuesta del servidor fallida.",
        response: loginResponse,
      };
    }
  } catch (error) {
    return { ok: false, message: error.message, error };
  }
};

interface ConnectInvitedUserParams {
  name: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  corp_id: number;
  invitation: {
    active: boolean;
    corp: number;
    email: string;
    id: string;
    sender: number;
    team: number;
  };
}

export const ConnectInvitedUser = async (
  credentials: ConnectInvitedUserParams
) => {
  const errors: string[] = [];
  let success = false;
  let companyData = {};
  let connectedUserData = {};
  try {
    const invitedUser = await createInvitedUser({
      name: credentials.name,
      lastname: credentials.lastname,
      email: credentials.email,
      password: credentials.password,
      confirmPassword: credentials.confirmPassword,
    });

    if (invitedUser.ok) {
      const loggedUser: any = await logInvitedUser({
        email: credentials.email,
        password: credentials.password,
        corp_id: credentials.corp_id,
        invitation: credentials.invitation,
      });

      if (loggedUser.ok) {
        success = true;
        connectedUserData = loggedUser.invitedUser;
        companyData = loggedUser.company;
      } else {
        errors.push(
          "No se pudo iniciar sesión con las credenciales proporcionadas"
        );
      }
    } else {
      errors.push("No se pudo crear el usuario invitado");
    }
  } catch (error) {
    errors.push("Ocurrió un error al procesar la solicitud: " + error.message);
  }

  return { connectedUserData, companyData, errors, success };
};
