import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "./userData";

const userDataSlice = createSlice({
  name: "user_data",
  initialState: { data: null, timeStamp: null, error: null, loading: false },
  reducers: {
    resetData: (state) => {
      state.data = null;
      state.timeStamp = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.timeStamp = action.payload.timeStamp;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userDataSlice.reducer;
