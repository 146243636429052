export const getProfileUpdated = (allSectors) => {
  const total = allSectors.filter((company) => company.smb_name);
  return total.length;
};

export const getMaxValues = (allSectors) => {
  if (allSectors.length === 0) {
    return [];
  }

  const getSectors = allSectors.map((company) => company.industry_sector);
  const getEachTotalSectorsNumber = getEachTotalSector(getSectors);
  const deleteSectorsDuplicates = getEachTotalSectorsNumber.reduce(
    (acc, sector) => {
      acc[sector.name] = ++acc[sector.name] || 1;
      return acc;
    },
    {}
  );

  const sectorsToArray = Object.entries(deleteSectorsDuplicates);
  const newFormObjectSector = sectorsToArray.map((sector) => {
    return {
      value: sector[1],
      name: sector[0],
    };
  });
  const maxValues = newFormObjectSector
    .sort((a, b) => b.value - a.value)
    .slice(0, 3);
  return maxValues;
};

const getEachTotalSector = (sectors) => {
  return sectors.map((sector) => {
    let countSectors = 0;
    let objSector = {};
    for (let index = 0; index < sectors.length; index++) {
      /* if the sector name is the same */
      if (sector === sectors[index]) {
        countSectors = countSectors + 1;
        objSector.name = sector;
        objSector.value = countSectors;
      }
    }
    return objSector;
  });
};

export const getFilterCompanies = (data, search) => {
  const filterCompanies = data.filter((company) => {
    let count = 0;
    const filterWithin = Object.values(company).filter((field) => {
      for (let index = 0; index < search.length; index++) {
        if (field === search[index]) {
          count = count + 1;
        }
      }
      return count === search.length && field;
    });
    return filterWithin.length !== 0 && company;
  });

  return filterCompanies;
};

export const getSizeTeamName = (size) => {
  switch (size) {
    case "1":
      return "Estoy solo";
    case "1-5":
      return "1 a 5 colaboradores";
    case "6-25":
      return "6 a 25 colaboradores";
    case "26-50":
      return "26 a 50 colaboradores";
    case "51-100":
      return "51 a 100 colaboradoress";
    case "101-250":
      return "201 a 250 colaboradores";
    case ">250":
      return "Más de 250 colaboradores";
    default:
      return "";
  }
};

/* the ordes is for the order api response */
export const dataCategories = [
  {
    title: "Planeación y estrategia",
    style: "planning",
    idApi: 0,
    slug: "planning",
  },
  {
    title: "Marketing",
    style: "marketing",
    idApi: 1,
    slug: "marketing",
  },
  {
    title: "Ventas y relación cliente",
    style: "marketing",
    idApi: 2,
    slug: "sales",
  },
  {
    title: "Administración",
    style: "admi",
    idApi: 3,
    slug: "admi",
  },
  {
    title: "Finanzas",
    style: "admi",
    idApi: 4,
    slug: "finance",
  },
  {
    title: "Equipo y cultura",
    style: "team",
    idApi: 5,
    slug: "team",
  },
  {
    title: "Digitalización y tecnología",
    style: "tech",
    idApi: 6,
    slug: "tech",
  },
];
