import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentUser } from "../../utils/authUtils";
import api from "../../utils/axiosUsage";
import { getCookie } from "../../utils/cookiesUtils";

export const fetchCrudUserData = createAsyncThunk(
  "user/fetchCrudUserData",
  async () => {
    const userData = await getCurrentUser();
    const token = getCookie("session_token");
    const response = await api.axiosAuthGet(`/api/v1/account`, token);
    const user_data = response.data || userData;
    return {
      crud_user_data: user_data,
      timeStamp: Date.now(),
    };
  }
);
