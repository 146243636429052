function fetchData(actions, dispatch) {
  actions.forEach((action) => dispatch(action()));
}

const fetchRefreshData = (actions, dispatch, refreshInterval) => {
  const intervalRefresh = setInterval(() => {
    actions.forEach((action) => dispatch(action()));
  }, refreshInterval);
  return () => {
    clearInterval(intervalRefresh);
  };
};

function fetchDataPromise(actions, dispatch) {
  return new Promise((resolve, reject) => {
    try {
      fetchData(actions, dispatch);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}

export { fetchData, fetchRefreshData, fetchDataPromise };
