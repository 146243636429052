import {
  activateSession,
  deactivateSession,
  setAdmin,
} from "../features/user_session/userSessionSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { clearAllCookies, createCookie, deleteCookie } from "./cookiesUtils";
import {
  getCurrentUser,
  setCurrentUser,
  setNewCompany,
  setNewUser,
} from "./authUtils";
import {
  CompanyDataInterface,
  UserDataInterface,
  UserSessionDataInterface,
} from "./types";

// Constantes
export const SESSION_TOKEN_COOKIE_NAME = "session_token";
export const REFRESH_TOKEN_COOKIE_NAME = "refresh_token";
export const SESSION_USER_COOKIE_NAME = "userSession";
export const SESSION_ID_USER_COOKIE_NAME = "userSessionId";
export const SESSION_ADMIN_COOKIE_NAME = "adminSession";
export const LOCAL_STORAGE_USER_KEY = "user";
export const LOCAL_STORAGE_COMPANY_KEY = "company";
export const LOCAL_STORAGE_SESSION_TIME = "session_time";

// Funciones de Cookies

// Crea tres tipos de cookies: una para la sesión de usuario, otra para el token de sesión y otra para la sesión de administrador (si isAdmin es true). Además, genera una cookie para identificar la sesión del usuario y guarda los datos de sesión proporcionados en un objeto UserSessionData.

function setAuthCookies(sessionData: UserDataInterface, isAdmin: boolean) {
  const userSessionData: UserSessionDataInterface = {
    userId: sessionData.id,
    accessToken: sessionData.access_token,
    refreshToken: sessionData.refresh_token,
  };
  const SESSION_TOKEN = sessionData.access_token;

  createCookie(SESSION_USER_COOKIE_NAME, JSON.stringify(userSessionData), 7);
  createCookie(SESSION_TOKEN_COOKIE_NAME, SESSION_TOKEN, 7);
  generateUserSessionCookie();

  if (isAdmin) {
    createCookie(SESSION_ADMIN_COOKIE_NAME, JSON.stringify(true), 7);
  }
}

function updateStorage(access_token: string) {
  const userData = getCurrentUser();
  if (!access_token || !userData)
    return "Se necesita tanto token de acceso como el usuario";
  const updatedUser = {
    ...userData,
    token: access_token,
    access_token: access_token,
  };
  const NEW_ACCESS_TOKEN = access_token;
  createCookie(SESSION_TOKEN_COOKIE_NAME, NEW_ACCESS_TOKEN, 7);
  setCurrentUser(updatedUser);
}

//Elimina tanto las cookies,localStorage y actualiza el estado global
function clearSessionData(dispatch: Dispatch) {
  dispatch(deactivateSession());
  deleteCookie(SESSION_TOKEN_COOKIE_NAME);
  deleteCookie(SESSION_USER_COOKIE_NAME);
  deleteCookie(SESSION_ADMIN_COOKIE_NAME);
  deleteCookie(SESSION_ID_USER_COOKIE_NAME);
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
  localStorage.removeItem(LOCAL_STORAGE_COMPANY_KEY);
  localStorage.removeItem(LOCAL_STORAGE_SESSION_TIME);
}

//Esta funcion crea una cookie para la sesion con un id unico
function generateUserSessionCookie() {
  const userSession =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  createCookie(SESSION_ID_USER_COOKIE_NAME, userSession, 24);

  return userSession;
}

// Funciones de Almacenamiento Local
function clearStorage() {
  localStorage.clear();
  clearAllCookies();
}

//Esta funcion tiene como prioridad actualizar el estado global,solo sirve para inicializar el estado
function setGlobalState(dispatch: Dispatch, is_admin: boolean) {
  dispatch(activateSession());
  dispatch(setAdmin(is_admin));
}

//Esta funcion tiene como funcion principal almacenar tanto los datos de la compañia como del usuario dentro de local storage
function setLocalStorageValues(
  userData: UserDataInterface,
  companyData: CompanyDataInterface
) {
  const user = userData;
  const access_token = userData.access_token;
  const refresh_token = userData.refresh_token;
  const corpId = userData.corp;
  setNewUser(user, access_token, refresh_token, corpId);
  setNewCompany(companyData, companyData.id);
  setLocalTime();
}

function setLocalTime() {
  const now = Date.now();
  localStorage.setItem(LOCAL_STORAGE_SESSION_TIME, now.toString());
}

export {
  setAuthCookies,
  setGlobalState,
  updateStorage,
  setLocalStorageValues,
  clearSessionData,
  clearStorage,
  generateUserSessionCookie,
};
