import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";

export const GraphicSector = ({ sectorsData }) => {
  const [sectorInfo, setSectorInfo] = useState([]);

  useEffect(() => {
    if (sectorsData.totalSectors >= 4) {
      setSectorInfo([
        ...sectorsData.sectors,
        {
          name: "Otros",
          value: getValueOther(),
        },
      ]);
    } else {
      setSectorInfo(sectorsData.sectors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorsData]);

  const shortName = (name) => {
    if (name.length > 30) {
      return name.substring(0, 30).concat("...");
    } else {
      return name;
    }
  };

  const getPorcent = (numRegisters) => {
    return (numRegisters * 100) / sectorsData.total;
  };

  const getValueOther = () => {
    const initialValue = 0;
    const totalSum = sectorsData.sectors.reduce(
      (previousValue, currentValue) => previousValue + currentValue.value,
      initialValue
    );

    return sectorsData.total - totalSum;
  };

  return (
    <Col className="companies-containerSectors">
      <h5>Sectores e industrias</h5>
      {sectorsData.total === 0 ? (
        <p className="companies-link">Invita empresas a tu red</p>
      ) : (
        <>
          <div className="companies-containerSectorData">
            {sectorInfo.map((sector, i) => (
              <div className={`companies-eachSectorData${i + 1}`} key={i}>
                <div className={`companies-eachPointSector sector${i + 1}`} />
                <p>{shortName(sector.name)}</p>
              </div>
            ))}
          </div>
          <div className="companies-graphicHorizontal">
            {sectorInfo.map((sector, i) => (
              <div
                key={i}
                style={{ width: `${getPorcent(sector.value)}%` }}
                className={`companies-graphicItem${i + 1} ${
                  sectorInfo.length === i + 1 && "finish"
                } `}
              />
            ))}
          </div>
        </>
      )}
    </Col>
  );
};
