import { createSlice } from "@reduxjs/toolkit";
import { fetchCrudUserData } from "./crudUserData";

const crudUserDataSlice = createSlice({
  name: "crud_user_data",
  initialState: { data: null, timeStamp: null, error: null, loading: false },
  reducers: {
    resetData: (state) => {
      state.data = null;
      state.timeStamp = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCrudUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCrudUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.crud_user_data;
        state.timeStamp = action.payload.timeStamp;
      })
      .addCase(fetchCrudUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default crudUserDataSlice.reducer;
