import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CloseIcon from "../../assets/images/icons/close.png";
import NotCompaniesImage from "../../assets/images/modals/notCompanies.png";
import "../../assets/styles/modals/modal-notCompanies.scss";

export const ModalNotCompanies = ({ show, close, referralData }) => {
  const history = useHistory();
  const [referral, setReferral] = useState([]);

  useEffect(() => {
    setReferral(referralData?.owners_in_corp);
  }, [referralData.owners_in_corp]);

  return (
    <Modal size="lg" centered show={show} onHide={() => close(false)}>
      <Modal.Body className="modalNotCompanies-container">
        <div className="modalNotCompanies-content">
          <div className="modalNotCompanies-containerTitle">
            {referral.length === 0 ? (
              <h1>Aún no tienes empresas referidas 🤷‍♂️</h1>
            ) : (
              <h1>Tienes {referral.length} empresas referidas.</h1>
            )}
            <img src={CloseIcon} alt="Cerrar" onClick={() => close(false)} />
          </div>
          <div className="modalNotCompanies-containerBody">
            <p className="modalNotCompanies-ContainerBodyDescription">
              Invita a tu red de empresas para empezar a analizar
              <span> sus perfiles y retos de crecimiento.</span>
            </p>
            <img
              src={NotCompaniesImage}
              style={{
                width: "450px",
                height: "225px",
              }}
              alt="Ejemplo de empresas referidas"
            />

            <div className="modalNotCompanies-containerBtn">
              <button type="button" onClick={() => history.push("/referal")}>
                Empezar a invitar a mi red
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
